import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {
  createClient,
  Provider as URQLProvider,
  defaultExchanges,
  subscriptionExchange,
} from 'urql';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';
import './locales/i18n';

import reportWebVitals from './reportWebVitals';
import { LayoutProvider } from './contexts/LayoutContext';
import { Web3Provider } from './contexts/Web3Context';
import { ModalProvider } from './contexts/ModalContext';
import { MarketsProvider } from './contexts/MarketsContext';
import { TokensProvider } from './contexts/TokensContext';
import { UserProvider } from './contexts/UserContext';
import App from './pages/App';
import { ThemeProvider, GlobalStyling } from './theme';

const subscriptionClient = new SubscriptionClient(process.env.REACT_APP_GQL_WS_ENDPOINT, {
  reconnect: true,
});

const subgraphQueryClient = createClient({
  url: process.env.REACT_APP_GQL_ENDPOINT,
  exchanges: [
    ...defaultExchanges,
    subscriptionExchange({
      forwardSubscription: (op) => subscriptionClient.request(op),
    }),
  ],
});

const reactQueryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <URQLProvider value={subgraphQueryClient}>
      <ReactQueryClientProvider client={reactQueryClient}>
        <ThemeProvider>
          <LayoutProvider>
            <Web3Provider>
              <TokensProvider>
                <MarketsProvider>
                  <UserProvider>
                    <ModalProvider>
                      <BrowserRouter>
                        <GlobalStyling />
                        <App />
                      </BrowserRouter>
                    </ModalProvider>
                  </UserProvider>
                </MarketsProvider>
              </TokensProvider>
            </Web3Provider>
          </LayoutProvider>
        </ThemeProvider>
      </ReactQueryClientProvider>
    </URQLProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
