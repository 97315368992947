import { createContext, useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { OperationContext } from 'urql';
import { BigNumber } from 'ethers';

import useWeb3 from './Web3Context';
import useMarkets from './MarketsContext';
import useTokens from './TokensContext';
import { UserInfoQuery, useUserInfoQuery } from '../graphql/generated';
import { Position, LpPosition, TokenBalance, mapToMarket, mapToToken } from '../utils/data';
import {
  getCollateralBalance,
  getFreeCollateralByRatio,
  getFundingPaymentsAcrossMarkets,
  getGlobalPosition,
  getLpPosition,
  getMarginRatio,
  getMinMargin,
  getMinMarginAtCreation,
  getMinPositiveOpenNotional,
  getPnlAcrossMarkets,
  getReserveValue,
  getTraderPosition,
  getUserDebtAcrossMarkets,
  SignerOrProvider,
} from '../utils/clearingHouse';
import usePortfolioValue from '../hooks/usePortfolioValue';
import { KeyMap, Side } from '../utils/types';
import { DataPoint } from '../components/ReChart/types';
import { Network } from '../data/chain';
import { useDeepRefresh } from '../hooks/useDeepRefresh';

interface UserContextType {
  fetching: boolean;
  deepFetching: boolean;
  invalidatePositions: (opts?: Partial<OperationContext>) => void;
  refreshUserInfo: () => void;
  refreshGlobalState: () => void;
  forceNewTraderPosition: (marketIdx: string) => void;
  forceNewLpPosition: (marketIdx: string) => void;
  forceNewTokenBalance: (tokenIdx: string) => void;
  portfolioValues?: DataPoint[];
  error?: Error;
  currentPositions?: Position[];
  allPositions?: KeyMap<Position[]>;
  currentLpPositions?: LpPosition[];
  allLpPositions?: KeyMap<LpPosition[]>;
  currentTokenBalances?: TokenBalance[];
  allTokenBalances?: KeyMap<TokenBalance[]>;
  freeCollateral?: BigNumber;
  marginRatio?: BigNumber;
  minMargin?: BigNumber;
  minMarginAtCreation?: BigNumber;
  minPositiveOpenNotional?: BigNumber;
  reserveValue?: BigNumber;
  buyingPower?: BigNumber;
  pnlAcrossMarkets?: BigNumber;
  fundingPaymentsAcrossMarkets?: BigNumber;
  userDebtAcrossMarkets?: BigNumber;
}

const UserContext = createContext<UserContextType>({
  fetching: true,
  deepFetching: false,
  invalidatePositions: () => null,
  refreshUserInfo: () => null,
  refreshGlobalState: () => null,
  forceNewTraderPosition: (marketIdx: string) => marketIdx,
  forceNewLpPosition: (marketIdx: string) => marketIdx,
  forceNewTokenBalance: (tokenIdx: string) => tokenIdx,
});

const chainQueries = async (
  signerOrProvider: SignerOrProvider,
  userAddress: string,
  chainConfig: Network,
): Promise<
  [
    BigNumber,
    BigNumber,
    BigNumber,
    BigNumber,
    BigNumber,
    BigNumber,
    BigNumber,
    BigNumber,
    BigNumber,
  ]
> => {
  const [
    marginRatioResult,
    minMarginResult,
    minMarginAtCreationResult,
    reserveValueResult,
    minPositiveOpenNotional,
    pnlAcrossMarketsResult,
    fundingPaymentsAcrossMarketsResult,
    userDebtAcrossMarketsResult,
  ] = await Promise.all([
    getMarginRatio({
      signerOrProvider,
      chainConfig,
      userAddress,
    }),
    getMinMargin({
      signerOrProvider,
      chainConfig,
    }),
    getMinMarginAtCreation({
      signerOrProvider,
      chainConfig,
    }),
    getReserveValue({
      signerOrProvider,
      chainConfig,
      userAddress,
    }),
    getMinPositiveOpenNotional({
      signerOrProvider,
      chainConfig,
    }),
    getPnlAcrossMarkets({
      signerOrProvider,
      chainConfig,
      userAddress,
    }),
    getFundingPaymentsAcrossMarkets({
      signerOrProvider,
      chainConfig,
      userAddress,
    }),
    getUserDebtAcrossMarkets({
      signerOrProvider,
      chainConfig,
      userAddress,
    }),
  ]);
  const freeCollateralResult = await getFreeCollateralByRatio({
    signerOrProvider,
    chainConfig,
    userAddress,
    ratio: minMarginAtCreationResult,
  });
  return [
    marginRatioResult,
    minMarginResult,
    minMarginAtCreationResult,
    reserveValueResult,
    minPositiveOpenNotional,
    freeCollateralResult,
    pnlAcrossMarketsResult,
    fundingPaymentsAcrossMarketsResult,
    userDebtAcrossMarketsResult,
  ];
};

const compareFn = (first: Record<string, unknown>, other: Record<string, unknown>): boolean =>
  !!(first as UserInfoQuery)?.user?.currentPositions?.reduce<boolean>(
    (acc, position) =>
      acc &&
      !!(other as UserInfoQuery)?.user?.currentPositions.find(
        (otherPosition) =>
          otherPosition.market.id === position.market.id &&
          otherPosition.timestamp === position.timestamp,
      ),
    true,
  );

export function UserProvider({ children }: { children: React.ReactNode }) {
  const { chainConfig, address, provider } = useWeb3();
  const { marketList } = useMarkets();
  const { tokenList } = useTokens();

  const [currentPositions, setCurrentPositions] = useState<Position[]>();
  const [allPositions, setAllPositions] = useState<KeyMap<Position[]>>();
  const [currentLpPositions, setCurrentLpPositions] = useState<LpPosition[]>();
  const [allLpPositions, setAllLpPositions] = useState<KeyMap<LpPosition[]>>();
  const [currentTokenBalances, setCurrentTokenBalances] = useState<TokenBalance[]>();
  const [allTokenBalances, setAllTokenBalances] = useState<KeyMap<TokenBalance[]>>();
  const [freeCollateral, setFreeCollateral] = useState<BigNumber>();
  const [marginRatio, setMarginRatio] = useState<BigNumber>();
  const [minMargin, setMinMargin] = useState<BigNumber>();
  const [minMarginAtCreation, setMinMarginAtCreation] = useState<BigNumber>();
  const [reserveValue, setReserveValue] = useState<BigNumber>();
  const [minPositiveOpenNotional, setMinPositiveOpenNotional] = useState<BigNumber>();
  const [pnlAcrossMarkets, setPnlAcrossMarkets] = useState<BigNumber>();
  const [fundingPaymentsAcrossMarkets, setFundingPaymentsAcrossMarkets] = useState<BigNumber>();
  const [userDebtAcrossMarkets, setUserDebtAcrossMarkets] = useState<BigNumber>();

  const earliestActivity = useMemo<string | undefined>(() => {
    if (allTokenBalances) {
      const allTokenBalancesList = Object.values(allTokenBalances).flat();
      return allTokenBalancesList[allTokenBalancesList.length - 1]?.timestamp;
    }
    return undefined;
  }, [allTokenBalances]);

  const { portfolioValues, refreshGlobalState } = usePortfolioValue({
    from: earliestActivity,
    allPositions,
    allLpPositions,
    allTokenBalances,
  });

  const [{ fetching, data, error }, invalidatePositions] = useUserInfoQuery({
    variables: { user: address?.toLowerCase() || '' },
    pause: !address,
  });

  const [userData, refreshUserInfo, deepFetching] = useDeepRefresh({
    queryData: data,
    fetching,
    invalidateQuery: invalidatePositions,
    compareFn,
    id: 'positions',
  });

  const forceNewTraderPosition = useCallback(
    async (marketIdx: string) => {
      let subscribed = true;
      if (address && provider && marketList) {
        getTraderPosition({
          userAddress: address,
          chainConfig,
          marketIdx,
          signerOrProvider: provider,
        }).then(({ openNotional, positionSize, cumFundingRate }) => {
          if (subscribed) {
            const market = marketList.find(({ id }) => id === marketIdx);
            if (market !== undefined) {
              const newPosition: Position = {
                id: `${address}/${marketIdx}`,
                market: {
                  ...market,
                  latestPrice: {
                    value: market.last,
                  },
                },
                timestamp: (Date.now() / 1000).toFixed(),
                direction: BigNumber.from(openNotional).lte(0) ? Side.Long : Side.Short,
                initialCumFundingRate: BigNumber.from(cumFundingRate).toString(),
                positionSize: BigNumber.from(positionSize).toString(),
                openNotional: BigNumber.from(openNotional).toString(),
                entryPrice: market.last,
              };
              setCurrentPositions((lastPositions?: Position[]): Position[] =>
                lastPositions
                  ? [
                      ...lastPositions.filter((position) => position.id !== newPosition.id),
                      newPosition,
                    ]
                  : [newPosition],
              );
              setAllPositions((lastAllPositions) =>
                mapToMarket(
                  lastAllPositions
                    ? [
                        ...Object.values(lastAllPositions)
                          .flat()
                          .filter((position) => position.id !== newPosition.id),
                        newPosition,
                      ]
                    : [newPosition],
                ),
              );
            }
          }
        });
      }
      return () => {
        subscribed = false;
      };
    },
    [address, chainConfig, marketList, provider],
  );

  const forceNewLpPosition = useCallback(
    async (marketIdx: string) => {
      let subscribed = true;

      if (provider && chainConfig && marketIdx && address) {
        Promise.all([
          getLpPosition({
            signerOrProvider: provider,
            chainConfig,
            marketIdx,
            userAddress: address,
          }),
          getGlobalPosition({
            signerOrProvider: provider,
            chainConfig,
            marketIdx,
          }),
        ]).then(
          ([
            { liquidityBalance, openNotional, positionSize, cumFundingPerLpToken, depositTime },
            { totalBaseFeesGrowth, totalQuoteFeesGrowth, totalTradingFeesGrowth },
          ]) => {
            if (subscribed) {
              const market = marketList.find(({ id }) => id === marketIdx);
              if (market !== undefined) {
                const newLpPosition: LpPosition = {
                  id: `${address}/${marketIdx}`,
                  market: {
                    ...market,
                    latestPrice: {
                      value: market.last,
                    },
                  },
                  depositTime: BigNumber.from(depositTime).toString(),
                  timestamp: (Date.now() / 1000).toFixed(),
                  initialCumFundingPerLpToken: BigNumber.from(cumFundingPerLpToken).toString(),
                  positionSize: BigNumber.from(positionSize).toString(),
                  openNotional: BigNumber.from(openNotional).toString(),
                  liquidityBalance: BigNumber.from(liquidityBalance).toString(),
                  totalBaseFeesGrowth: BigNumber.from(totalBaseFeesGrowth).toString(),
                  totalQuoteFeesGrowth: BigNumber.from(totalQuoteFeesGrowth).toString(),
                  totalTradingFeesGrowth: BigNumber.from(totalTradingFeesGrowth).toString(),
                };
                setCurrentLpPositions((lastPositions?: LpPosition[]): LpPosition[] =>
                  lastPositions
                    ? [
                        ...lastPositions.filter((position) => position.id !== newLpPosition.id),
                        newLpPosition,
                      ]
                    : [newLpPosition],
                );
                setAllLpPositions((lastAllPositions) =>
                  mapToMarket(
                    lastAllPositions
                      ? [
                          ...Object.values(lastAllPositions)
                            .flat()
                            .filter((position) => position.id !== newLpPosition.id),
                          newLpPosition,
                        ]
                      : [newLpPosition],
                  ),
                );
              }
            }
          },
        );
      }

      return () => {
        subscribed = false;
      };
    },
    [address, chainConfig, marketList, provider],
  );

  const forceNewTokenBalance = useCallback(
    async (tokenIdx: string) => {
      let subscribed = true;

      if (provider && chainConfig && tokenIdx && address && tokenList) {
        getCollateralBalance({
          signerOrProvider: provider,
          chainConfig,
          tokenIdx,
          userAddress: address,
        }).then((balance) => {
          if (subscribed) {
            const token = tokenList.find((tokenInfo) => tokenInfo.id === tokenIdx);
            if (token !== undefined) {
              const newTokenBalance: TokenBalance = {
                id: `${address}/${token.id}`,
                token,
                amount: BigNumber.from(balance).toString(),
                timestamp: (Date.now() / 1000).toString(),
              };
              setCurrentTokenBalances((lastTokenBalances?: TokenBalance[]): TokenBalance[] =>
                lastTokenBalances
                  ? [
                      ...lastTokenBalances.filter(
                        (tokenBalance) => tokenBalance.id !== newTokenBalance.id,
                      ),
                      newTokenBalance,
                    ]
                  : [newTokenBalance],
              );
              setAllTokenBalances((lastTokenBalances) =>
                mapToToken(
                  lastTokenBalances
                    ? [
                        ...Object.values(lastTokenBalances)
                          .flat()
                          .filter((tokenBalance) => tokenBalance.id !== newTokenBalance.id),
                        newTokenBalance,
                      ]
                    : [newTokenBalance],
                ),
              );
            }
          }
        });
      }

      return () => {
        subscribed = false;
      };
    },
    [address, chainConfig, tokenList, provider],
  );

  useEffect(() => {
    // Synchronous Operations
    const userInfo = userData?.user;
    if (userInfo) {
      setCurrentPositions(userInfo.currentPositions as Position[]);
      setAllPositions(
        mapToMarket([...userInfo.currentPositions, ...userInfo.pastPositions] as Position[]),
      );
      setCurrentLpPositions(userInfo.currentLpPositions as LpPosition[]);
      setAllLpPositions(
        mapToMarket([...userInfo.currentLpPositions, ...userInfo.pastLpPositions] as LpPosition[]),
      );
      setCurrentTokenBalances(userInfo.currentTokenBalances as TokenBalance[]);
      setAllTokenBalances(
        mapToToken([
          ...userInfo.currentTokenBalances,
          ...userInfo.pastTokenBalances,
        ] as TokenBalance[]),
      );
    }
  }, [userData, chainConfig, provider, address, data]);

  useEffect(() => {
    let subscribed = true;

    // Async operations
    if (provider && address && chainConfig) {
      chainQueries(provider, address, chainConfig)
        .then(
          ([
            marginRatioResult,
            minMarginResult,
            minMarginAtCreationResult,
            reserveValueResult,
            minPositiveOpenNotionalResult,
            freeCollateralResult,
            pnlAcrossMarketsResult,
            fundingPaymentsAcrossMarketsResult,
            userDebtAcrossMarketsResult,
          ]) => {
            if (subscribed) {
              setMarginRatio(marginRatioResult);
              setFreeCollateral(freeCollateralResult);
              setMinMargin(minMarginResult);
              setMinMarginAtCreation(minMarginAtCreationResult);
              setMinPositiveOpenNotional(minPositiveOpenNotionalResult);
              setReserveValue(reserveValueResult);
              setPnlAcrossMarkets(pnlAcrossMarketsResult);
              setFundingPaymentsAcrossMarkets(fundingPaymentsAcrossMarketsResult);
              setUserDebtAcrossMarkets(userDebtAcrossMarketsResult);
            }
          },
        )
        .catch(console.error);
    }

    return () => {
      subscribed = false;
    };
  }, [provider, address, chainConfig, currentLpPositions, currentPositions, currentTokenBalances]);

  const buyingPower = useMemo<BigNumber>(
    () =>
      freeCollateral && minMarginAtCreation
        ? freeCollateral.divWei(minMarginAtCreation)
        : BigNumber.from(0),
    [freeCollateral, minMarginAtCreation],
  );

  const userValue = useMemo(
    () => ({
      currentPositions,
      allPositions,
      currentLpPositions,
      allLpPositions,
      currentTokenBalances,
      allTokenBalances,
      fetching,
      deepFetching,
      error,
      invalidatePositions,
      marginRatio,
      freeCollateral,
      minMargin,
      minMarginAtCreation,
      minPositiveOpenNotional,
      reserveValue,
      buyingPower,
      portfolioValues,
      refreshUserInfo,
      refreshGlobalState,
      forceNewTraderPosition,
      forceNewLpPosition,
      forceNewTokenBalance,
      pnlAcrossMarkets,
      fundingPaymentsAcrossMarkets,
      userDebtAcrossMarkets,
    }),
    [
      currentPositions,
      allPositions,
      currentLpPositions,
      allLpPositions,
      currentTokenBalances,
      allTokenBalances,
      fetching,
      error,
      invalidatePositions,
      marginRatio,
      freeCollateral,
      minMargin,
      minMarginAtCreation,
      minPositiveOpenNotional,
      reserveValue,
      buyingPower,
      portfolioValues,
      refreshUserInfo,
      refreshGlobalState,
      deepFetching,
      forceNewTraderPosition,
      forceNewLpPosition,
      forceNewTokenBalance,
      pnlAcrossMarkets,
      fundingPaymentsAcrossMarkets,
      userDebtAcrossMarkets,
    ],
  );

  return <UserContext.Provider value={userValue}>{children}</UserContext.Provider>;
}

const useUser = () => useContext(UserContext) as UserContextType;

export default useUser;
