import ethFlag from '../assets/images/flags/eth.png';
import usdcLogo from '../assets/images/tokens/usdc.png';

export interface PaymasterOption {
  image: string;
  feeTokenAddress: string;
  feeTokenSymbol: string;
  paymasterName: string;
  promo?: string;
}

export const paymasters: PaymasterOption[] = [
  {
    image: ethFlag,
    feeTokenAddress: '0x0000000000000000000000000000000000000000',
    feeTokenSymbol: 'ETH',
    paymasterName: 'Default',
  },
  {
    image: usdcLogo,
    feeTokenAddress: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
    feeTokenSymbol: 'USDC',
    paymasterName: 'Zyfi Paymaster',
    promo: 'up to 100% off',
  },
];
