import { BigNumber, BigNumberish } from 'ethers';
import { parseUnits, formatUnits, formatEther } from 'ethers/lib/utils';

import { TermsModalContent } from '../components/Modal/TermsModalContent';
import { ModalInfo } from '../contexts/ModalContext';
import { getAcceptedTerms, setAcceptedTerms } from './localStorage';

export const MAX_UINT_256 = BigNumber.from(
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
);
export const MAX_UINT_128 = BigNumber.from('0xffffffffffffffffffffffffffffffff');

export const ensureTermsAccepted = async ({
  setModal,
  closeModal,
}: {
  setModal: (modalInfo: ModalInfo) => void;
  closeModal: () => void;
}): Promise<void> =>
  new Promise((resolve) => {
    if (getAcceptedTerms() || !process.env.REACT_APP_PRODUCTION) {
      resolve();
    } else {
      setModal({
        title: 'Terms of service & Risks and disclaimers',
        content: (
          <TermsModalContent
            onAccept={() => {
              setAcceptedTerms();
              closeModal();
              resolve();
            }}
          />
        ),
      });
    }
  });

export const getMinAmount = (proposedAmount: BigNumber, slippage: number): BigNumber => {
  // Slippage in percentage units (0.5 === 0.5%)
  // Max decimal places = 2
  const multiplier = parseUnits((100 - slippage).toString(), 3);
  const adjuster = parseUnits('1', 5);
  return proposedAmount.mul(multiplier).div(adjuster);
};

export const formatDollarValue = (
  value: BigNumberish,
  decimals?: number,
  noSymbol?: boolean,
  displayDecimals?: number,
): string => {
  try {
    return Number(formatUnits(value, decimals ?? 0)).toLocaleString('en-US', {
      style: noSymbol ? undefined : 'currency',
      currency: 'USD',
      minimumFractionDigits: displayDecimals ?? 0,
      maximumFractionDigits: displayDecimals ?? 0,
    });
  } catch (err) {
    return '-';
  }
};

export const formatShortDollarValue = (value: number): string => {
  if (value >= 10 ** 9)
    return `${value >= 0 ? '' : '-'}$${Math.abs(Math.floor(value / 10 ** 7) / 100).toFixed(2)}b`;
  if (value >= 10 ** 6)
    return `${value >= 0 ? '' : '-'}$${Math.abs(Math.floor(value / 10 ** 4) / 100).toFixed(2)}m`;
  if (value >= 10 ** 3)
    return `${value >= 0 ? '' : '-'}$${Math.abs(Math.floor(value / 10 ** 1) / 100).toFixed(2)}k`;
  return `${value >= 0 ? '' : '-'}$${Math.abs(value).toFixed(2)}`;
};

// Calculate projected APR based on two fee growth values over any time period
// Input values must be in 1e18 decimals
export const calcApr = (
  curr: { value: BigNumber; timestamp: number },
  past: { value: BigNumber; timestamp: number },
): number => {
  const YEAR = 31556926;
  const periodGrowth = Number(formatEther(curr.value.sub(past.value)));

  return periodGrowth === 0 ? 0 : periodGrowth * (YEAR / (curr.timestamp - past.timestamp));
};
