import { BigNumber, BigNumberish } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { mul, div } from '@prb/math';

import { Market } from '../contexts/MarketsContext';
import { GenericCandle } from '../hooks/types';
import { formatDollarValue, formatShortDollarValue } from './general';

BigNumber.prototype.mulWei = function mulWei(other: BigNumberish) {
  return mul(this, BigNumber.from(other));
};

BigNumber.prototype.divWei = function divWei(other: BigNumberish) {
  return div(this, BigNumber.from(other));
};

export interface MarketInfo {
  priceChangeVal?: number;
  priceChange?: string;
  price?: string;
  mark?: string;
  index?: string;
  fundingRateVal?: number;
  fundingRate?: string;
  high?: string;
  low?: string;
  vol?: string;
  openInterest?: string;
  liquidity?: string;
}

export const calcPriceImpact = (
  quoteAmount: BigNumber,
  baseAmount: BigNumber,
  lastPrice: BigNumberish,
): BigNumber => quoteAmount.divWei(baseAmount).sub(lastPrice).divWei(lastPrice);

export const calcLiquidationPrice = (
  minMargin: BigNumber,
  collateral: BigNumber,
  fundingPayments: BigNumber,
  notional: BigNumber,
  positionSize: BigNumber,
): BigNumber =>
  minMargin
    .mulWei(notional.abs())
    .sub(collateral)
    .sub(fundingPayments)
    .sub(notional)
    .divWei(positionSize);

export const calcMinAmount = (dy: BigNumber, slippage: BigNumber) => {
  // Add Slippage
  return dy.sub(dy.mulWei(slippage));
};

export const formatMarketInfo = (selectedMarket: Market | undefined): MarketInfo => {
  if (selectedMarket) {
    const {
      displayDecimals,
      price,
      last,
      markPrice,
      high,
      low,
      indexPrice,
      indexTwap,
      latestGlobalPosition,
      sensitivity,
    } = selectedMarket;
    const priceVal = Number(formatEther(price));
    const prevPriceVal = Number(formatEther(last));
    const changeVal = priceVal - prevPriceVal;
    const changeSymbol = changeVal > 0 ? '+' : '';
    const fundingRateVal = Number(
      formatEther(
        BigNumber.from(sensitivity)
          .mulWei(BigNumber.from(markPrice).sub(indexTwap))
          .divWei(indexTwap),
      ),
    );
    const openInterest = latestGlobalPosition
      ? BigNumber.from(latestGlobalPosition.traderLongs)
          .add(BigNumber.from(latestGlobalPosition.traderShorts))
          .mulWei(indexPrice) ?? 0
      : BigNumber.from(0);
    const lpTokenPrice = latestGlobalPosition?.totalLiquidityProvided
      ? BigNumber.from(latestGlobalPosition.totalBaseProvided)
          .mulWei(indexPrice)
          .add(latestGlobalPosition.totalQuoteProvided)
          .divWei(latestGlobalPosition.totalLiquidityProvided)
      : parseEther('1');

    return {
      price: priceVal.toFixed(displayDecimals),
      priceChangeVal: changeVal,
      mark: Number(formatEther(markPrice)).toFixed(displayDecimals),
      index: Number(formatEther(indexPrice)).toFixed(displayDecimals),
      fundingRateVal,
      fundingRate: `${fundingRateVal > 0 ? '+' : ''}${(fundingRateVal * 100).toFixed(4)}%`,
      high: Number(formatEther(high)).toFixed(displayDecimals),
      low: Number(formatEther(low)).toFixed(displayDecimals),
      priceChange: `${changeSymbol}${changeVal.toFixed(displayDecimals)} (${changeSymbol}${(
        (changeVal / prevPriceVal) *
        100
      ).toFixed(2)}%)`,
      openInterest: formatDollarValue(openInterest, 18, false, 0),
      liquidity: formatShortDollarValue(
        Number(
          formatEther(lpTokenPrice.mulWei(latestGlobalPosition?.totalLiquidityProvided ?? '0')),
        ),
      ),
    };
  }
  return {};
};

export const formatMarketVolume = (candles: GenericCandle[] | undefined): string | undefined =>
  formatShortDollarValue(
    candles?.reduce((acc, val) => acc + Math.abs(Number(formatEther(val.volume))), 0) ?? 0,
  );
