import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { TermsModalContent } from '../components/Modal/TermsModalContent';
import useModal from '../contexts/ModalContext';
import useWeb3 from '../contexts/Web3Context';

interface EnsureTermsSignedResponse {
  signed: boolean;
}

export const useEnsureTermsSigned = (): EnsureTermsSignedResponse => {
  const { address } = useWeb3();
  const { modalContent, setModal, closeModal } = useModal();
  const [signatureSubmitted, setSignatureSubmitted] = useState<boolean>(false);

  const latestSigQuery = useQuery({
    enabled: !!address,
    queryKey: ['latest_sig', address],
    queryFn: async () => {
      return axios
        .get(`${process.env.REACT_APP_TOS_SIGNATURE_SERVICE}/signature/latest/${address}`)
        .then((res) => res.data);
    },
    cacheTime: 100,
  });

  useEffect(() => {
    if (
      latestSigQuery?.data?.signed !== undefined &&
      !latestSigQuery.data.signed &&
      !signatureSubmitted &&
      modalContent === null &&
      process.env.REACT_APP_PRODUCTION
    ) {
      setModal({
        title: 'Terms of service & Risks and disclaimers',
        content: (
          <TermsModalContent
            onAccept={() => {
              closeModal();
              setSignatureSubmitted(true);
              latestSigQuery.refetch();
            }}
          />
        ),
      });
    }
  }, [latestSigQuery, address, setModal, closeModal, modalContent, signatureSubmitted]);

  return {
    signed: latestSigQuery?.data?.signed !== undefined,
  };
};
