import styled from 'styled-components/macro';
import { NavLinkProps } from 'react-router-dom';

import { Nav1 } from '../Text';

export const LinkWrapper = styled(Nav1)`
  position: relative;
  margin: 1.125rem 1.75rem 0rem 1.75rem;
  padding-bottom: 1.33rem;
  text-decoration: none;
  transition: all ${({ theme }) => theme.fast} ease-in-out;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 0.2rem;
    border-radius: 0.066rem;
    background: rgba(0, 0, 0, 0);
  }

  &:hover {
    color: ${({ theme }) => theme.gray3};

    &:after {
      background: ${({ theme }) => theme.gray1};
    }
  }

  &:enabled:active {
    opacity: 0.5;
  }

  &.active {
    color: ${({ theme }) => theme.white};

    &:hover {
      color: ${({ theme }) => theme.white};
    }

    &:after {
      background: ${({ theme }) => theme.gray1};
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    margin: 1.125rem 0rem 0rem 1.75rem;
  }
`;

export function NavLink(props: NavLinkProps) {
  return <LinkWrapper {...props} />;
}
