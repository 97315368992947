import { formatEther } from 'ethers/lib/utils';
import cloneDeep from 'lodash.clonedeep';
import { useMemo, useEffect, useState } from 'react';
import { DataPoint } from '../components/ReChart/types';

import useWeb3 from '../contexts/Web3Context';
import { useGlobalStateQuery } from '../graphql/generated';
import {
  LpPosition,
  Position,
  TokenBalance,
  GlobalPosition,
  MarketPrice,
  TokenPrice,
  calcPortfolioValue,
} from '../utils/data';
import { KeyMap } from '../utils/types';
import { useDeepRefresh } from './useDeepRefresh';

interface UsePortfolioValueParams {
  from?: string;
  allPositions?: KeyMap<Position[]>;
  allLpPositions?: KeyMap<LpPosition[]>;
  allTokenBalances?: KeyMap<TokenBalance[]>;
}

interface PortfolioValue {
  portfolioValues?: DataPoint[];
  globalPositions: KeyMap<GlobalPosition[]>;
  marketPrices: KeyMap<MarketPrice[]>;
  tokenPrices: KeyMap<TokenPrice[]>;
  refreshGlobalState: () => void;
}

const isEmptyReducer = <T>(isEmpty: boolean, val: T[]): boolean => isEmpty && val.length <= 0;

// Reduce to a KeyMap of the first items in each array of a KeyMap
const reduceToFirst = <T>(obj: KeyMap<T[]>): KeyMap<T> => {
  const newObj: KeyMap<T> = {};
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    [newObj[key]] = obj[key];
  }
  return newObj;
};

// Reduce KeyMap to array for first items in each array
const reduceToArrOfFirst = <T>(obj: KeyMap<T[]>): T[] =>
  Object.values(obj).reduce((res, curr) => (curr[0] ? [...res, curr[0]] : res), []);

// Shift the array with the largest timestamp given a keymap of arrays
// returns the shifted item
const shiftLatest = <T extends { timestamp: string }>(entities: KeyMap<T[]>[]): T | undefined => {
  let latestTimestamp = '-1';
  const latestEntities: { index: number; market: string }[] = [];

  entities.forEach((entity, index) => {
    const keys = Object.keys(entity);
    keys.forEach((market) => {
      if (entity[market][0]) {
        const { timestamp } = entity[market][0];
        if (Number(timestamp) >= Number(latestTimestamp)) {
          latestTimestamp = timestamp;
          latestEntities.push({
            index,
            market,
          });
        }
      }
    });
  });

  let latestEntity: T | undefined;
  latestEntities.forEach(({ index, market }) => {
    const entity = entities[index][market].shift();
    if (entity) {
      latestEntity = entity;
    }
  });

  return latestEntity;
};

const reduceToDataPoints = (
  globalPositions: KeyMap<GlobalPosition[]>,
  marketPrices: KeyMap<MarketPrice[]>,
  tokenPrices: KeyMap<TokenPrice[]>,
  allPositions: KeyMap<Position[]>,
  allLpPositions: KeyMap<LpPosition[]>,
  allTokenBalances: KeyMap<TokenBalance[]>,
): DataPoint[] => {
  const dataPoints: DataPoint[] = [];
  const tempGlobalPositions = cloneDeep(globalPositions);
  const tempMarketPrices = cloneDeep(marketPrices);
  const tempTokenPrices = cloneDeep(tokenPrices);
  const tempPositions = cloneDeep(allPositions);
  const tempLpPositions = cloneDeep(allLpPositions);
  const tempTokenBalances = cloneDeep(allTokenBalances);

  // Loop while there are still positions, lpPositions or collateral balances
  while (
    !Object.values(tempGlobalPositions).reduce(isEmptyReducer, true) &&
    !Object.values(tempMarketPrices).reduce(isEmptyReducer, true) &&
    !Object.values(tempTokenPrices).reduce(isEmptyReducer, true) &&
    (!Object.values(tempPositions).reduce(isEmptyReducer, true) ||
      !Object.values(tempLpPositions).reduce(isEmptyReducer, true) ||
      !Object.values(tempTokenBalances).reduce(isEmptyReducer, true))
  ) {
    // Get the value using the latest inputs
    const value = Number(
      formatEther(
        calcPortfolioValue({
          globalPositions: reduceToFirst(tempGlobalPositions),
          marketPrices: reduceToFirst(tempMarketPrices),
          tokenPrices: reduceToFirst(tokenPrices),
          positions: reduceToArrOfFirst(tempPositions),
          lpPositions: reduceToArrOfFirst(tempLpPositions),
          tokenBalances: reduceToArrOfFirst(tempTokenBalances),
        }),
      ),
    );
    // Shift the latest input and grab the time
    const time = shiftLatest<
      GlobalPosition | MarketPrice | TokenPrice | Position | LpPosition | TokenBalance
    >([
      tempGlobalPositions,
      tempMarketPrices,
      tempTokenPrices,
      tempPositions,
      tempLpPositions,
      tempTokenBalances,
    ])?.timestamp;

    // Add value to dataPoints array
    if (value && time) {
      dataPoints.push({
        value,
        time,
      });
    }
  }

  return dataPoints.sort((last, curr) => Number(last.time) - Number(curr.time));
};

const usePortfolioValue = ({
  from,
  allPositions,
  allLpPositions,
  allTokenBalances,
}: UsePortfolioValueParams): PortfolioValue => {
  const [portfolioValues, setPortfolioValues] = useState<DataPoint[]>();

  const { address } = useWeb3();

  const [globalInfoQuery, invalidateQuery] = useGlobalStateQuery({
    variables: { user: address?.toLocaleLowerCase() || '', from },
    pause: !address || !from,
  });

  const [globalStateData, refreshGlobalState] = useDeepRefresh({
    queryData: globalInfoQuery.data,
    fetching: globalInfoQuery.fetching,
    invalidateQuery,
    id: 'global-state',
  });

  const { globalPositions, marketPrices, tokenPrices } = useMemo<{
    globalPositions: KeyMap<GlobalPosition[]>;
    marketPrices: KeyMap<MarketPrice[]>;
    tokenPrices: KeyMap<TokenPrice[]>;
  }>(() => {
    if (globalStateData) {
      const newGlobalPositions: KeyMap<GlobalPosition[]> = {};
      const newMarketPrices: KeyMap<MarketPrice[]> = {};
      const newTokenPrices: KeyMap<TokenPrice[]> = {};

      // Loop through Markets that the user has had positions in and set the
      // price & global positions for each market
      globalStateData.user?.currentPositions.forEach((position) => {
        const marketId = position.market.id;
        newGlobalPositions[marketId] = [
          ...(position.market.restGlobalPositions ?? []),
          ...(position.market.firstGlobalPosition ?? []),
        ].map((aggregate) => ({
          ...aggregate,
          timestamp: aggregate.closeTimestamp,
        })) as GlobalPosition[];
        newMarketPrices[marketId] = [
          ...(position.market.restPrices.map((price) => price.lastPrice) ?? []),
          ...(position.market.firstPrice ?? []),
        ] as MarketPrice[];
      });

      // Loop through Markets that the user has had LP positions in and set the
      // price & global positions for each market
      globalStateData.user?.currentLpPositions.forEach((position) => {
        const marketId = position.market.id;
        newGlobalPositions[marketId] = [
          ...(position.market.restGlobalPositions ?? []),
          ...(position.market.firstGlobalPosition ?? []),
        ].map((aggregate) => ({
          ...aggregate,
          timestamp: aggregate.closeTimestamp,
        })) as GlobalPosition[];
        newMarketPrices[marketId] = [
          ...(position.market.restPrices.map((price) => price.lastPrice) ?? []),
          ...(position.market.firstPrice ?? []),
        ] as MarketPrice[];
      });

      // Loop through Tokens that the user has had balances of and set the
      // price history for each token
      globalStateData.user?.currentTokenBalances.forEach((tokenBalance) => {
        const tokenId = tokenBalance.token.id;
        newTokenPrices[tokenId] = [
          ...(tokenBalance.token.rest ?? []),
          ...(tokenBalance.token.first ?? []),
        ] as TokenPrice[];
      });

      return {
        globalPositions: newGlobalPositions,
        marketPrices: newMarketPrices,
        tokenPrices: newTokenPrices,
      };
    }
    return { globalPositions: {}, marketPrices: {}, tokenPrices: {} };
  }, [globalStateData]);

  useEffect(() => {
    if (
      (globalPositions && marketPrices && allPositions && allLpPositions) ||
      (tokenPrices && allTokenBalances)
    ) {
      setPortfolioValues(
        reduceToDataPoints(
          globalPositions,
          marketPrices,
          tokenPrices,
          allPositions ?? {},
          allLpPositions ?? {},
          allTokenBalances ?? {},
        ),
      );
    }
  }, [globalPositions, marketPrices, tokenPrices, allPositions, allLpPositions, allTokenBalances]);

  return {
    globalPositions,
    marketPrices,
    tokenPrices,
    portfolioValues,
    refreshGlobalState,
  };
};

export default usePortfolioValue;
