import { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { renderIcon } from '@download/blockies';

const Icon = styled.canvas`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2rem;
`;

interface AvatarProps {
  address?: string;
}

export function Avatar({ address }: AvatarProps) {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (address && canvasRef.current) {
      renderIcon({ seed: address.toLowerCase() }, canvasRef.current);
    }
  }, [address, canvasRef]);

  return <Icon ref={canvasRef} />;
}

Avatar.defaultProps = {
  address: '',
};
