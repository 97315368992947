import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { BigNumber } from 'ethers';
import { parseEther, formatEther, parseUnits /* , formatUnits  */ } from 'ethers/lib/utils';

import useUser from './UserContext';
import useMarkets from './MarketsContext';
import useWeb3 from './Web3Context';
import { Position } from '../utils/data';
import { getDyFeesPerc, getPriceImpact } from '../utils/curve';
import { calcLiquidationPrice, calcMinAmount } from '../utils/helpers';

interface TradeLogicContextType {
  currentPosition?: Position;
  proposedAmount: BigNumber;
  minAmount: BigNumber;
  tradingFee: BigNumber;
  insuranceFee: BigNumber;
  entryPrice?: BigNumber;
  liquidationPrice?: BigNumber;
  priceImpact?: BigNumber;
  combinedOpenNotional: BigNumber;
  maxLeverage: number;
  baseLeverage: number;
  isReversing: boolean;
  maxQuoteVal: BigNumber;
  newMargin?: BigNumber;
  buyingPower: BigNumber;
  buyingPowerChange: BigNumber;
  setQuoteInput: (newVal?: BigNumber) => void;
  setSlippage: (newVal: number) => void;
  setIsLong: (newVal: boolean) => void;
  isLong: boolean;
  slippage: number;
  quoteInput?: BigNumber;
  addedPositionSize: BigNumber;
  combinedPositionSize: BigNumber;
}

const TradeLogicContext = createContext<TradeLogicContextType>({
  proposedAmount: BigNumber.from(0),
  minAmount: BigNumber.from(0),
  tradingFee: BigNumber.from(0),
  insuranceFee: BigNumber.from(0),
  combinedOpenNotional: BigNumber.from(0),
  maxLeverage: 0,
  baseLeverage: 0,
  isReversing: false,
  maxQuoteVal: BigNumber.from(0),
  buyingPower: BigNumber.from(0),
  buyingPowerChange: BigNumber.from(0),
  setQuoteInput: (newVal?: BigNumber) => newVal,
  setSlippage: (newVal: number) => newVal,
  setIsLong: (newVal: boolean) => newVal,
  isLong: true,
  slippage: 0,
  addedPositionSize: BigNumber.from(0),
  combinedPositionSize: BigNumber.from(0),
});

export function TradeLogicProvider({ children }: { children: React.ReactNode }) {
  const [quoteInput, setQuoteInput] = useState<BigNumber>();
  const [slippage, setSlippage] = useState<number>(0);
  const [isLong, setIsLong] = useState<boolean>(true);

  // (dy/dx - marketPrice) / marketPrice
  const [priceImpact, setPriceImpact] = useState<BigNumber>();
  // getDy()
  const [dy, setDy] = useState<BigNumber>();
  // quoteInput * (get_dy_fees_perc + insuranceFee)
  const [tradingFeePerc, setTradingFeePerc] = useState<BigNumber>();

  const { selectedMarket } = useMarkets();
  const { provider, chainConfig } = useWeb3();
  const {
    currentPositions,
    freeCollateral,
    reserveValue,
    pnlAcrossMarkets,
    fundingPaymentsAcrossMarkets,
    userDebtAcrossMarkets,
  } = useUser();

  const currentPosition = useMemo<Position | undefined>(
    () =>
      selectedMarket &&
      currentPositions?.find((pos: Position) => pos.market.id === selectedMarket.id),
    [currentPositions, selectedMarket],
  );

  // if long: (indexPrice / marketPrice) - 1
  // if short: (marketPrice / indexPrice) - 1
  // freeCollateral / (selectedMarket.minMarginAtCreation * riskWeight + outFee + insuranceFee + min(estPnlPerc, 0))
  // note: if there is a current position open, add or subtract the current openNotional
  const maxQuoteVal = useMemo(() => {
    let returnVal = BigNumber.from(0);
    if (selectedMarket?.minMarginAtCreation && selectedMarket && freeCollateral) {
      const totalFeesAndPnlPerc = parseUnits(selectedMarket.outFee, 8)
        .mul(2) // fee to close position should also be charged
        .add(selectedMarket.insuranceFee);
      const maxExclFees = freeCollateral.divWei(
        BigNumber.from(selectedMarket.minMarginAtCreation).mulWei(selectedMarket.riskWeight),
      );
      const maxAdded = freeCollateral
        .sub(maxExclFees.mulWei(totalFeesAndPnlPerc))
        .divWei(
          BigNumber.from(selectedMarket.minMarginAtCreation).mulWei(selectedMarket.riskWeight),
        );

      // no current position
      returnVal = maxAdded;

      if (currentPosition) {
        // if going long and a short position is open
        if (isLong && !BigNumber.from(currentPosition.openNotional).isNegative()) {
          returnVal = maxAdded.add(BigNumber.from(currentPosition.openNotional).mul(2));
        } else if (!isLong && BigNumber.from(currentPosition.openNotional).isNegative()) {
          returnVal = maxAdded.add(
            BigNumber.from(currentPosition.positionSize).mulWei(selectedMarket.indexPrice).mul(2),
          );
        }
      }
    }
    return returnVal.lt(0) ? BigNumber.from(0) : returnVal;
  }, [freeCollateral, selectedMarket, currentPosition, isLong]);

  /*
   * Values calculated on demand when user changes input
   */

  // entryPrice = marketPrice + (marketPrice * priceImpact)
  const entryPrice = useMemo<BigNumber | undefined>(() => {
    if (priceImpact !== undefined && selectedMarket !== undefined) {
      const currentPrice = BigNumber.from(selectedMarket.price);
      return currentPrice.add(currentPrice.mulWei(priceImpact));
    }
    return undefined;
  }, [selectedMarket, priceImpact]);

  // true if the user has a current position open,
  // and their input will cause a change in the position direction
  const isReversing = useMemo<boolean>(
    () =>
      !!(
        currentPosition &&
        quoteInput &&
        quoteInput.abs().gt(BigNumber.from(currentPosition.openNotional).abs()) &&
        ((!isLong && BigNumber.from(currentPosition.openNotional).lt(0)) ||
          (isLong && BigNumber.from(currentPosition.openNotional).gt(0)))
      ),
    [quoteInput, currentPosition, isLong],
  );

  // if long: addedPositionSize = dy
  // if short: addedPositionSize = -quoteInput / indexPrice
  const addedPositionSize = useMemo<BigNumber>(() => {
    if (
      quoteInput !== undefined &&
      !quoteInput.eq(0) &&
      selectedMarket !== undefined &&
      dy !== undefined
    ) {
      if (isLong) {
        if (isReversing) {
          return dy.add(
            BigNumber.from(currentPosition?.openNotional).divWei(selectedMarket.indexPrice).abs(),
          );
        }
        return dy;
      }
      return quoteInput.divWei(selectedMarket.indexPrice).mul(-1);
    }
    return BigNumber.from(0);
  }, [quoteInput, selectedMarket, isLong, dy, isReversing, currentPosition]);

  // if long: addedOpenNotional = -quoteInput
  // if short: addedOpenNotional = dy
  const addedOpenNotional = useMemo<BigNumber>(() => {
    if (quoteInput !== undefined && dy !== undefined) {
      if (isLong) {
        return quoteInput.mul(-1);
      }
      if (isReversing) {
        return dy.add(BigNumber.from(currentPosition?.openNotional).abs());
      }
      return dy;
    }
    return BigNumber.from(0);
  }, [quoteInput, isLong, dy, isReversing, currentPosition]);

  // addedOpenNotional + currentOpenNotional
  const combinedOpenNotional = useMemo<BigNumber>(
    () => addedOpenNotional.add(currentPosition?.openNotional ?? '0'),
    [addedOpenNotional, currentPosition],
  );

  // addedPositionSize + currentPositionSize
  const combinedPositionSize = useMemo<BigNumber>(
    () => addedPositionSize.add(currentPosition?.positionSize ?? '0'),
    [addedPositionSize, currentPosition],
  );

  // Max fee to close position
  // closingFees = addedPositionSize.abs() * indexPrice * outFee
  const closingFees = useMemo<BigNumber>(
    () =>
      selectedMarket !== undefined
        ? addedPositionSize
            .abs()
            .mulWei(selectedMarket.indexPrice)
            .mulWei(parseUnits(selectedMarket.outFee, 8))
        : BigNumber.from(0),
    [addedPositionSize, selectedMarket],
  );

  // if long: quoteInput
  // if short: quoteInput / indexPrice
  const proposedAmount = useMemo<BigNumber>(() => {
    if (quoteInput !== undefined && selectedMarket !== undefined) {
      if (currentPosition && isReversing) {
        if (isLong) {
          return quoteInput.sub(
            BigNumber.from(currentPosition.positionSize).abs().mulWei(selectedMarket.indexPrice),
          );
        }
        return quoteInput
          .sub(BigNumber.from(currentPosition.openNotional).abs())
          .divWei(selectedMarket.indexPrice);
      }
      if (isLong) {
        return quoteInput;
      }
      return quoteInput.divWei(selectedMarket.indexPrice);
    }
    return BigNumber.from(0);
  }, [isLong, quoteInput, selectedMarket, currentPosition, isReversing]);

  // instantPnl = addedOpenNotional + (addedPositionSize * indexPrice) - closingFees
  const instantPnl = useMemo<BigNumber>(() => {
    if (selectedMarket && dy) {
      if (isLong) {
        return proposedAmount.mul(-1).add(dy.mulWei(selectedMarket.indexPrice)).sub(closingFees);
      }
      return dy.sub(proposedAmount.mulWei(selectedMarket.indexPrice)).sub(closingFees);
    }
    return BigNumber.from(0);
  }, [selectedMarket, closingFees, proposedAmount, dy, isLong]);

  // addedOpenNotional * insuranceFee
  const insuranceFee = useMemo<BigNumber>(
    () =>
      selectedMarket !== undefined
        ? addedOpenNotional.abs().mulWei(selectedMarket.insuranceFee)
        : BigNumber.from(0),
    [addedOpenNotional, selectedMarket],
  );

  // addedOpenNotional * tradingFeePerc
  const tradingFee = useMemo<BigNumber>(
    () =>
      tradingFeePerc !== undefined
        ? addedOpenNotional.abs().mulWei(tradingFeePerc)
        : BigNumber.from(0),
    [tradingFeePerc, addedOpenNotional],
  );

  const isReducing = useMemo<boolean>(() => {
    if (currentPosition) {
      const currentPositionSize = BigNumber.from(currentPosition.positionSize);
      if (
        currentPositionSize.gt(0) &&
        addedPositionSize.lt(0) &&
        addedPositionSize.abs().lte(currentPositionSize)
      ) {
        return true;
      }
      if (
        currentPositionSize.lt(0) &&
        addedPositionSize.gt(0) &&
        addedPositionSize.lte(currentPositionSize.abs())
      ) {
        return true;
      }
    }
    return false;
  }, [currentPosition, addedPositionSize]);

  const liquidationPrice = useMemo<BigNumber | undefined>(() => {
    if (
      quoteInput !== undefined &&
      !quoteInput.eq(0) &&
      selectedMarket !== undefined &&
      selectedMarket.minMargin !== undefined &&
      freeCollateral !== undefined &&
      !BigNumber.from(freeCollateral).eq(0)
    ) {
      try {
        return calcLiquidationPrice(
          BigNumber.from(selectedMarket.minMargin),
          freeCollateral,
          BigNumber.from(0),
          combinedOpenNotional,
          combinedPositionSize,
        );
      } catch (err) {
        console.warn(err);
      }
    }
    return undefined;
  }, [selectedMarket, combinedOpenNotional, combinedPositionSize, freeCollateral, quoteInput]);

  const minAmount = useMemo<BigNumber>(() => {
    if (!addedPositionSize.eq(0) && slippage !== undefined && dy !== undefined) {
      return calcMinAmount(dy, parseEther(slippage.toString()));
    }
    return BigNumber.from(0);
  }, [addedPositionSize, slippage, dy]);

  // currenOpenNotional * riskWeight
  const currentDebt = useMemo<BigNumber>(() => {
    if (currentPosition !== undefined && selectedMarket !== undefined) {
      if (isLong) {
        return BigNumber.from(currentPosition.openNotional).mulWei(selectedMarket?.riskWeight);
      }
      return BigNumber.from(currentPosition.positionSize)
        .mulWei(selectedMarket.indexPrice)
        .mulWei(selectedMarket?.riskWeight);
    }
    return BigNumber.from(0);
  }, [currentPosition, selectedMarket, isLong]);

  // min(positionSize * indexPrice, openNotional).abs() * riskWeight
  const newDebt = useMemo<BigNumber>(() => {
    if (selectedMarket) {
      // user is going short
      if (addedPositionSize.lt(0)) {
        // user is decreasing their debt
        if (isReducing) {
          return addedOpenNotional.abs().mulWei(selectedMarket.riskWeight).mul('-1');
        }
        // user is reversing their position, current debt should be deducted from new debt
        if (isReversing) {
          return addedOpenNotional
            .abs()
            .sub(
              BigNumber.from(currentPosition?.positionSize ?? '0')
                .abs()
                .mulWei(selectedMarket.indexPrice)
                .mul(2),
            )
            .mulWei(selectedMarket.riskWeight);
        }
        // user is increasing their debt
        return addedPositionSize
          .mulWei(selectedMarket.indexPrice)
          .abs()
          .mulWei(selectedMarket.riskWeight);
      }
      // user is going long
      if (isReducing) {
        // user is decreasing their debt
        return addedPositionSize
          .mulWei(selectedMarket.indexPrice)
          .abs()
          .mulWei(selectedMarket.riskWeight)
          .mul('-1');
      }
      if (isReversing) {
        // user is reversing their position, current debt should be deducted from new debt
        return addedPositionSize
          .mulWei(selectedMarket.indexPrice)
          .abs()
          .sub(
            BigNumber.from(currentPosition?.openNotional ?? '0')
              .abs()
              .mul(2),
          )
          .mulWei(selectedMarket.riskWeight);
      }
      // user is increasing their debt
      return addedOpenNotional.abs().mulWei(selectedMarket.riskWeight);
    }
    return BigNumber.from(0);
  }, [
    addedPositionSize,
    addedOpenNotional,
    selectedMarket,
    isReversing,
    isReducing,
    currentPosition,
  ]);

  // reserveValue + min(pnlAcrossMarkets, 0) + fundingPaymentsAcrossMarkets
  const accountValue = useMemo<BigNumber>(
    () =>
      reserveValue !== undefined &&
      pnlAcrossMarkets !== undefined &&
      fundingPaymentsAcrossMarkets !== undefined
        ? reserveValue
            .add(pnlAcrossMarkets.gt(0) ? '0' : pnlAcrossMarkets)
            .add(fundingPaymentsAcrossMarkets)
        : BigNumber.from(0),
    [reserveValue, pnlAcrossMarkets, fundingPaymentsAcrossMarkets],
  );

  // userDebtAcrossMarkets / accountValue
  const accountLeverage = useMemo<BigNumber>(
    () =>
      accountValue.gt(0) && userDebtAcrossMarkets !== undefined
        ? userDebtAcrossMarkets.divWei(accountValue).abs()
        : BigNumber.from(0),
    [accountValue, userDebtAcrossMarkets],
  );

  // currentDebt / accountValue
  const baseLeverage = useMemo<number>(
    () =>
      accountValue.gt(0)
        ? Number(formatEther(currentDebt.divWei(accountValue).mul(isLong ? '-1' : '1')))
        : 0,
    [currentDebt, accountValue, isLong],
  );

  // (1 / selectedMarket.minMarginAtCreation) - accountLeverage
  const maxLeverage = useMemo<number>(
    () =>
      selectedMarket?.minMarginAtCreation !== undefined
        ? Number(
            formatEther(
              parseEther('1')
                .divWei(BigNumber.from(selectedMarket.minMarginAtCreation))
                .sub(accountLeverage),
            ),
          )
        : 0,
    [selectedMarket, accountLeverage],
  );

  // accountValue * maxLeverage
  const buyingPower = useMemo<BigNumber>(
    () =>
      accountValue !== undefined && maxLeverage !== undefined
        ? accountValue.mulWei(parseEther(maxLeverage.toFixed(18)))
        : BigNumber.from(0),
    [accountValue, maxLeverage],
  );

  const buyingPowerChange = useMemo<BigNumber>(() => {
    const currentOpenNotional = BigNumber.from(currentPosition?.openNotional ?? '0');
    if (!currentOpenNotional.eq(0) && !addedOpenNotional.eq(0)) {
      if (currentOpenNotional.lt(0) && isReversing) {
        return currentOpenNotional.mul('-2').sub(addedOpenNotional);
      }
      if (currentOpenNotional.lt(0)) {
        return addedOpenNotional;
      }
      if (isReversing) {
        return addedOpenNotional.add(currentOpenNotional.mul('2'));
      }
      return addedOpenNotional.mul('-1');
    }
    if (!addedOpenNotional.eq(0)) {
      return addedOpenNotional.abs().mul('-1');
    }
    return BigNumber.from(0);
  }, [currentPosition, addedOpenNotional, isReversing]);

  // (userDebtAcrossMarkets + addedOpenNotional) / (accountValue + min(instantPnl, 0) + insuranceFee + tradingFee)
  // <=
  // 1 / (selectedMarket.minMarginAtCreation * riskWeight)
  const newMargin = useMemo<BigNumber | undefined>(() => {
    if (
      userDebtAcrossMarkets &&
      selectedMarket?.minMarginAtCreation &&
      accountValue.gt(0) &&
      !newDebt.eq(0)
    ) {
      const newAccountValue = accountValue
        .add(instantPnl.gt(0) ? '0' : instantPnl)
        .sub(insuranceFee)
        .sub(tradingFee);

      if (newAccountValue.lt(0)) return undefined;

      return newAccountValue.divWei(userDebtAcrossMarkets.add(newDebt));
    }
    return undefined;
  }, [
    userDebtAcrossMarkets,
    accountValue,
    instantPnl,
    insuranceFee,
    tradingFee,
    selectedMarket,
    newDebt,
  ]);

  useEffect(() => {
    let subscribed = true;
    if (
      selectedMarket?.cryptoSwapPool &&
      selectedMarket?.price &&
      isLong !== undefined &&
      provider &&
      proposedAmount.gt(0)
    ) {
      getPriceImpact({
        cryptoSwapAddress: selectedMarket.cryptoSwapPool,
        signerOrProvider: provider,
        isLong,
        currentPrice: selectedMarket.price,
        proposedAmount,
      })
        .then(({ priceImpact: priceImpactResult, dy: dyResult }) => {
          if (subscribed) {
            setDy(dyResult);
            setPriceImpact(priceImpactResult);
          }
        })
        .catch((err) => {
          console.warn(err);
          setPriceImpact(undefined);
        });
    } else {
      setPriceImpact(undefined);
    }
    return () => {
      subscribed = false;
    };
  }, [isLong, provider, selectedMarket, proposedAmount]);

  useEffect(() => {
    let subscribed = true;
    if (
      proposedAmount !== undefined &&
      provider !== undefined &&
      selectedMarket?.cryptoSwapPool !== undefined &&
      quoteInput !== undefined &&
      proposedAmount.gt(0)
    ) {
      getDyFeesPerc({
        chainConfig,
        cryptoSwapAddress: selectedMarket.cryptoSwapPool,
        signerOrProvider: provider,
        isLong,
        proposedAmount,
      }).then((swapFee) => {
        if (subscribed) {
          setTradingFeePerc(swapFee);
        }
      });
    }
    return () => {
      subscribed = false;
    };
  }, [selectedMarket, provider, chainConfig, isLong, proposedAmount, quoteInput]);

  const contextValue = useMemo(
    () => ({
      currentPosition,
      proposedAmount,
      minAmount,
      tradingFee,
      insuranceFee,
      entryPrice,
      liquidationPrice,
      priceImpact,
      combinedOpenNotional,
      maxLeverage,
      baseLeverage,
      isReversing,
      maxQuoteVal,
      newMargin,
      buyingPower,
      buyingPowerChange,
      setSlippage,
      setIsLong,
      setQuoteInput,
      isLong,
      quoteInput,
      slippage,
      addedPositionSize,
      combinedPositionSize,
    }),
    [
      currentPosition,
      proposedAmount,
      minAmount,
      tradingFee,
      insuranceFee,
      entryPrice,
      liquidationPrice,
      priceImpact,
      combinedOpenNotional,
      maxLeverage,
      baseLeverage,
      isReversing,
      maxQuoteVal,
      newMargin,
      buyingPower,
      buyingPowerChange,
      setSlippage,
      setIsLong,
      setQuoteInput,
      isLong,
      quoteInput,
      slippage,
      addedPositionSize,
      combinedPositionSize,
    ],
  );

  return <TradeLogicContext.Provider value={contextValue}>{children}</TradeLogicContext.Provider>;
}
const useTradeLogic = () => useContext(TradeLogicContext) as TradeLogicContextType;

export default useTradeLogic;
