/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  ClearingHouseViewer,
  ClearingHouseViewerInterface,
} from "../ClearingHouseViewer";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IClearingHouse",
        name: "_clearingHouse",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "ClearingHouseViewer_LpTokenAmountPassedLargerThanBalance",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouseViewer_ReductionRatioTooLarge",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "paramIndex",
        type: "uint8",
      },
    ],
    name: "ClearingHouseViewer_ZeroAddressConstructor",
    type: "error",
  },
  {
    inputs: [],
    name: "PRBMathSD59x18__AbsInputTooSmall",
    type: "error",
  },
  {
    inputs: [],
    name: "PRBMathSD59x18__DivInputTooSmall",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "rAbs",
        type: "uint256",
      },
    ],
    name: "PRBMathSD59x18__DivOverflow",
    type: "error",
  },
  {
    inputs: [],
    name: "PRBMathSD59x18__MulInputTooSmall",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "rAbs",
        type: "uint256",
      },
    ],
    name: "PRBMathSD59x18__MulOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivFixedPointOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "denominator",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "accountLeverage",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "targetContract",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "target",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "iter",
        type: "uint256",
      },
    ],
    name: "binarySearch",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "clearingHouse",
    outputs: [
      {
        internalType: "contract IClearingHouse",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenIdx",
        type: "uint256",
      },
    ],
    name: "getAllowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenIdx",
        type: "uint256",
      },
    ],
    name: "getBalance",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "getBaseBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "getBaseDust",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256[2]",
        name: "amounts",
        type: "uint256[2]",
      },
    ],
    name: "getExpectedLpTokenAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "vQuoteAmountToSpend",
        type: "uint256",
      },
    ],
    name: "getExpectedVBaseAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "vQuoteAmountToSpend",
        type: "uint256",
      },
    ],
    name: "getExpectedVBaseAmountExFees",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "vQuoteAmountToReceive",
        type: "uint256",
      },
    ],
    name: "getExpectedVBaseToReceiveAmountExFees",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "vBaseAmountToSpend",
        type: "uint256",
      },
    ],
    name: "getExpectedVQuoteAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "vBaseAmountToSpend",
        type: "uint256",
      },
    ],
    name: "getExpectedVQuoteAmountExFees",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "vBaseAmountToReceive",
        type: "uint256",
      },
    ],
    name: "getExpectedVQuoteAmountToReceiveExFees",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "lpTokenAmountToWithdraw",
        type: "uint256",
      },
    ],
    name: "getExpectedVirtualTokenAmountsFromLpTokenAmount",
    outputs: [
      {
        internalType: "uint256[2]",
        name: "",
        type: "uint256[2]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "int256",
        name: "ratio",
        type: "int256",
      },
    ],
    name: "getFreeCollateralByRatio",
    outputs: [
      {
        internalType: "int256",
        name: "freeCollateral",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getFundingPayments",
    outputs: [
      {
        internalType: "int256",
        name: "pendingFunding",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getFundingPaymentsAcrossMarkets",
    outputs: [
      {
        internalType: "int256",
        name: "fundingPayments",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "getGlobalPosition",
    outputs: [
      {
        components: [
          {
            internalType: "uint64",
            name: "timeOfLastTrade",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "timeOfLastTwapUpdate",
            type: "uint64",
          },
          {
            internalType: "int128",
            name: "cumFundingRate",
            type: "int128",
          },
          {
            internalType: "uint128",
            name: "totalQuoteProvided",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalBaseProvided",
            type: "uint128",
          },
          {
            internalType: "int128",
            name: "cumFundingPerLpToken",
            type: "int128",
          },
          {
            internalType: "uint128",
            name: "currentBlockTradeAmount",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalTradingFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalBaseFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalQuoteFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "traderLongs",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "traderShorts",
            type: "uint128",
          },
        ],
        internalType: "struct LibPerpetual.GlobalPosition",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "reductionRatio",
        type: "uint256",
      },
      {
        internalType: "uint256[2]",
        name: "minVTokenAmounts",
        type: "uint256[2]",
      },
    ],
    name: "getLpDx",
    outputs: [
      {
        internalType: "uint256",
        name: "proceeds",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "reductionRatio",
        type: "uint256",
      },
      {
        internalType: "uint256[2]",
        name: "minVTokenAmounts",
        type: "uint256[2]",
      },
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
    ],
    name: "getLpDy",
    outputs: [
      {
        internalType: "uint256",
        name: "proceeds",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpEstimatedPnl",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpFundingPayments",
    outputs: [
      {
        internalType: "int256",
        name: "pendingFunding",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpPosition",
    outputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "openNotional",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "positionSize",
            type: "int128",
          },
          {
            internalType: "uint128",
            name: "liquidityBalance",
            type: "uint128",
          },
          {
            internalType: "uint64",
            name: "depositTime",
            type: "uint64",
          },
          {
            internalType: "uint128",
            name: "totalTradingFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalBaseFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalQuoteFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "int128",
            name: "cumFundingPerLpToken",
            type: "int128",
          },
        ],
        internalType: "struct LibPerpetual.LiquidityProviderPosition",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpPositionAfterWithdrawal",
    outputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "openNotional",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "positionSize",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "cumFundingRate",
            type: "int128",
          },
        ],
        internalType: "struct LibPerpetual.TraderPosition",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "reductionRatio",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "iter",
        type: "uint256",
      },
      {
        internalType: "uint256[2]",
        name: "minVTokenAmounts",
        type: "uint256[2]",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
    ],
    name: "getLpProposedAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "idx",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "reductionRatio",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "iter",
            type: "uint256",
          },
          {
            internalType: "uint256[2]",
            name: "minVTokenAmounts",
            type: "uint256[2]",
          },
          {
            internalType: "uint256",
            name: "minAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "precision",
            type: "uint256",
          },
        ],
        internalType: "struct IClearingHouseViewer.LpProposedAmountArgs",
        name: "args",
        type: "tuple",
      },
    ],
    name: "getLpProposedAmountStruct",
    outputs: [
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpTradingFees",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpUnrealizedPnL",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "getMarket",
    outputs: [
      {
        internalType: "contract ICryptoSwap",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "getQuoteBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "bool",
        name: "isDiscounted",
        type: "bool",
      },
    ],
    name: "getReserveValue",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "getTotalLiquidityProvided",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
      {
        internalType: "enum LibPerpetual.Side",
        name: "direction",
        type: "uint8",
      },
    ],
    name: "getTraderDy",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getTraderFundingPayments",
    outputs: [
      {
        internalType: "int256",
        name: "pendingFunding",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getTraderPosition",
    outputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "openNotional",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "positionSize",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "cumFundingRate",
            type: "int128",
          },
        ],
        internalType: "struct LibPerpetual.TraderPosition",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "reductionRatio",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "iter",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
    ],
    name: "getTraderProposedAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "idx",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "reductionRatio",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "iter",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "minAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "precision",
            type: "uint256",
          },
        ],
        internalType: "struct IClearingHouseViewer.TraderProposedAmountArgs",
        name: "args",
        type: "tuple",
      },
    ],
    name: "getTraderProposedAmountStruct",
    outputs: [
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getTraderUnrealizedPnL",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "getUpdatedFundingRate",
    outputs: [
      {
        internalType: "int256",
        name: "cumFundingRate",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "cumFundingPerLpToken",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "getUpdatedTwap",
    outputs: [
      {
        internalType: "int256",
        name: "oracleTwap",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "marketTwap",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "indexPrice",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "insuranceFee",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isLpPositionOpen",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "int256",
        name: "ratio",
        type: "int256",
      },
    ],
    name: "isMarginValid",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isPositionOpen",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isTraderPositionOpen",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "marginRatio",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "marketLeverage",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "marketPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "perpetual",
    outputs: [
      {
        internalType: "contract IPerpetual",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "liquidityAmountToRemove",
        type: "uint256",
      },
      {
        internalType: "uint256[2]",
        name: "minVTokenAmounts",
        type: "uint256[2]",
      },
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
      {
        internalType: "enum LibPerpetual.Side",
        name: "direction",
        type: "uint8",
      },
      {
        internalType: "bool",
        name: "withCurveTradingFees",
        type: "bool",
      },
    ],
    name: "removeLiquiditySwap",
    outputs: [
      {
        internalType: "uint256",
        name: "proceeds",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "totalLpTokenSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class ClearingHouseViewer__factory {
  static readonly abi = _abi;
  static createInterface(): ClearingHouseViewerInterface {
    return new utils.Interface(_abi) as ClearingHouseViewerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ClearingHouseViewer {
    return new Contract(address, _abi, signerOrProvider) as ClearingHouseViewer;
  }
}
