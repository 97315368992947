import { useEffect, useState } from 'react';

interface AccessCheck {
  accessAllowed?: boolean;
  loading: boolean;
  error: Error | null;
}

export const useAccessCheck = (): AccessCheck => {
  const [accessAllowed, setAccessAllowed] = useState<boolean>();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let subscribed = true;
    fetch(process.env.REACT_APP_ACCESS_ENDPOINT)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (subscribed) {
          setAccessAllowed(data.accessAllowed);
        }
      })
      .catch(setError)
      .finally(() => {
        if (subscribed) {
          setLoading(false);
        }
      });
    return () => {
      subscribed = false;
    };
  }, []);

  return {
    accessAllowed,
    error,
    loading,
  };
};
