import styled from 'styled-components/macro';

export const Loader = styled.span`
  margin: auto;
  display: block;
  color: ${({ theme }) => theme.white};
  position: relative;
  background: ${({ theme }) => theme.white};
  animation: escaleY 1s infinite ease-in-out;
  width: 0.2rem;
  height: 0.75rem;
  animation-delay: -0.16s;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0.5rem;
    background: ${({ theme }) => theme.white};
    width: 0.2rem;
    height: 1rem;
    animation: escaleY 1s infinite ease-in-out;
  }

  &:before {
    left: -0.5rem;
    animation-delay: -0.32s;
  }

  @keyframes escaleY {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 0.75rem;
    }
    40% {
      box-shadow: 0 -0.25rem;
      height: 1rem;
    }
  }
`;
