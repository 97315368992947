import styled from 'styled-components';
import { H3, Body } from '../Text';

const Container = styled.div`
  width: 35rem;
  max-width: 100%;
  padding: 3rem;
  box-sizing: border-box;
`;

const Title = styled(H3)`
  margin-bottom: 1.5rem;
  line-height: 1.2;
`;

const Content = styled(Body)`
  line-height: 1.5;
  margin: 0;

  a {
    color: ${({ theme }) => theme.white};

    &:visited {
      color: ${({ theme }) => theme.white};
    }
  }
`;

export default function AccessModal() {
  return (
    <Container>
      <Title>Increment is not available in your region</Title>
      <Content>
        Sorry! For compliance reasons, this service is not accessible in your area. Use of a VPN or
        other means to circumvent this restriction is a violation of our{' '}
        <a href="https://docs.increment.finance/legal/terms-of-service">Terms of Service</a>.
      </Content>
    </Container>
  );
}
