/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  ClearingHouse,
  ClearingHouseInterface,
  IClearingHouse,
} from "../ClearingHouse";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IVault",
        name: "_vault",
        type: "address",
      },
      {
        internalType: "contract IInsurance",
        name: "_insurance",
        type: "address",
      },
      {
        components: [
          {
            internalType: "int256",
            name: "minMargin",
            type: "int256",
          },
          {
            internalType: "int256",
            name: "minMarginAtCreation",
            type: "int256",
          },
          {
            internalType: "uint256",
            name: "minPositiveOpenNotional",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidationReward",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "insuranceRatio",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidationRewardInsuranceShare",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidationDiscount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonUACollSeizureDiscount",
            type: "uint256",
          },
          {
            internalType: "int256",
            name: "uaDebtSeizureThreshold",
            type: "int256",
          },
        ],
        internalType: "struct IClearingHouse.ClearingHouseParams",
        name: "_params",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "ClearingHouse_AmountProvidedTooLarge",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_ChangePositionZeroAmount",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_ClosePositionStillOpen",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_DepositForZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_ExcessiveLiquidationDiscount",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_ExcessiveLiquidationRewardInsuranceShare",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_ExcessivePositiveOpenNotional",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_ExtendPositionInsufficientMargin",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_ExtendPositionZeroAmount",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_InsufficientDiffBtwLiquidationDiscountAndNonUACollSeizureDiscount",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_InsufficientUaDebtSeizureThreshold",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_InvalidInsuranceRatio",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_InvalidLiquidationReward",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_InvalidMinMargin",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_InvalidMinMarginAtCreation",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_LiquidateInsufficientProposedAmount",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_LiquidateInvalidPosition",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_LiquidateValidMargin",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_LiquidationDebtSizeZero",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_MarketDoesNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_NegativeDustProceeds",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_PerpetualMarketAlreadyAssigned",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_ProvideLiquidityZeroAmount",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_ReducePositionZeroAmount",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_RemoveLiquidityInsufficientFunds",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_SufficientUserCollateral",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_UnderOpenNotionalAmountRequired",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_WithdrawInsufficientMargin",
    type: "error",
  },
  {
    inputs: [],
    name: "ClearingHouse_ZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "PRBMathSD59x18__AbsInputTooSmall",
    type: "error",
  },
  {
    inputs: [],
    name: "PRBMathSD59x18__MulInputTooSmall",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "rAbs",
        type: "uint256",
      },
    ],
    name: "PRBMathSD59x18__MulOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivFixedPointOverflow",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "enum LibPerpetual.Side",
        name: "direction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "addedOpenNotional",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "addedPositionSize",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "profit",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "tradingFeesPayed",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "insuranceFeesPayed",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "isPositionIncreased",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "isPositionClosed",
        type: "bool",
      },
    ],
    name: "ChangePosition",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "int256",
        name: "newMinMargin",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "newMinMarginAtCreation",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newMinPositiveOpenNotional",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newLiquidationReward",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newInsuranceRatio",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newLiquidationRewardInsuranceShare",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newLiquidationDiscount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "nonUACollSeizureDiscount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "uaDebtSeizureThreshold",
        type: "int256",
      },
    ],
    name: "ClearingHouseParametersChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "profit",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "tradingFeesPayed",
        type: "int256",
      },
    ],
    name: "DustSold",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "liquidatee",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "liquidator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "notional",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "profit",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "tradingFeesPayed",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "isTrader",
        type: "bool",
      },
    ],
    name: "LiquidationCall",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "liquidityProvider",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "quoteAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "baseAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "tradingFeesEarned",
        type: "int256",
      },
    ],
    name: "LiquidityProvided",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "liquidityProvider",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "reductionRatio",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "profit",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "tradingFeesPayed",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "isPositionClosed",
        type: "bool",
      },
    ],
    name: "LiquidityRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "contract IPerpetual",
        name: "perpetual",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "listedIdx",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "numPerpetuals",
        type: "uint256",
      },
    ],
    name: "MarketAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "contract IPerpetual",
        name: "perpetual",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "delistedIdx",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "numPerpetuals",
        type: "uint256",
      },
    ],
    name: "MarketRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "contract IRewardContract",
        name: "newRewardContract",
        type: "address",
      },
    ],
    name: "RewardContractChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32",
      },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "liquidatee",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "liquidator",
        type: "address",
      },
    ],
    name: "SeizeCollateral",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "EMERGENCY_ADMIN",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GOVERNANCE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IRewardContract",
        name: "rewardDistributor",
        type: "address",
      },
    ],
    name: "addRewardContract",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IPerpetual",
        name: "perp",
        type: "address",
      },
    ],
    name: "allowListPerpetual",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "liquidatee",
        type: "address",
      },
    ],
    name: "canSeizeCollateral",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
      {
        internalType: "enum LibPerpetual.Side",
        name: "direction",
        type: "uint8",
      },
    ],
    name: "changePosition",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
      {
        internalType: "contract IERC20Metadata",
        name: "token",
        type: "address",
      },
    ],
    name: "closePositionWithdrawCollateral",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "subtractedAmount",
        type: "uint256",
      },
      {
        internalType: "contract IERC20Metadata",
        name: "token",
        type: "address",
      },
    ],
    name: "decreaseAllowance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IPerpetual",
        name: "perp",
        type: "address",
      },
    ],
    name: "delistPerpetual",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "contract IERC20Metadata",
        name: "token",
        type: "address",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "contract IERC20Metadata",
        name: "token",
        type: "address",
      },
    ],
    name: "depositFor",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "collateralAmount",
        type: "uint256",
      },
      {
        internalType: "contract IERC20Metadata",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "positionAmount",
        type: "uint256",
      },
      {
        internalType: "enum LibPerpetual.Side",
        name: "direction",
        type: "uint8",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
    ],
    name: "extendPositionWithCollateral",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getDebtAcrossMarkets",
    outputs: [
      {
        internalType: "int256",
        name: "userDebt",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "int256",
        name: "ratio",
        type: "int256",
      },
    ],
    name: "getFreeCollateralByRatio",
    outputs: [
      {
        internalType: "int256",
        name: "freeCollateral",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getNumMarkets",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getPnLAcrossMarkets",
    outputs: [
      {
        internalType: "int256",
        name: "unrealizedPositionPnl",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
    ],
    name: "getRoleAdmin",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "int256",
        name: "ratio",
        type: "int256",
      },
    ],
    name: "getTotalMarginRequirement",
    outputs: [
      {
        internalType: "int256",
        name: "requiredMargin",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "hasRole",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "id",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "addedAmount",
        type: "uint256",
      },
      {
        internalType: "contract IERC20Metadata",
        name: "token",
        type: "address",
      },
    ],
    name: "increaseAllowance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "insurance",
    outputs: [
      {
        internalType: "contract IInsurance",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "insuranceRatio",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isEmergencyAdmin",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isGovernor",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "liquidatee",
        type: "address",
      },
      {
        internalType: "uint256[2]",
        name: "minVTokenAmounts",
        type: "uint256[2]",
      },
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
    ],
    name: "liquidateLp",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "liquidatee",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
    ],
    name: "liquidateTrader",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "liquidationDiscount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "liquidationReward",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "liquidationRewardInsuranceShare",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "marketIds",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minMargin",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minMarginAtCreation",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minPositiveOpenNotional",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "nonUACollSeizureDiscount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "closeProposedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "closeMinAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "openProposedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "openMinAmount",
        type: "uint256",
      },
      {
        internalType: "enum LibPerpetual.Side",
        name: "direction",
        type: "uint8",
      },
    ],
    name: "openReversePosition",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "perpetuals",
    outputs: [
      {
        internalType: "contract IPerpetual",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256[2]",
        name: "amounts",
        type: "uint256[2]",
      },
      {
        internalType: "uint256",
        name: "minLpAmount",
        type: "uint256",
      },
    ],
    name: "provideLiquidity",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "liquidityAmountToRemove",
        type: "uint256",
      },
      {
        internalType: "uint256[2]",
        name: "minVTokenAmounts",
        type: "uint256[2]",
      },
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
    ],
    name: "removeLiquidity",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "rewardContract",
    outputs: [
      {
        internalType: "contract IRewardContract",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "liquidatee",
        type: "address",
      },
    ],
    name: "seizeCollateral",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "int256",
            name: "minMargin",
            type: "int256",
          },
          {
            internalType: "int256",
            name: "minMarginAtCreation",
            type: "int256",
          },
          {
            internalType: "uint256",
            name: "minPositiveOpenNotional",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidationReward",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "insuranceRatio",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidationRewardInsuranceShare",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidationDiscount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonUACollSeizureDiscount",
            type: "uint256",
          },
          {
            internalType: "int256",
            name: "uaDebtSeizureThreshold",
            type: "int256",
          },
        ],
        internalType: "struct IClearingHouse.ClearingHouseParams",
        name: "params",
        type: "tuple",
      },
    ],
    name: "setParameters",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
      {
        internalType: "enum LibPerpetual.Side",
        name: "direction",
        type: "uint8",
      },
    ],
    name: "settleDust",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "uaDebtSeizureThreshold",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "updateGlobalState",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "vault",
    outputs: [
      {
        internalType: "contract IVault",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "contract IERC20Metadata",
        name: "token",
        type: "address",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20Metadata",
        name: "token",
        type: "address",
      },
    ],
    name: "withdrawAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "contract IERC20Metadata",
        name: "token",
        type: "address",
      },
    ],
    name: "withdrawFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class ClearingHouse__factory {
  static readonly abi = _abi;
  static createInterface(): ClearingHouseInterface {
    return new utils.Interface(_abi) as ClearingHouseInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ClearingHouse {
    return new Contract(address, _abi, signerOrProvider) as ClearingHouse;
  }
}
