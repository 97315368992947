import { useCallback, useEffect, useState } from 'react';
import { OperationContext } from 'urql';
import isEqual from 'lodash.isequal';

interface UseDeepRefreshProps<T> {
  queryData?: T;
  fetching: boolean;
  invalidateQuery: (opts?: Partial<OperationContext>) => void;
  id: string;
  compareFn?: (
    queryData: Record<string, unknown>,
    latestValidData: Record<string, unknown>,
  ) => boolean;
}

export const useDeepRefresh = <T>({
  queryData,
  fetching,
  invalidateQuery,
  compareFn,
}: UseDeepRefreshProps<T>): [T | undefined, () => void, boolean] => {
  const [refreshDepth, setRefreshDepth] = useState<number>(0);
  const [latestValidData, setLatestValidData] = useState<T>();

  const deepRefresh = useCallback(() => {
    setRefreshDepth((value) => value + 1);
  }, [setRefreshDepth]);

  useEffect(() => {
    const subgraphPoll = setInterval(() => {
      if (refreshDepth > 0 && !fetching) {
        invalidateQuery({ requestPolicy: 'network-only' });
      }
    }, 1000);
    return () => {
      clearInterval(subgraphPoll);
    };
  }, [refreshDepth, fetching, invalidateQuery]);

  useEffect(() => {
    if (
      queryData &&
      (latestValidData === undefined || !(compareFn ?? isEqual)(queryData, latestValidData))
    ) {
      if (refreshDepth <= 1) {
        setRefreshDepth(0);
        setLatestValidData(queryData);
      } else {
        setRefreshDepth((value) => value - 1);
      }
    }
  }, [queryData, latestValidData, setLatestValidData, compareFn, refreshDepth]);

  return [latestValidData, deepRefresh, refreshDepth > 0];
};
