import styled from 'styled-components';
import { ToastContainer as ToastifyContainer } from 'react-toastify';

const StyledToastContainer = styled(ToastifyContainer)`
  .Toastify__toast {
    border-radius: 0.75rem;
    padding: 0.875rem 1.375rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.00625rem;
    display: flex;
    align-items: center;
    filter: drop-shadow(0px 30px 40px rgba(0, 0, 0, 0.2));
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.red};
    color: ${({ theme }) => theme.white};

    .Toastify__toast-icon {
      display: none;
    }

    button {
      :before {
        content: 'Damn';
      }
    }
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.lgrad0};
    color: ${({ theme }) => theme.white};

    .Toastify__toast-icon {
      display: none;
    }

    button {
      :before {
        content: 'Ok';
      }
    }
  }

  .Toastify__toast--default {
    background: ${({ theme }) => theme.bg4};
    color: ${({ theme }) => theme.white};

    .Toastify__toast-icon {
      margin-right: 1rem;
      margin-left: -0.25rem;
    }

    button {
      :before {
        content: 'Ok';
      }
    }
  }

  .Toastify__toast--info {
    background: ${({ theme }) => theme.bg5};
    color: ${({ theme }) => theme.white};

    .Toastify__toast-icon {
      display: none;
    }

    button {
      :before {
        content: 'Ok';
      }
    }
  }

  .Toastify__toast-body {
    margin: 0;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
    height: auto;
  }
`;

export const CloseButton = styled.button`
  border: 2px solid white;
  background: none;
  border-radius: 1rem;
  height: 2.5rem;
  cursor: pointer;
  transition: all ${({ theme }) => theme.fast} ease-out;

  :hover {
    opacity: 0.8;
  }

  :before {
    content: 'Ok';
    color: white;
    margin: 1.375rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.00625rem;
  }
`;

export function ToastContainer() {
  return (
    <StyledToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      rtl={false}
      closeButton={<CloseButton />}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
