/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { Perpetual, PerpetualInterface, IPerpetual } from "../Perpetual";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IVBase",
        name: "_vBase",
        type: "address",
      },
      {
        internalType: "contract IVQuote",
        name: "_vQuote",
        type: "address",
      },
      {
        internalType: "contract ICryptoSwap",
        name: "_market",
        type: "address",
      },
      {
        internalType: "contract IClearingHouse",
        name: "_clearingHouse",
        type: "address",
      },
      {
        internalType: "contract ICurveCryptoViews",
        name: "_views",
        type: "address",
      },
      {
        internalType: "bool",
        name: "_isTradingExpansionAllowed",
        type: "bool",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "riskWeight",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxLiquidityProvided",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "twapFrequency",
            type: "uint256",
          },
          {
            internalType: "int256",
            name: "sensitivity",
            type: "int256",
          },
          {
            internalType: "uint256",
            name: "maxBlockTradeAmount",
            type: "uint256",
          },
          {
            internalType: "int256",
            name: "insuranceFee",
            type: "int256",
          },
          {
            internalType: "int256",
            name: "lpDebtCoef",
            type: "int256",
          },
          {
            internalType: "uint256",
            name: "lockPeriod",
            type: "uint256",
          },
        ],
        internalType: "struct IPerpetual.PerpetualParams",
        name: "_params",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "PRBMathSD59x18__AbsInputTooSmall",
    type: "error",
  },
  {
    inputs: [],
    name: "PRBMathSD59x18__DivInputTooSmall",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "rAbs",
        type: "uint256",
      },
    ],
    name: "PRBMathSD59x18__DivOverflow",
    type: "error",
  },
  {
    inputs: [],
    name: "PRBMathSD59x18__MulInputTooSmall",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "rAbs",
        type: "uint256",
      },
    ],
    name: "PRBMathSD59x18__MulOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivFixedPointOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "denominator",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivOverflow",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_AttemptReducePosition",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_AttemptReversePosition",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_ExcessiveBlockTradeAmount",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "int256",
        name: "fee",
        type: "int256",
      },
    ],
    name: "Perpetual_InsuranceFeeInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_InvalidAdminFee",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_LPOpenPosition",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_LPWithdrawExceedsBalance",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "lockPeriod",
        type: "uint256",
      },
    ],
    name: "Perpetual_LockPeriodInvalid",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "withdrawTime",
        type: "uint256",
      },
    ],
    name: "Perpetual_LockPeriodNotReached",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_LpAmountDeviation",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "int256",
        name: "lpDebtCoef",
        type: "int256",
      },
    ],
    name: "Perpetual_LpDebtCoefInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_MarketBalanceTooLow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "maxBlockTradeAmount",
        type: "uint256",
      },
    ],
    name: "Perpetual_MaxBlockAmountInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_MaxLiquidityProvided",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_MaxPositionSize",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_NoOpenPosition",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "riskWeight",
        type: "uint256",
      },
    ],
    name: "Perpetual_RiskWeightInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_SenderNotClearingHouse",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_SenderNotClearingHouseOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "int256",
        name: "sensitivity",
        type: "int256",
      },
    ],
    name: "Perpetual_SensitivityInvalid",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_TooMuchExposure",
    type: "error",
  },
  {
    inputs: [],
    name: "Perpetual_TradingExpansionPaused",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "twapFrequency",
        type: "uint256",
      },
    ],
    name: "Perpetual_TwapFrequencyInvalid",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenIndex",
        type: "uint256",
      },
    ],
    name: "Perpetual_VirtualTokenApprovalConstructor",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "paramIndex",
        type: "uint256",
      },
    ],
    name: "Perpetual_ZeroAddressConstructor",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "int256",
        name: "vBaseAmount",
        type: "int256",
      },
    ],
    name: "DustGenerated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "amount",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "globalCumulativeFundingRate",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "userCumulativeFundingRate",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "isTrader",
        type: "bool",
      },
    ],
    name: "FundingPaid",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "int256",
        name: "cumulativeFundingRate",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "cumulativeFundingPerLpToken",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "fundingRate",
        type: "int256",
      },
    ],
    name: "FundingRateUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "errorMessage",
        type: "string",
      },
    ],
    name: "Log",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newRiskWeight",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newMaxLiquidityProvided",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newTwapFrequency",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "newSensitivity",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "newMaxBlockTradeAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "newInsuranceFee",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "newLpDebtCoef",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "lockPeriod",
        type: "uint256",
      },
    ],
    name: "PerpetualParametersChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32",
      },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "admin",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "toPause",
        type: "bool",
      },
    ],
    name: "TradingExpansionPauseToggled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "int256",
        name: "newOracleTwap",
        type: "int256",
      },
      {
        indexed: false,
        internalType: "int256",
        name: "newMarketTwap",
        type: "int256",
      },
    ],
    name: "TwapUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "EMERGENCY_ADMIN",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GOVERNANCE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
      {
        internalType: "enum LibPerpetual.Side",
        name: "direction",
        type: "uint8",
      },
      {
        internalType: "bool",
        name: "isLiquidation",
        type: "bool",
      },
    ],
    name: "changePosition",
    outputs: [
      {
        internalType: "int256",
        name: "quoteProceeds",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "baseProceeds",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "profit",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "tradingFeesPayed",
        type: "int256",
      },
      {
        internalType: "bool",
        name: "isPositionIncreased",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "isPositionClosed",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "clearingHouse",
    outputs: [
      {
        internalType: "contract IClearingHouse",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "curveCryptoViews",
    outputs: [
      {
        internalType: "contract ICurveCryptoViews",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getGlobalPosition",
    outputs: [
      {
        components: [
          {
            internalType: "uint64",
            name: "timeOfLastTrade",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "timeOfLastTwapUpdate",
            type: "uint64",
          },
          {
            internalType: "int128",
            name: "cumFundingRate",
            type: "int128",
          },
          {
            internalType: "uint128",
            name: "totalQuoteProvided",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalBaseProvided",
            type: "uint128",
          },
          {
            internalType: "int128",
            name: "cumFundingPerLpToken",
            type: "int128",
          },
          {
            internalType: "uint128",
            name: "currentBlockTradeAmount",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalTradingFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalBaseFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalQuoteFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "traderLongs",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "traderShorts",
            type: "uint128",
          },
        ],
        internalType: "struct LibPerpetual.GlobalPosition",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpLiquidity",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpOpenNotional",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpPosition",
    outputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "openNotional",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "positionSize",
            type: "int128",
          },
          {
            internalType: "uint128",
            name: "liquidityBalance",
            type: "uint128",
          },
          {
            internalType: "uint64",
            name: "depositTime",
            type: "uint64",
          },
          {
            internalType: "uint128",
            name: "totalTradingFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalBaseFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "totalQuoteFeesGrowth",
            type: "uint128",
          },
          {
            internalType: "int128",
            name: "cumFundingPerLpToken",
            type: "int128",
          },
        ],
        internalType: "struct LibPerpetual.LiquidityProviderPosition",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpPositionAfterWithdrawal",
    outputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "openNotional",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "positionSize",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "cumFundingRate",
            type: "int128",
          },
        ],
        internalType: "struct LibPerpetual.TraderPosition",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpTradingFees",
    outputs: [
      {
        internalType: "uint256",
        name: "tradingFeesEarned",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getLpUnrealizedPnL",
    outputs: [
      {
        internalType: "int256",
        name: "pnl",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getPendingPnL",
    outputs: [
      {
        internalType: "int256",
        name: "pnL",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
    ],
    name: "getRoleAdmin",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getTotalLiquidityProvided",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getTraderPosition",
    outputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "openNotional",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "positionSize",
            type: "int128",
          },
          {
            internalType: "int128",
            name: "cumFundingRate",
            type: "int128",
          },
        ],
        internalType: "struct LibPerpetual.TraderPosition",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getTraderUnrealizedPnL",
    outputs: [
      {
        internalType: "int256",
        name: "pnl",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "getUserDebt",
    outputs: [
      {
        internalType: "int256",
        name: "debt",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "hasRole",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "indexPrice",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "insuranceFee",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isEmergencyAdmin",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isGovernor",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isLpPositionOpen",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isTraderPositionOpen",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isTradingExpansionAllowed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lockPeriod",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lpDebtCoef",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "market",
    outputs: [
      {
        internalType: "contract ICryptoSwap",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "marketCumulativeAmount",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "marketCumulativeAmountAtBeginningOfPeriod",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "marketPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "marketTwap",
    outputs: [
      {
        internalType: "int128",
        name: "",
        type: "int128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxBlockTradeAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxLiquidityProvided",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxPosition",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "oracleCumulativeAmount",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "oracleCumulativeAmountAtBeginningOfPeriod",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "oracleTwap",
    outputs: [
      {
        internalType: "int128",
        name: "",
        type: "int128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256[2]",
        name: "amounts",
        type: "uint256[2]",
      },
      {
        internalType: "uint256",
        name: "minLpAmount",
        type: "uint256",
      },
    ],
    name: "provideLiquidity",
    outputs: [
      {
        internalType: "int256",
        name: "tradingFees",
        type: "int256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "liquidityAmountToRemove",
        type: "uint256",
      },
      {
        internalType: "uint256[2]",
        name: "minVTokenAmounts",
        type: "uint256[2]",
      },
      {
        internalType: "uint256",
        name: "proposedAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "isLiquidation",
        type: "bool",
      },
    ],
    name: "removeLiquidity",
    outputs: [
      {
        internalType: "int256",
        name: "profit",
        type: "int256",
      },
      {
        internalType: "int256",
        name: "tradingFeesPayed",
        type: "int256",
      },
      {
        internalType: "uint256",
        name: "reductionRatio",
        type: "uint256",
      },
      {
        internalType: "int256",
        name: "quoteProceeds",
        type: "int256",
      },
      {
        internalType: "bool",
        name: "isPositionClosed",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "liquidityAmountToRemove",
        type: "uint256",
      },
      {
        internalType: "uint256[2]",
        name: "minVTokenAmounts",
        type: "uint256[2]",
      },
      {
        internalType: "bytes",
        name: "func",
        type: "bytes",
      },
    ],
    name: "removeLiquiditySwap",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "riskWeight",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "sensitivity",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "riskWeight",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxLiquidityProvided",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "twapFrequency",
            type: "uint256",
          },
          {
            internalType: "int256",
            name: "sensitivity",
            type: "int256",
          },
          {
            internalType: "uint256",
            name: "maxBlockTradeAmount",
            type: "uint256",
          },
          {
            internalType: "int256",
            name: "insuranceFee",
            type: "int256",
          },
          {
            internalType: "int256",
            name: "lpDebtCoef",
            type: "int256",
          },
          {
            internalType: "uint256",
            name: "lockPeriod",
            type: "uint256",
          },
        ],
        internalType: "struct IPerpetual.PerpetualParams",
        name: "params",
        type: "tuple",
      },
    ],
    name: "setParameters",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "settleLpFunding",
    outputs: [
      {
        internalType: "int256",
        name: "fundingPayments",
        type: "int256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "settleTraderFunding",
    outputs: [
      {
        internalType: "int256",
        name: "fundingPayments",
        type: "int256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "toPause",
        type: "bool",
      },
    ],
    name: "toggleTradingExpansionPause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "twapFrequency",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "updateGlobalState",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "vBase",
    outputs: [
      {
        internalType: "contract IVBase",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vQuote",
    outputs: [
      {
        internalType: "contract IVQuote",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class Perpetual__factory {
  static readonly abi = _abi;
  static createInterface(): PerpetualInterface {
    return new utils.Interface(_abi) as PerpetualInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): Perpetual {
    return new Contract(address, _abi, signerOrProvider) as Perpetual;
  }
}
