import { AbiCoder } from 'ethers/lib/utils';
import {
  KNOWN_CONTRACT_ERRORS,
  KNOWN_ETHERS_ERRORS,
  KNOWN_PLAIN_TEXT_ERRORS,
} from '../data/errors';
import * as factories from '../typechain';

export interface TxError {
  code: number;
  data?: {
    code: number;
    message: string;
  };
  message: string;
  stack: string;
}

export interface EthSigDbResult {
  ok: boolean;
  result: {
    function: { [key: string]: { name: string; filtered: boolean }[] };
  };
}

const ETH_SIG_DB_URL = 'https://sig.eth.samczsun.com';

export const fetchError = async (functionSelector: string): Promise<string | undefined> =>
  fetch(`${ETH_SIG_DB_URL}/api/v1/signatures?errors=true&function=${functionSelector}`)
    .then((res) => res.json())
    .then(({ result, ok }: EthSigDbResult) => {
      if (!ok) throw new Error('Unable to fetch from Eth Sig DB.');

      return result.function[functionSelector]?.[0]?.name;
    })
    .catch((err) => {
      console.error(err);
      return undefined;
    });

export const parseError = (functionSelector: string): string | undefined => {
  const interfaces = Object.values(factories).map((factory) => factory.createInterface());

  for (let i = 0; i < interfaces.length; i += 1) {
    try {
      const decodedError = interfaces[i].parseError(functionSelector);
      return decodedError.name;
    } catch (err) {
      console.log(err);
    }
  }

  return undefined;
};

export const getContractErrorDescription = (callSignature: string): string => {
  // Search known contract errors
  if (Object.keys(KNOWN_CONTRACT_ERRORS).indexOf(callSignature) !== -1) {
    return KNOWN_CONTRACT_ERRORS[callSignature];
  }

  return `An unknown error occurred: ${callSignature}`;
};

export const getPlainTextErrorDescription = (error: string): string => {
  // Search known contract plain-text errors
  const keys = Object.keys(KNOWN_PLAIN_TEXT_ERRORS);
  for (let i = 0; i < keys.length; i += 1) {
    if (error.indexOf(keys[i]) !== -1) {
      return KNOWN_PLAIN_TEXT_ERRORS[keys[i]];
    }
  }

  return `An unknown error occurred: ${error}`;
};

export const getEthersErrorDescription = (error: string): string => {
  // Search known ethers errors
  const keys = Object.keys(KNOWN_ETHERS_ERRORS);
  for (let i = 0; i < keys.length; i += 1) {
    if (error.indexOf(keys[i]) !== -1) {
      return KNOWN_ETHERS_ERRORS[keys[i]];
    }
  }

  return `An unknown error occurred: ${error}`;
};

// eslint-disable-next-line
export const decodeTxError = async (error: TxError | any): Promise<string> => {
  if (error.data?.message || error.error?.data) {
    let functionSelector = '';
    let data = '';

    if (typeof error.error.data === 'string') {
      functionSelector = error.error.data.substring(0, 10);
      data = error.error.data.substring(10);
    } else if (error.error.data.data) {
      functionSelector = error.error.data.data.substring(0, 10);
      data = error.error.data.data.substring(10);
    } else if (error.error.data.originalError?.data) {
      functionSelector = error.error.data.originalError.data.substring(0, 10);
      data = error.error.data.originalError.data.substring(10);
    } else {
      const functionSelectorIndex = error.data.message.indexOf('function_selector = ');

      // No function selector found
      if (functionSelectorIndex === -1) {
        // Error is plainText in the contract by default
        const plainTextErrorIndex = error.data.message.indexOf('Cannot estimate transaction: ');
        return getPlainTextErrorDescription(error.data.message.slice(plainTextErrorIndex + 28));
      }

      functionSelector = error.data.message.substring(
        functionSelectorIndex + 20,
        functionSelectorIndex + 30,
      );
    }

    // encoded plain text error
    if (functionSelector === '0x08c379a0') {
      const abiCoder = new AbiCoder();
      const [plainTextError] = abiCoder.decode(['string'], `0x${data}`);
      return getPlainTextErrorDescription(plainTextError);
    }

    // parse error from known abis
    const parsedError = parseError(functionSelector);
    if (parsedError) return getContractErrorDescription(parsedError);

    // fetch error from eth sig db
    const fetchedError = await fetchError(functionSelector);
    if (fetchedError) return getContractErrorDescription(fetchedError);

    return `An unknown error occurred: function_selector = ${functionSelector}`;
  }

  // Most likely an issue with ethers at this point
  return getEthersErrorDescription(error.toString());
};
