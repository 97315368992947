import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useSetChain } from '@web3-onboard/react';
import { useTranslation } from 'react-i18next';

import { ButtonM } from '../Button';
import { Avatar } from '../Avatar';
import useLayout from '../../contexts/LayoutContext';
import useWeb3 from '../../contexts/Web3Context';

import arrowSmall from '../../assets/vectors/arrow-s.svg';
import warning from '../../assets/vectors/warning.svg';
import { Label3, Nav2 } from '../Text';
import { targetChainId } from '../../data/chain';

const Container = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.navZ};
`;

const ButtonWrapper = styled(ButtonM)<{
  connected: boolean;
  wrongNetwork: boolean;
  expanded: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ connected, theme }) => (connected ? theme.bg4 : 'none')};
  border-color: ${({ connected, wrongNetwork, theme }) => {
    if (connected && !wrongNetwork) {
      return theme.gray1;
    }
    if (connected) {
      return theme.red;
    }
    return theme.blue;
  }};

  &:hover {
    filter: drop-shadow(
      0rem 0rem 0.25rem
        ${({ connected, expanded, theme }) => {
          if (expanded) {
            return 'rgba(0,0,0,0)';
          }
          if (connected) {
            return theme.gray1;
          }
          return theme.blue;
        }}
    );
  }

  &:enabled:active {
    background: ${({ connected, theme }) => (connected ? theme.gray1 : theme.blue)};
    filter: none;
  }
`;

const ButtonText = styled(Nav2)`
  margin: 0 0.625rem;
  white-space: nowrap;
`;

const Warning = styled.img`
  width: 2rem;
  height: 2rem;
  margin: 0.4rem;
`;

const Arrow = styled.img<{ expanded: boolean }>`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  transition: all ${({ theme }) => theme.fast} ease-out;
  transform: rotate(${({ expanded }) => (expanded ? '180deg' : '0deg')});

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    margin-right: 0.1575rem;
    margin-left: 0.625rem;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.navZ};
  top: 100%;
  margin-top: 0.45rem;
  right: 0;
  background: ${({ theme }) => theme.gray1};
  width: 100%;
  min-width: 10rem;
  border-radius: 1rem;
  overflow: hidden;
`;

const DropdownOption = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.bg4};
  border: none;
  color: ${({ theme }) => theme.white};
  width: 100%;
  padding: 0.5rem 0.875rem;

  &:not(:last-child) {
    margin-bottom: 0.066rem;
  }

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.bg3};
  }
`;

export function WalletButton() {
  const { isMobile } = useLayout();
  const { connected, connect, disconnect, address, networkError, chainConfig } = useWeb3();
  const [, setChain] = useSetChain();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleBlur = () => {
    let subscribed = true;
    setTimeout(() => {
      if (subscribed) {
        setFocused(false);
        setExpanded(false);
      }
    }, 100);
    return () => {
      subscribed = false;
    };
  };

  const shortAddress = useMemo(
    () => (address ? `${address.slice(0, 6)}...${address.slice(38, 42)}` : ''),
    [address],
  );

  const setNetwork = useCallback(async () => {
    setChain({
      chainId: targetChainId,
    });
    setExpanded(false);
  }, [setChain]);

  const handleFocus = useCallback(() => {
    setFocused(true);
    if (focused) {
      setExpanded(true);
    }
  }, [focused]);

  const handleClick = useCallback(() => {
    if (!connected) {
      if (connect) {
        connect();
      }
    } else if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [expanded, connect, connected]);

  return (
    <Container onFocus={handleFocus} onBlur={handleBlur}>
      <ButtonWrapper
        connected={connected}
        onClick={handleClick}
        wrongNetwork={!!networkError}
        expanded={expanded}
      >
        {connected && <Avatar address={address} />}
        {isMobile && !connected && <ButtonText>{t('Connect')}</ButtonText>}
        {!isMobile && <ButtonText>{connected ? shortAddress : t('Connect Wallet')}</ButtonText>}
        {connected && <Arrow src={arrowSmall} expanded={expanded} />}
      </ButtonWrapper>
      {expanded && (
        <DropdownContainer onFocusCapture={handleFocus}>
          {networkError && (
            <DropdownOption onClick={setNetwork}>
              <Warning src={warning} />
              <ButtonText>{t('Wrong Network')}</ButtonText>
              <Label3>
                {t('Switch to')} {chainConfig?.chainName}
              </Label3>
            </DropdownOption>
          )}
          <DropdownOption onClick={disconnect}>
            <ButtonText>{t('Disconnect')}</ButtonText>
          </DropdownOption>
        </DropdownContainer>
      )}
    </Container>
  );
}
