import { createContext, useContext, useMemo } from 'react';
import { TokenInfo, TokenList } from '@uniswap/token-lists';
import { parseEther } from 'ethers/lib/utils';

import { useTokensQuery } from '../graphql/generated';
import localTokenList from '../data/tokenlist.json';
import useWeb3 from './Web3Context';

export interface Token extends TokenInfo {
  id: string;
  weight: string;
  depositedAmount: string;
  uniqueDepositors: string;
  maxAmount: string;
  address: string;
  aggregator: string;
  isReserveToken: boolean;
  prices: {
    answer: string;
    decimals: string;
  }[];
  lastWeeksPrices: {
    answer: string;
    decimals: string;
  }[];
}

interface TokensContextType {
  tokenList?: Token[];
}

const TokensContext = createContext<TokensContextType>({});

export function TokensProvider({ children }: { children: React.ReactNode }) {
  const { chainConfig } = useWeb3();
  const [tokensQuery] = useTokensQuery({
    variables: {
      lastWeek: Math.round(Date.now().valueOf() / 1000) - 24 * 60 * 60 * 7, // 1 Week ago
    },
  });

  const tokenList = useMemo<Token[]>(() => {
    const localList = localTokenList as TokenList;
    if (localList?.tokens && tokensQuery?.data && chainConfig?.chainId) {
      const tokens: Token[] = [];
      [...(tokensQuery?.data?.tokens ?? []), ...(tokensQuery?.data?.reserveTokens ?? [])].forEach(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (token: any) => {
          const tokenInfo = localList.tokens.find(
            (item: TokenInfo) =>
              item.address.toLowerCase() === token.address.toLowerCase() &&
              item.chainId === +chainConfig.chainId,
          );
          if (tokenInfo) {
            tokens.push({
              ...tokenInfo,
              id: token.id,
              aggregator: token.aggregator ?? '',
              prices: token.prices ?? [],
              lastWeeksPrices: token.lastWeeksPrices ?? [],
              weight: token.weight ?? parseEther('1'),
              depositedAmount: token.depositedAmount ?? parseEther('0'),
              uniqueDepositors: token.uniqueDepositors ?? '0',
              maxAmount: token.maxAmount ?? token.mintCap,
              address: token.address,
              decimals: +token.decimals,
              isReserveToken: !token.prices,
            });
          }
        },
      );
      return tokens;
    }
    return [];
  }, [tokensQuery, chainConfig]);

  const value = useMemo<TokensContextType>(
    () => ({
      tokenList,
    }),
    [tokenList],
  );

  return <TokensContext.Provider value={value}>{children}</TokensContext.Provider>;
}

const useTokens = () => useContext(TokensContext) as TokensContextType;

export default useTokens;
