export const KNOWN_CONTRACT_ERRORS: Record<string, string> = {
  ClearingHouse_ZeroAddress: 'ClearingHouse address cannot be the zero address',
  ClearingHouse_WithdrawInsufficientMargin: 'Cannot withdraw this amount, insufficient margin',
  ClearingHouse_ClosePositionStillOpen: 'Failed to close position',
  ClearingHouse_LiquidateInvalidPosition: 'Attempted to liquidate invalid position',
  ClearingHouse_LiquidateValidMargin: 'Cannot liquidate position, margin still valid',
  ClearingHouse_LiquidateInsufficientProposedAmount:
    'Cannot liquidate position, insufficient proposed amount',
  ClearingHouse_SeizeCollateralStillOpen: 'Cannot seize collateral, position still open',
  ClearingHouse_ProvideLiquidityZeroAmount: 'Cannot provide zero liquidity',
  ClearingHouse_AmountProvidedTooLarge: 'Amount provided is too large',
  ClearingHouse_RemoveLiquidityInsufficientFunds: 'Cannot remove more liquidity than provided',
  ClearingHouse_ExtendPositionZeroAmount: 'Cannot extend position with zero amount',
  ClearingHouse_ExtendPositionInsufficientMargin: 'Cannot open position, insufficient margin',
  ClearingHouse_ReducePositionZeroAmount: 'Cannot reduce position with zero amount',
  ClearingHouse_ChangePositionZeroAmount: 'Cannot change position with zero amount',
  ClearingHouse_UnderOpenNotionalAmountRequired:
    'Cannot change position, resulting open notional is invalid',
  ClearingHouse_LiquidationDebtSizeZero: 'Cannot seize collateral, liquidatee has zero debt',
  ClearingHouse_SufficientUserCollateral:
    'Cannot seize collateral, liquidatee has sufficient collateral',
  ClearingHouse_DepositForZeroAddress: 'Cannot deposit for the zero address',
  Perpetual_TradingExpansionPaused: 'Trading expansion is paused',
  Perpetual_ExcessiveBlockTradeAmount: 'Trade amount exceeds the maximum for the current block',
  Perpetual_NoOpenPosition: 'Cannot reduce position, position does not exist',
  Perpetual_LPWithdrawExceedsBalance: 'Cannot remove liquidity, amount exceeds balance',
  Perpetual_LockPeriodNotReached: 'Cannot remove liquidity until lock period has ended',
  Perpetual_MarketBalanceTooLow: 'Cannot remove liquidity, market balance too low',
  Perpetual_LPOpenPosition: 'Cannot remove liquidity, closing position expected to fail',
  Perpetual_AttemptReversePosition: 'Cannot remove liquidity, attempted to reverse position',
  Perpetual_MaxLiquidityProvided: 'Cannot provide liquidity, max liquidity provided',
  Perpetual_MaxPositionSize: 'Cannot change position, amount exceeds max position size',
  Perpetual_LpAmountDeviation:
    'Cannot provide liquidity, token amount deviation exceeds the maximum allowed',
  Oracle_InvalidRoundTimestamp: 'Oracle provided an invalid timestamp',
  Oracle_InvalidRoundPrice: 'Oracle provided an invalid price',
  Oracle_DataNotFresh: 'Oracle provided an old price',
  Oracle_SequencerDown: 'Oracle sequencer is down',
  Oracle_GracePeriodNotOver: "The oracle's grace period is not over",
  Vault_WithdrawExcessiveAmount: 'Withdraw amount exceeds balance',
  Vault_UnsupportedCollateral: 'Unsupported collateral',
  Vault_UADebt: 'Cannot withdraw, UA debt is below zero',
  Vault_InsufficientBalance: 'Cannot withdraw more than deposited',
  Vault_MaxCollateralAmountExceeded: 'Cannot deposit, max collateral amount has been reached',
  VBase_InvalidRoundTimestamp: 'Chainlink oracle provided invalid timestamp',
  VBase_DataNotFresh: 'Chainlink oracle provided old value',
  VBase_InvalidRoundPrice: 'Chainlink oracle provided invalid price',
  VBase_SequencerDown: 'Network sequencer is down',
  VBase_GracePeriodNotOver: "The oracle's grace period is not over",
  UA_InvalidReserveTokenIndex: 'Cannot mint UA, invalid reserve token index',
  UA_ExcessiveTokenMintCapReached: 'Cannot mint UA, maximum mint capacity has been reached',
};

export const KNOWN_PLAIN_TEXT_ERRORS: Record<string, string> = {
  Slippage: 'Transaction expected to fail due to slippage',
};

export const KNOWN_ETHERS_ERRORS: Record<string, string> = {
  ACTION_REJECTED: 'Signature request failed',
};
