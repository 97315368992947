import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import cn from './cn.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import jp from './jp.json';
import kr from './kr.json';
import ru from './ru.json';
import tr from './tr.json';

export const supportedLanguages: { [key: string]: string } = {
  cn: '中文',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  jp: '日本語',
  kr: '한국어',
  ru: 'Русский',
  tr: 'Türkçe',
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      cn: {
        translation: cn,
      },
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      fr: {
        translation: fr,
      },
      jp: {
        translation: jp,
      },
      kr: {
        translation: kr,
      },
      ru: {
        translation: ru,
      },
      tr: {
        translation: tr,
      },
    },
    lang: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
