import ethFlag from '../assets/images/flags/eth.png';

export interface Market {
  idx: string;
  displayDecimals: number;
  baseDecimals: number;
  label: string;
  base: string;
  quote: string;
  id: string;
  img: string;
  assetClass: string;
}

export const marketList: Market[] = [
  {
    idx: '0',
    displayDecimals: 2,
    baseDecimals: 4,
    label: 'ETH / USD',
    base: 'ETH',
    quote: 'USD',
    id: 'ETHUSD',
    img: ethFlag,
    assetClass: 'Crypto',
  },
];
