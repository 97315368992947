export const setAcceptedTerms = () => {
  localStorage.setItem('acceptedTerms', JSON.stringify(true));
};

export const getAcceptedTerms = () => localStorage.getItem('acceptedTerms') === 'true';

export const setFeeToken = (feeToken: string) => {
  localStorage.setItem('feeToken', feeToken);
};

export const getFeeToken = () => localStorage.getItem('feeToken');
