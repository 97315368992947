import styled from 'styled-components/macro';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import { ButtonXL } from '../Button';
import { H3, Body } from '../Text';
import useWeb3 from '../../contexts/Web3Context';
import { Loader } from '../Loader';

const ContentContainer = styled.div`
  padding: 1.75rem;
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.tablet};
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-bottom: 0.875rem;
  max-height: 70vh;
`;

const SectionHeader = styled(H3)`
  padding-bottom: 1.125rem;
`;

const Paragraph = styled(Body)`
  padding-bottom: 1.125rem;
  line-height: 150%;

  ol {
    li {
      padding-bottom: 1rem;
    }
  }

  a {
    color: ${({ theme }) => theme.white};
    font-weight: 700;
  }
`;

enum TermsModalState {
  Terms,
  Risks,
}

interface TermsModalProps {
  onAccept: () => void;
}

export function TermsModalContent({ onAccept }: TermsModalProps) {
  const { signer, address } = useWeb3();
  const [signedToS, setSignedToS] = useState<string>();
  const [state, setState] = useState<TermsModalState>(TermsModalState.Terms);
  const [waitingForSignature, setWaitingForSignature] = useState(false);
  const scrollContainer = useRef<HTMLDivElement>(null);

  const latestToSQuery = useQuery({
    queryKey: ['latest_tos'],
    queryFn: async () => {
      return axios
        .get(`${process.env.REACT_APP_TOS_SIGNATURE_SERVICE}/tos/latest`)
        .then((res) => res.data);
    },
    onError: (err) => {
      console.log(err);
      toast.error("Couldn't load Terms of Service. Please try again later.");
    },
  });

  const addSignatureQuery = useQuery({
    enabled: !!signedToS && !!address,
    queryKey: ['add_signature', signedToS],
    queryFn: async () => {
      return axios
        .post(`${process.env.REACT_APP_TOS_SIGNATURE_SERVICE}/signature/add`, {
          address,
          signature: signedToS,
        })
        .then((res) => res.data);
    },
    onError: (err) => {
      console.error(err);
      toast.error("Couldn't submit signature. Please try again later.");
    },
  });

  const signToS = async () => {
    if (latestToSQuery?.data && signer && address) {
      setWaitingForSignature(true);
      signer
        .signMessage(latestToSQuery.data)
        .then((signature) => {
          setSignedToS(signature);
        })
        .catch((err) => {
          console.error(err);
          toast.error('Signing failed. Please try again.');
        })
        .finally(() => {
          setWaitingForSignature(false);
        });
    }
  };

  useEffect(() => {
    if (addSignatureQuery?.data !== undefined) {
      onAccept();
    }
  }, [addSignatureQuery, onAccept]);

  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo(0, 0);
    }
  }, [state, scrollContainer]);

  return (
    <ContentContainer>
      <ScrollContainer ref={scrollContainer}>
        {state === TermsModalState.Terms ? (
          <>
            <SectionHeader>Terms of Service</SectionHeader>
            Last Revised on December 19, 2023
            <Paragraph>
              Welcome to the Terms of Service (these “<b>Terms</b>”) for our website-hosted user
              interface located at{' '}
              <a href="https://increment.finance/">https://increment.finance/</a> (the “
              <b>Interface</b>”), in each case, operated by or on behalf Layken Limited (“
              <b>Company</b>”, “<b>we</b>” or “<b>us</b>”). The Interface, the Increment Governance
              Forum available at{' '}
              <a href="https://discourse.increment.finance/">
                https://discourse.increment.finance/
              </a>{' '}
              (the “<b>Governance Forum</b>”), and any content, tools, documentation, features and
              functionality offered on or through the Interface are collectively referred to as the
              “<b>Services</b>”. These Terms are entered into between you (acting on your own
              behalf, the “<b>Individual User</b>”) and on behalf of the firm on whose behalf you
              are acting (the “<b>Firm</b>”, and together with the Individual User, “<b>you</b>”)
              and the Company. If you act as an employee or agent of a legal entity, and enter into
              these Terms on their behalf, you represent and warrant that you have all the necessary
              rights and authorizations to bind such legal entity.{' '}
            </Paragraph>
            <Paragraph>
              These Terms govern your access to and use of the Services. Please read these Terms
              carefully, as they include important information about your legal rights. By accessing
              and/or using the Services, you are agreeing to these Terms. If you do not understand
              or agree to these Terms, please do not use the Services.
            </Paragraph>
            <Paragraph>
              The Protocol is Not Part of the Services: The Increment protocol (whether the mainnet
              or testnet version) is an open source protocol that enables on-chain perpetual swaps
              (the “<b>Protocol</b>”), as further described in the documentation available at (“
              <b>Documentation</b>”). The Interface is a web application and user interface that
              displays data and provides certain tools and functionality to facilitate a user’s
              access to the Protocol. The Interface is part of our Services. However, the Protocol,
              including its underlying smart contracts, and any other blockchain protocol or smart
              contracts that the Protocol interacts with are not part of the Services. There may be
              other ways to engage with the Protocol other than by using the Interface or other
              Services. Other developers may in the future create their own interfaces to function
              with the Protocol. We do not control all activity and data on the Protocol itself, nor
              do we take possession, custody, or control over any digital assets on the Protocol
              (other than such assets that we hold or custody for ourselves or for third parties
              that have specifically authorized us to hold or custody such assets on their behalf,
              and that in each case are transacted in via the Protocol). You acknowledge and agree
              that you are not buying, selling or swapping digital assets from us and that we do not
              operate any liquidity pools on the Protocol [A3] [A4] or control trade execution on
              the Protocol, and transactions on the Protocol are peer-to-peer transactions and are
              executed directly between users of the Protocol, including when accessing the Protocol
              through the Services. You acknowledge and agree that we make no representations and
              warranties with respect to the Protocol. Your use of the Protocol is entirely at your
              own risk.
            </Paragraph>
            <Paragraph>
              For purposes of these Terms, “<b>you</b>” and “<b>your</b>” means you as the user of
              the Services. If you use the Services on behalf of a company or other entity then “
              <b>you</b>” includes you and that entity, and you represent and warrant that (a) you
              are an authorized representative of the entity with the authority to bind the entity
              to these Terms, and (b) you agree to these Terms on the entity’s behalf.
            </Paragraph>
            <Paragraph>
              <b>
                SECTION 9 CONTAINS AN ARBITRATION CLAUSE AND CLASS ACTION WAIVER. BY AGREEING TO
                THESE TERMS, YOU AGREE (A) TO RESOLVE ALL DISPUTES (WITH LIMITED EXCEPTION) RELATED
                TO THE COMPANY’S SERVICES AND/OR PRODUCTS THROUGH BINDING INDIVIDUAL ARBITRATION,
                WHICH MEANS THAT YOU WAIVE ANY RIGHT TO HAVE THOSE DISPUTES DECIDED BY A JUDGE OR
                JURY, AND (B) TO WAIVE YOUR RIGHT TO PARTICIPATE IN CLASS ACTIONS, CLASS
                ARBITRATIONS, OR REPRESENTATIVE ACTIONS, AS SET FORTH BELOW. YOU HAVE THE RIGHT TO
                OPT-OUT OF THE ARBITRATION CLAUSE AND THE CLASS ACTION WAIVER AS EXPLAINED IN
                SECTION 9 .
              </b>
            </Paragraph>
            <SectionHeader>Table of Contents</SectionHeader>
            <Paragraph>
              <ol>
                <li>
                  <b>GENERAL</b>
                </li>
                <li>
                  <b>WHO MAY USE THE SERVICES</b>
                </li>
                <li>
                  <b>LOCATION OF OUR PRIVACY POLICY AND OTHER POLICIES</b>
                </li>
                <li>
                  <b>RIGHTS WE GRANT YOU</b>
                </li>
                <li>
                  <b>OWNERSHIP AND CONTENT</b>
                </li>
                <li>
                  <b>THIRD PARTY SERVICES AND MATERIALS</b>
                </li>
                <li>
                  <b>DISCLAIMERS, LIMITATIONS OF LIABILITY AND INDEMNIFICATION</b>
                </li>
                <li>
                  <b>ARBITRATION AND CLASS ACTION WAIVER</b>
                </li>
                <li>
                  <b>ADDITIONAL PROVISIONS</b>
                </li>
              </ol>
            </Paragraph>
            <SectionHeader>1. GENERAL</SectionHeader>
            <Paragraph>
              1.1 Wallets. To use certain of the Services, you will need to connect a compatible
              third-party digital wallet (“<b>Wallet</b>”) with the Services. By using a Wallet in
              connection with the Services, you agree that you are using the Wallet under the terms
              and conditions of the applicable third-party provider of such Wallet. Wallets are not
              associated with, maintained by, supported by or affiliated with the Company. You
              acknowledge and agree that we are not party to any transactions conducted while
              accessing our Interface, and we do not have possession, custody or control over any
              digital assets appearing on the Services. When you interact with the Interface, you
              retain control over your digital assets at all times. We accept no responsibility or
              liability to you in connection with your use of a Wallet, and we make no
              representations or warranties regarding how the Services will operate or be compatible
              with any specific Wallet.{' '}
              <b>
                The private keys necessary to access the assets held in a Wallet are not held by the
                Company. The Company has no ability to help you access or recover your private keys
                and/or seed phrases for your Wallet. You are solely responsible for maintaining the
                confidentiality of your private keys and you are responsible for any transactions
                signed with your private keys.
              </b>
            </Paragraph>
            <Paragraph>
              1.2 Updates; Monitoring. We may make any improvement, modifications or updates to the
              Services, including but not limited to changes and updates to the underlying software,
              infrastructure, security protocols, technical configurations or service features (the
              “<b>Updates</b>”) from time to time. Your continued access and use of the Services are
              subject to such Updates and you shall accept any patches, system upgrades, bug fixes,
              feature modifications, or other maintenance work that arise out of such Updates. We
              are not liable for any failure by you to accept and use such Updates in the manner
              specified or required by us. Although the Company is not obligated to monitor access
              to or participation in the Services, it has the right to do so for the purpose of
              operating the Services, to ensure compliance with the Terms and to comply with
              applicable law or other legal requirements.
            </Paragraph>
            <Paragraph>
              1.3 Fees and Taxes. Although the Company does not intend to charge any fees for the
              Services, we may elect to do so in the future. We will post any fees that we charge
              for the Services prominently on the Interface. In addition, there are certain fees and
              charges that are payable in connection with use of the Protocol, such as transaction
              or processing fees, liquidity provider fees, insurance fees, and blockchain gas or
              similar network fees. These fees are described on{' '}
              <a href="https://docs.increment.finance/developer-docs/perpetual-swaps/fees">
                https://docs.increment.finance/developer-docs/perpetual-swaps/fees
              </a>{' '}
              and{' '}
              <a href="https://docs.increment.finance/developer-docs/perpetual-swaps/insurance">
                https://docs.increment.finance/developer-docs/perpetual-swaps/insurance
              </a>{' '}
              and may vary from time to time. We will disclose the amount of fees we will charge or
              pass through to you for the applicable Service at the time you initiate any action
              through the Services – however, additional fees will be charged by the Protocol and
              may not be determinable in advance. Although we will attempt to provide accurate fee
              information, any such information reflects our estimate of fees, which may vary from
              the fees actually paid to use the Services and interact with the Protocol.
              Additionally, your Wallet provider may impose a fee to transact using the Services. We
              are not responsible for any fees charged by a third party (including fees charged by
              the Protocol itself). All transactions processed through the Services are
              non-refundable. You will be responsible for paying any and all taxes, duties and
              assessments now or hereafter claimed or imposed by any governmental authority
              associated with your use of the Services.{' '}
            </Paragraph>
            <SectionHeader>2. WHO MAY USE THE SERVICES</SectionHeader>
            <Paragraph>2.1 Eligibility.</Paragraph>
            <Paragraph>
              (a) You must be 18 years of age or older and not be a Prohibited Person to use the
              Services. A “<b>Prohibited Person</b>” is any person or entity that is (a) the subject
              of any economic or trade sanctions administered or enforced by any governmental
              authority, including being designated on any list of prohibited or restricted parties
              by any governmental authority, such as the U.S. Treasury Department’s list of
              Specially Designated Nationals, the U.S. Department of Commerce Denied Persons List
              Entity List, the E.U. Consolidated List of persons and the U.K. Consolidated List of
              Financial Sanctions Targets, (b) located, a resident of or organized in a Restricted
              Territory, or (c) owned or controlled by such persons or entities listed in (a)-(b). “
              <b>Restricted Territory</b>” means the United States, Canada, any jurisdiction or
              territory that is the subject of comprehensive country-wide or regional economic
              sanctions or has been designated as “<b>terrorist supporting</b>” by the United
              Nations or the governmental authority of the European Union, United Kingdom or the
              United States,and any other jurisdiction in which accessing or using our protocol is
              prohibited. We reserve the right to choose markets and jurisdictions to conduct
              business and may restrict or refuse the access of Website and our Services in other
              countries or regions in our sole discretion.
            </Paragraph>
            <Paragraph>
              (b) You may not use the Services if we have previously suspended you from use of the
              Services.
            </Paragraph>
            <Paragraph>
              (c) You acknowledge and agree that you are solely responsible for complying with all
              applicable laws of the jurisdiction you are located or accessing the Services from in
              connection with your use of the Services. By using the Services, you represent and
              warrant that you meet these requirements and will not be using the Services for any
              illegal activity or to engage in the prohibited activities in Section 4.2.
            </Paragraph>
            <Paragraph>
              2.2 Account. To use certain of the Services, such as the Governance Forum, you need to
              create an account or link another account (“<b>Account</b>”). You agree to provide us
              with accurate, complete and updated information for your Account. You are solely
              responsible for any activity on your Account and maintaining the confidentiality and
              security of your password. We are not liable for any acts or omissions by you in
              connection with your Account.
            </Paragraph>
            <Paragraph>
              2.3 Other Information. We may require you to provide additional information and
              documents regarding your use of the Services, including at the request of any
              competent authority or in case of application of any applicable law or regulation,
              including laws related to anti-money laundering or for counteracting financing of
              terrorism. We may also require you to provide additional information or documents in
              cases where we have reason to believe: (i) that your Wallet is being used for illegal
              money laundering or for any other illegal activity; or (ii) you have concealed or
              reported false identification information or other details. In addition to providing
              any required information, you agree to allow us to keep a record of that information
              during the period for which your account is active and within five (5) years after
              your account is closed. You also authorize us to share your submitted information and
              documentation to third parties to verify the authenticity of such information. We may
              also conduct necessary investigations directly or through a third party to verify your
              identity or protect you and/or us from financial crimes, such as fraud, and to take
              necessary action based on the results of such investigations. We will collect, use and
              share such information in accordance with our privacy policy.
            </Paragraph>
            <Paragraph>
              If you provide any information to us, you must ensure that such information is true,
              complete, and timely updated when changed. If there are any grounds for believing that
              any of the information you provided is incorrect, false, outdated or incomplete, we
              reserve the right to send you a notice to demand correction, directly delete the
              relevant information, and as the case may be, terminate all or part of the Services we
              provide for you. You shall be fully liable for any loss or expense caused to us during
              your use of the Services. You hereby acknowledge and agree that you have the
              obligation to keep all the information accurate, update and correct at all times.
            </Paragraph>
            <SectionHeader>3. LOCATION OF OUR PRIVACY POLICY AND OTHER POLICIES</SectionHeader>
            <Paragraph>
              3.1 Privacy Policy. Our Privacy Policy describes how we handle the information you
              provide to us when you use the Services. For an explanation of our privacy practices,
              please visit our Privacy Policy located at .<br />
              3.2 Governance Forum Community Guidelines. Our Governance Forum Community Guidelines
              available at describe our rules of behavior and engagement when you use the Governance
              Forum, which are intended to foster a positive and engaging community. All content
              that you upload or share to the Governance Forum must comply with the Governance Forum
              Community Guidelines.
            </Paragraph>
            <Paragraph>
              3.3 Risks and Disclaimers. The Risks and Disclaimers available at describe certain
              risks and other information you should know when using the Services and other
              blockchain technology, such as the Protocol. By agreeing to these Terms, you
              acknowledge that you have read and understood such Risks and Disclaimers.
            </Paragraph>
            <SectionHeader>4. RIGHTS WE GRANT YOU</SectionHeader>
            <Paragraph>
              4.1 Right to Use Services. We hereby permit you to use the Services for your internal
              use only, provided that you comply with these Terms in connection with all such use.
              If any software, content or other materials owned or controlled by us are distributed
              to you as part of your use of the Services, we hereby grant you a personal,
              non-assignable, non-sublicensable, non-transferrable, and non-exclusive right and
              license to access and display such software, content and materials provided to you as
              part of the Services, in each case for the sole purpose of enabling you to use the
              Services as permitted by these Terms. Your access and use of the Services may be
              interrupted from time to time for any of several reasons, including, without
              limitation, the malfunction of equipment, periodic updating, maintenance or repair of
              the Service or other actions that Company, in its sole discretion, may elect to take.
            </Paragraph>
            <Paragraph>
              4.2 Restrictions On Your Use of the Services. You may not do any of the following in
              connection with your use of the Services:
            </Paragraph>
            <Paragraph>
              (a) use the Services to transact with any Prohibited Person or in violation of any
              applicable law, including laws related to anti-money laundering and terrorist
              financing;
            </Paragraph>
            <Paragraph>
              (b) use the Services for market manipulation (such as pump and dump schemes, wash
              trading, self-trading, front running, quote stuffing, and spoofing or layering,
              regardless of whether prohibited by law);
            </Paragraph>
            <Paragraph>
              (c) download, modify, copy, distribute, transmit, display, perform, reproduce,
              duplicate, publish, license, create derivative works from, or offer for sale any
              information contained on, or obtained from or through, the Services, except for
              temporary files that are automatically cached by your web browser for display
              purposes, or as otherwise expressly permitted in these Terms;
            </Paragraph>
            <Paragraph>
              (d) duplicate, decompile, reverse engineer, disassemble or decode the Services
              (including any underlying idea or algorithm), or attempt to do any of the same, except
              as expressly permitted by these Terms or applicable law;
            </Paragraph>
            <Paragraph>
              (e) use, reproduce or remove any copyright, trademark, service mark, trade name,
              slogan, logo, image, or other proprietary notation displayed on or through the
              Services;
            </Paragraph>
            <Paragraph>
              (f) use the Services to send advertisements, chain letters, or other solicitations, or
              to gather addresses or other personal data for commercial mailing lists or databases;
            </Paragraph>
            <Paragraph>
              (g) use automation software (bots), hacks, modifications (mods) or any other
              unauthorized third-party software designed to modify the Services;
            </Paragraph>
            <Paragraph>
              (h) access or use the Services in any manner that could disable, overburden, damage,
              disrupt or impair the Services or interfere with any other party’s access to or use of
              the Services or use any device, software or routine that causes the same;
            </Paragraph>
            <Paragraph>
              (i) attempt to gain unauthorized access to, interfere with, damage or disrupt the
              Services or the computer systems, wallets, accounts, protocols or networks connected
              to the Services;
            </Paragraph>
            <Paragraph>
              (j) circumvent, remove, alter, deactivate, degrade or thwart any technological measure
              or content protections of the Services or the computer systems, wallets, accounts,
              protocols or networks connected to the Services, including through use of VPN
              software;
            </Paragraph>
            <Paragraph>
              (k) use any robot, spider, crawlers or other automatic device, process, software or
              queries that intercepts, “<b>mines</b>,” scrapes or otherwise accesses the Services to
              monitor, extract, copy or collect information or data from or through the Services, or
              engage in any manual process to do the same;
            </Paragraph>
            <Paragraph>
              (l) introduce any viruses, trojan horses, worms, logic bombs or other materials that
              are malicious or technologically harmful into our systems;
            </Paragraph>
            <Paragraph>
              (m) submit, transmit, display, perform, post or store any content that is inaccurate,
              unlawful, defamatory, obscene, lewd, lascivious, filthy, excessively violent,
              pornographic, invasive of privacy or publicity rights, harassing, threatening,
              abusive, inflammatory, harmful, hateful, cruel or insensitive, deceptive, or otherwise
              objectionable, use the Services for illegal, harassing, bullying, unethical or
              disruptive purposes, or otherwise use the Services in a manner that is obscene, lewd,
              lascivious, filthy, excessively violent, harassing, harmful, hateful, cruel or
              insensitive, deceptive, threatening, abusive, inflammatory, pornographic, inciting,
              organizing, promoting or facilitating violence or criminal or harmful activities,
              defamatory, obscene or otherwise objectionable; or
            </Paragraph>
            <Paragraph>
              (n) access or use the Services in any way not expressly permitted by these Terms.
            </Paragraph>
            <Paragraph>
              4.3 Interactions with Other Users on the Services. You are responsible for your
              interactions with other users on or through the Services. While we reserve the right
              to monitor interactions between users, we are not obligated to do so, and we cannot be
              held liable for your interactions with other users, or for any user’s actions or
              inactions. If you have a dispute with one or more users, you release us (and our
              affiliates and subsidiaries, and our and their respective officers, directors,
              employees and agents) from claims, demands and damages (actual and consequential) of
              every kind and nature, known and unknown, arising out of or in any way connected with
              such disputes. In entering into this release you expressly waive any protections
              (whether statutory or otherwise) that would otherwise limit the coverage of this
              release to include only those claims which you may know or suspect to exist in your
              favor at the time of agreeing to this release.
            </Paragraph>
            <SectionHeader>5. OWNERSHIP AND CONTENT</SectionHeader>
            <Paragraph>
              5.1 Ownership of the Services. The Services, including their “<b>look and feel</b>”
              (e.g., text, graphics, images, logos), proprietary content, information and other
              materials, are protected under copyright, trademark and other intellectual property
              laws. You agree that the Company and/or its licensors own all right, title and
              interest in and to the Services (including any and all intellectual property rights
              therein) and you agree not to take any action(s) inconsistent with such ownership
              interests. We and our licensors reserve all rights in connection with the Services and
              its content, including, without limitation, the exclusive right to create derivative
              works.
            </Paragraph>
            <Paragraph>
              5.2 Ownership of Trademarks. The Company’s name, trademarks and logos and all related
              names, logos, product and service names, designs and slogans are trademarks of the
              Company or its affiliates or licensors. Other names, logos, product and service names,
              designs and slogans that appear on the Services are the property of their respective
              owners, who may or may not be affiliated with, connected to, or sponsored by us.
            </Paragraph>
            <Paragraph>
              5.3 Ownership of Feedback. We welcome feedback, bug reports, comments and suggestions
              for improvements to the Services (“<b>Feedback</b>”). You acknowledge and expressly
              agree that any contribution of Feedback does not and will not give or grant you any
              right, title or interest in the Services or in any such Feedback. All Feedback becomes
              the sole and exclusive property of the Company, and the Company may use and disclose
              Feedback in any manner and for any purpose whatsoever without further notice or
              compensation to you and without retention by you of any proprietary or other right or
              claim. You hereby assign to the Company any and all right, title and interest
              (including, but not limited to, any patent, copyright, trade secret, trademark,
              show-how, know-how, moral rights and any and all other intellectual property right)
              that you may have in and to any and all Feedback.
            </Paragraph>
            <Paragraph>
              5.4 Your Content License Grant. In connection with your use of the Services, you may
              be able to post, upload, or submit content to be made available through the Services
              (“
              <b>Your Content</b>”), including on our Governance Forum. In order to operate the
              Service, we must obtain from you certain license rights in Your Content so that
              actions we take in operating the Service are not considered legal violations.
              Accordingly, by using the Service and uploading Your Content, you grant us a license
              to access, use, host, cache, store, reproduce, transmit, display, publish, distribute,
              and modify (for technical purposes, e.g., making sure content is viewable on
              smartphones as well as computers and other devices) Your Content but solely as
              required to be able to operate, improve and provide the Services. You agree that these
              rights and licenses are royalty free, transferable, sub-licensable, worldwide and
              irrevocable (for so long as Your Content is stored with us), and include a right for
              us to make Your Content available to, and pass these rights along to, others with whom
              we have contractual relationships related to the provision of the Services, solely for
              the purpose of providing such Services, and to otherwise permit access to or disclose
              Your Content to third parties if we determine such access is necessary to comply with
              our legal obligations. As part of the foregoing license grant you agree that the other
              users of the Services shall have the right to comment on and/or tag Your Content
              and/or to use, publish, display, modify or include a copy of Your Content as part of
              their own use of the Services; except that the foregoing shall not apply to any of
              Your Content that you post privately for non-public display on the Services. To the
              fullest extent permitted by applicable law, the Company reserves the right, and has
              absolute discretion, to remove, screen, edit, or delete any of Your Content at any
              time, for any reason, and without notice. By posting or submitting Your Content
              through the Services, you represent and warrant that you have, or have obtained, all
              rights, licenses, consents, permissions, power and/or authority necessary to grant the
              rights granted herein for Your Content. You agree that Your Content will not contain
              material subject to copyright or other proprietary rights, unless you have the
              necessary permission or are otherwise legally entitled to post the material and to
              grant us the license described above.
            </Paragraph>
            <Paragraph>5.5 Notice of Infringement – Copyright Policy</Paragraph>
            <Paragraph>
              If you believe that any text, graphics, photos, audio, videos or other materials or
              works uploaded, downloaded or appearing on the Services have been copied in a way that
              constitutes copyright infringement under applicable copyright laws, including, to the
              extent applicable, the Digital Millennium Copyright Act, you may submit a notification
              to our copyright agent by providing the following information in writing:
            </Paragraph>
            <Paragraph>
              (a) identification of the copyrighted work that is claimed to be infringed;
            </Paragraph>
            <Paragraph>
              (b) identification of the allegedly infringing material that is requested to be
              removed, including a description of where it is located on the Service;
            </Paragraph>
            <Paragraph>
              (c) information for our copyright agent to contact you, such as an address, telephone
              number and e-mail address;
            </Paragraph>
            <Paragraph>
              (d) a statement that you have a good faith belief that the identified, allegedly
              infringing use is not authorized by the copyright owners, its agent or the law;
            </Paragraph>
            <Paragraph>
              (e) a statement that the information above is accurate, and under penalty of perjury,
              that you are the copyright owner or the authorized person to act on behalf of the
              copyright owner; and
            </Paragraph>
            <Paragraph>
              (f) the physical or electronic signature of a person authorized to act on behalf of
              the owner of the copyright or of an exclusive right that is allegedly infringed.
            </Paragraph>
            <Paragraph>
              Notices of copyright infringement claims should be sent by by e-mail to . It is our
              policy, in appropriate circumstances and at our discretion, to disable or terminate
              the accounts of users who repeatedly infringe copyrights or intellectual property
              rights of others.
            </Paragraph>
            <SectionHeader>6. THIRD PARTY SERVICES AND MATERIALS</SectionHeader>
            <Paragraph>
              6.1 Third Party Services and Materials. The Services may display, include or make
              available services, content, data, information, applications or materials from third
              parties (“
              <b>Third</b>-Party Services and Materials”) or provide links to certain third party
              websites. The Company does not endorse any Third-Party Services and Materials. You
              agree that your access and use of such Third-Party Services and Materials is governed
              solely by the terms and conditions of such Third-Party Services and Materials, as
              applicable. The Company is not responsible or liable for, and makes no representations
              as to any aspect of such Third-Party Services and Materials, including, without
              limitation, their content or the manner in which they handle, protect, manage or
              process data or any interaction between you and the provider of such Third-Party
              Services and Materials. The Company is not responsible for examining or evaluating the
              content, accuracy, completeness, availability, timeliness, validity, copyright
              compliance, legality, decency, quality or any other aspect of such Third-Party
              Services and Materials or websites. You irrevocably waive any claim against the
              Company with respect to such Third-Party Services and Materials. We are not liable for
              any damage or loss caused or alleged to be caused by or in connection with your
              enablement, access or use of any such Third-Party Services and Materials, or your
              reliance on the privacy practices, data security processes or other policies of such
              Third-Party Services and Materials. Third-Party Services and Materials and links to
              other websites are provided solely as a convenience to you.
            </Paragraph>
            <SectionHeader>
              7. DISCLAIMERS, LIMITATIONS OF LIABILITY AND INDEMNIFICATION
            </SectionHeader>
            <Paragraph>7.1 Disclaimers.</Paragraph>
            <Paragraph>
              (a) YOUR ACCESS TO AND USE OF THE SERVICES AND THE PROTOCOL ARE AT YOUR OWN RISK. YOU
              UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED TO YOU ON AN “<b>AS IS</b>” AND “
              <b>AS AVAILABLE</b>” BASIS. WITHOUT LIMITING THE FOREGOING, TO THE MAXIMUM EXTENT
              PERMITTED UNDER APPLICABLE LAW, THE COMPANY, ITS PARENTS, AFFILIATES, RELATED
              COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS AND
              LICENSORS (THE “<b>COMPANY ENTITIES</b>”) DISCLAIM ALL WARRANTIES AND CONDITIONS,
              WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY WARRANTIES
              RELATING TO TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              NON-INFRINGEMENT, USAGE, QUALITY, PERFORMANCE, SUITABILITY OR FITNESS OF THE SERVICES
              AND THE PROTOCOL FOR ANY PARTICULAR PURPOSE, OR AS TO THE ACCURACY, QUALITY, SEQUENCE,
              RELIABILITY, WORKMANSHIP OR TECHNICAL CODING THEREOF, OR THE ABSENCE OF ANY DEFECTS
              THEREIN WHETHER LATENT OR PATENT.
            </Paragraph>
            <Paragraph>
              (b) The Company Entities make no warranty or representation and disclaim all
              responsibility and liability for: (a) the completeness, accuracy, availability,
              timeliness, security or reliability of the Services or the Protocol; (b) any harm to
              your computer system, loss of data, or other harm that results from your access to or
              use of the Services or the Protocol; (c) the operation or compatibility of the
              Services with any other application or any particular system or device, including any
              Wallets; (d) whether the Services or the Protocol will meet your requirements or be
              available on an uninterrupted, secure or error-free basis; (e) whether the Services or
              the Protocol will protect your assets from theft, hacking, cyber attack, or other form
              of loss caused by third party conduct; and (f) the deletion of, or the failure to
              store or transmit, Your Content and other communications maintained by the Services.
            </Paragraph>
            <Paragraph>
              (c) Nothing contained in the Services constitutes, or is meant to constitute,
              financial, legal or other professional advice of any kind. If you require advice in
              relation to any financial, legal or other professional matter you should consult an
              appropriate professional. No advice or information, whether oral or written, obtained
              from the Company Entities or through the Services, will create any warranty or
              representation not expressly made herein. We make no representation or warranty as to
              the merit, legality or characterization of any particular digital asset that may be
              available for trading on the Protocol, including whether or not such digital asset is
              considered a security or financial instrument under any applicable laws. Investing in
              digital assets is highly risky and may lead to a total loss of investment. You must
              have sufficient understanding of cryptographic tokens, token storage mechanisms (such
              as token wallets), and blockchain technology to appreciate the risks involved in
              dealing in digital assets. You understand and agree that the value of digital assets
              can be volatile, and we are not in any way responsible or liable for any losses you
              may incur by using or transferring digital assets in connection with our Services.
            </Paragraph>
            <Paragraph>
              (d) There are certain multi-signature crypto wallets (the “<b>MultiSigs</b>”, and the
              signatories to such MultiSigs, the “<b>MultiSig Committee Members</b>”) that have
              certain controls related to the Protocol, that may include, but are not limited to,
              the ability to pause certain functionality of the Protocol, implement or influence
              upgrades to the Protocol (or any aspect thereof) and certain other controls of the
              functionality of the Protocol as described in the Documentation or in public
              communications made by us. Certain MultiSigs may be controlled by us or MultiSig
              Committee Members that are employed or engaged by us, and certain other MultiSigs will
              be controlled partially or entirely by MultiSig Committee Members that are
              unaffiliated third parties over which we have no or limited control. We will not be
              able to control the actions of such MultiSig Committee Members if they are not
              employed or engaged by us and thus certain MultiSigs will be outside of our control.
            </Paragraph>
            <Paragraph>
              (e) THE LAWS OF CERTAIN JURISDICTIONS, INCLUDING THE STATE OF NEW JERSEY, DO NOT ALLOW
              LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES AS
              SET FORTH IN SECTION 7.2 BELOW. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
              DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
              ADDITIONAL RIGHTS.
            </Paragraph>
            <Paragraph>
              (f) THE COMPANY ENTITIES TAKE NO RESPONSIBILITY AND ASSUME NO LIABILITY FOR ANY
              CONTENT THAT YOU, ANOTHER USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS,
              RECEIVES, OR STORES ON OR THROUGH OUR SERVICES.
            </Paragraph>
            <Paragraph>
              (g) YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE
              OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE INAPPROPRIATE, NONE OF WHICH THE COMPANY
              ENTITIES WILL BE RESPONSIBLE FOR.
            </Paragraph>
            <Paragraph>
              7.2 Limitations of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN
              NO EVENT WILL THE COMPANY ENTITIES AND THE MULTISIG COMMITTEE MEMBERS BE LIABLE (A)
              FOR DAMAGES OF ANY KIND, INCLUDING INDIRECT SPECIAL, EXEMPLARY, INCIDENTAL,
              CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF
              SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS, BUSINESS INTERRUPTION OR
              ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE
              THE SERVICES), HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER UNDER THESE
              TERMS OR OTHERWISE ARISING IN ANY WAY IN CONNECTION WITH THE SERVICES OR THESE TERMS
              AND WHETHER IN CONTRACT, STRICT LIABILITY OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE)
              EVEN IF THE COMPANY ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, OR
              (B) FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR ARISING OUT OF
              OR IN CONNECTION WITH THESE TERMS OR THE DELIVERY, USE OR PERFORMANCE OF THE SERVICES.
              THE COMPANY ENTITIES’ AND THE MULTISIG COMMITTEE MEMBERS’ TOTAL LIABILITY TO YOU FOR
              ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED THE GREATER OF ONE HUNDRED DOLLARS
              ($100.00), OR THE AMOUNT YOU PAID THE COMPANY ENTITIES, IF ANY, IN THE PAST SIX (6)
              MONTHS FOR THE SERVICES (OR OFFERINGS PURCHASED ON THE SERVICES) GIVING RISE TO THE
              CLAIM. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF
              ITS ESSENTIAL PURPOSE.
            </Paragraph>
            <Paragraph>
              7.3 Indemnification. By entering into these Terms and accessing or using the Services,
              you agree that you shall defend, indemnify and hold the Company Entities and MultiSig
              Committee Members harmless from and against any and all claims, costs, damages,
              losses, liabilities and expenses (including attorneys’ fees and costs) incurred by the
              Company Entities arising out of or in connection with: (a) your violation or breach of
              any term of these Terms or any applicable law or regulation; (b) your violation of any
              rights of any third party; (c) your misuse of the Services; (d) your negligence or
              wilful misconduct; or (e) Your Content. If you are obligated to indemnify any Company
              Entity hereunder, then you agree that Company (or, at its discretion, the applicable
              Company Entity) will have the right, in its sole discretion, to control any action or
              proceeding and to determine whether Company wishes to settle, and if so, on what
              terms, and you agree to fully cooperate with Company in the defense or settlement of
              such claim. Your obligations under this indemnification provision will continue even
              after these Terms have expired or been terminated.
            </Paragraph>
            <Paragraph>
              7.4 Third Party Beneficiaries. You and the Company acknowledge and agree that the
              Company Entities (other than the Company) and the MultiSig Committee Members are third
              party beneficiaries of these Terms, including under Sections 7 and 8.
            </Paragraph>
            <SectionHeader>8. ARBITRATION AND CLASS ACTION WAIVER</SectionHeader>
            <Paragraph>
              8.1 PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL
              RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT AND TO HAVE A JURY HEAR YOUR
              CLAIMS. IT CONTAINS PROCEDURES FOR MANDATORY BINDING ARBITRATION AND A CLASS ACTION
              WAIVER.
            </Paragraph>
            <Paragraph>
              8.2 Informal Process First. You and the Company agree that in the event of any dispute
              between you and the Company Entities or the MultiSig Committee Members, either party
              will first contact the other party and make a good faith sustained effort to resolve
              the dispute before resorting to more formal means of resolution, including without
              limitation, any court action, after first allowing the receiving party 30 days in
              which to respond. Both you and the Company agree that this dispute resolution
              procedure is a condition precedent which must be satisfied before initiating any
              arbitration against the other party.
            </Paragraph>
            <Paragraph>
              8.3 Arbitration Agreement and Class Action Waiver. After the informal dispute
              resolution process, any remaining dispute, controversy, or claim (collectively, “
              <b>Claim</b>”) relating in any way to the Services, including the Interface, any use
              or access or lack of access thereto, and any other usage of the Protocol even if
              interacted with outside of the Services or Interface, will be resolved by arbitration,
              including threshold questions of arbitrability of the Claim. You and the Company agree
              that any Claim will be settled by final and binding arbitration, using the English
              language, administered by the British Virgin Islands International Arbitration Centre
              (“<b>BVIIAC</b>”) under its BVIIAC Administered Arbitration Rules (the “
              <b>BVIIAC Rules</b>”) then in effect (those rules are deemed to be incorporated by
              reference into this section, and as of the date of these Terms). Arbitration will be
              handled by a sole arbitrator in accordance with the BVIIAC Rules. Judgment on the
              arbitration award may be entered in any court that has jurisdiction. Any arbitration
              under these Terms will take place on an individual basis – class arbitrations and
              class actions are not permitted. You understand that by agreeing to these Terms, you
              and the Company are each waiving the right to trial by jury or to participate in a
              class action or class arbitration.
            </Paragraph>
            <Paragraph>
              8.4 Exceptions. Notwithstanding the foregoing, you and the Company agree that the
              following types of disputes will be resolved in a court of proper jurisdiction: (i)
              disputes or claims where the sole form of relief sought is injunctive relief
              (including public injunctive relief); or (ii) intellectual property disputes.
            </Paragraph>
            <Paragraph>
              8.5 Costs of Arbitration. Payment of all filing, administration, and arbitrator costs
              and expenses will be governed by the BVIIAC Rules, except that if you demonstrate that
              any such costs and expenses owed by you under those rules would be prohibitively more
              expensive than a court proceeding, the Company will pay the amount of any such costs
              and expenses that the arbitrator determines are necessary to prevent the arbitration
              from being prohibitively more expensive than a court proceeding (subject to possible
              reimbursement as set forth below).
            </Paragraph>
            <Paragraph>
              Fees and costs may be awarded as provided pursuant to applicable law. If the
              arbitrator finds that either the substance of your claim or the relief sought in the
              demand is frivolous or brought for an improper purpose, then the payment of all fees
              will be governed by the BVIIAC Rules. In that case, you agree to reimburse the Company
              for all monies previously disbursed by it that are otherwise your obligation to pay
              under the applicable rules. If you prevail in the arbitration and are awarded an
              amount that is less than the last written settlement amount offered by the Company
              before the arbitrator was appointed, the Company will pay you the amount it offered in
              settlement. The arbitrator may make rulings and resolve disputes as to the payment and
              reimbursement of fees or expenses at any time during the proceeding and upon request
              from either party made within 14 days of the arbitrator’s ruling on the merits.
            </Paragraph>
            <Paragraph>
              8.6 Opt-Out. You have the right to opt-out and not be bound by the arbitration
              provisions set forth in these Terms by sending written notice of your decision to
              opt-out to . The notice must be sent to the Company within thirty (30) days of your
              first registering to use the Services or agreeing to these Terms; otherwise you shall
              be bound to arbitrate disputes on a non-class basis in accordance with these Terms. If
              you opt out of only the arbitration provisions, and not also the class action waiver,
              the class action waiver still applies. You may not opt out of only the class action
              waiver and not also the arbitration provisions. If you opt-out of these arbitration
              provisions, the Company also will not be bound by them.
            </Paragraph>
            <Paragraph>
              8.7 WAIVER OF RIGHT TO BRING CLASS ACTION AND REPRESENTATIVE CLAIMS. To the fullest
              extent permitted by applicable law, you and the Company each agree that any proceeding
              to resolve any dispute, claim, or controversy will be brought and conducted ONLY IN
              THE RESPECTIVE PARTY’S INDIVIDUAL CAPACITY AND NOT AS PART OF ANY CLASS (OR PURPORTED
              CLASS), CONSOLIDATED, MULTIPLE-PLAINTIFF, OR REPRESENTATIVE ACTION OR PROCEEDING (“
              <b>CLASS ACTION</b>”). You and the Company AGREE TO WAIVE THE RIGHT TO PARTICIPATE AS
              A PLAINTIFF OR CLASS MEMBER IN ANY CLASS ACTION. You and the Company EXPRESSLY WAIVE
              ANY ABILITY TO MAINTAIN A CLASS ACTION IN ANY FORUM. If the dispute is subject to
              arbitration, THE ARBITRATOR WILL NOT HAVE THE AUTHORITY TO COMBINE OR AGGREGATE
              CLAIMS, CONDUCT A CLASS ACTION, OR MAKE AN AWARD TO ANY PERSON OR ENTITY NOT A PARTY
              TO THE ARBITRATION. Further, you and the Company agree that the ARBITRATOR MAY NOT
              CONSOLIDATE PROCEEDINGS FOR MORE THAN ONE PERSON’S CLAIMS, AND IT MAY NOT OTHERWISE
              PRESIDE OVER ANY FORM OF A CLASS ACTION. For the avoidance of doubt, however, you can
              seek public injunctive relief to the extent authorized by law and consistent with the
              Exceptions clause above.
            </Paragraph>
            <Paragraph>
              IF THIS CLASS ACTION WAIVER IS LIMITED, VOIDED, OR FOUND UNENFORCEABLE, THEN, UNLESS
              THE PARTIES MUTUALLY AGREE OTHERWISE, THE PARTIES’ AGREEMENT TO ARBITRATE SHALL BE
              NULL AND VOID WITH RESPECT TO SUCH PROCEEDING SO LONG AS THE PROCEEDING IS PERMITTED
              TO PROCEED AS A CLASS ACTION. If a court decides that the limitations of this
              paragraph are deemed invalid or unenforceable, any putative class, private attorney
              general, or consolidated or representative action must be brought in a court of proper
              jurisdiction and not in arbitration.
            </Paragraph>
            <SectionHeader>9. Additional Provisions</SectionHeader>
            <Paragraph>
              9.1 Updating These Terms. We may modify these Terms from time to time in which case we
              will update the “<b>Last Revised</b>” date at the top of these Terms. If we make
              changes that are material, we will use reasonable efforts to attempt to notify you,
              such as by e-mail and/or by placing a prominent notice on the first page of the
              Interface. However, it is your sole responsibility to review these Terms from time to
              time to view any such changes. The updated Terms will be effective as of the time of
              posting, or such later date as may be specified in the updated Terms. Your continued
              access or use of the Services after the modifications have become effective will be
              deemed your acceptance of the modified Terms. No amendment shall apply to a dispute
              for which an arbitration has been initiated prior to the change in Terms.
            </Paragraph>
            <Paragraph>
              9.2 Suspension; Termination. If you breach any of the provisions of these Terms, all
              licenses granted by the Company will terminate automatically. Additionally, the
              Company may, in its sole discretion, suspend or terminate your Account and/or access
              to or use of any of the Services, with or without notice, for any or no reason,
              including, without limitation, (i) if we believe, in our sole discretion, you have
              engaged in any of the prohibited activities set forth in Section 4.2; (ii) if you
              provide any incomplete, incorrect or false information to us; (iii) if you have
              breached any portion of these Terms; and/or (iv) if we determine such action is
              necessary to comply with these Terms, any of our policies, procedures or practices, or
              any law rule or regulation. If the Company deletes your Account or suspends or
              terminates your access to the Services, you are prohibited from using the Services
              under a different name or wallet address. In the event of Account deletion for any
              reason, the Company may, but is not obligated to, delete any of Your Content. the
              Company shall not be responsible for the failure to delete or deletion of Your
              Content. All sections which by their nature should survive the termination of these
              Terms shall continue in full force and effect subsequent to and notwithstanding any
              termination of this Agreement by the Company or you. Termination will not limit any of
              the Company’s other rights or remedies at law or in equity.
            </Paragraph>
            <Paragraph>
              9.3 Injunctive Relief. You agree that a breach of these Terms will cause irreparable
              injury to the Company for which monetary damages would not be an adequate remedy and
              the Company shall be entitled to equitable relief in addition to any remedies it may
              have hereunder or at law without a bond, other security or proof of damages.
            </Paragraph>
            <Paragraph>
              9.4 Force Majeure. We will not be liable or responsible to you, nor be deemed to have
              defaulted under or breached these Terms, for any failure or delay in fulfilling or
              performing any of our obligations under these Terms or in providing the Services, when
              and to the extent such failure or delay is caused by or results from any events beyond
              our ability to control, including acts of God; flood, fire, earthquake, epidemics,
              pandemics, tsunami, explosion, war, invasion, hostilities (whether war is declared or
              not), terrorist threats or acts, riot or other civil unrest, government order, law, or
              action, embargoes or blockades, strikes, labor stoppages or slowdowns or other
              industrial disturbances, shortage of adequate or suitable Internet connectivity,
              telecommunication breakdown or shortage of adequate power or electricity, and other
              similar events beyond our control.
            </Paragraph>
            <Paragraph>
              9.5 Miscellaneous. If any provision of these Terms shall be unlawful, void or for any
              reason unenforceable, then that provision shall be deemed severable from these Terms
              and shall not affect the validity and enforceability of any remaining provisions.
              These Terms and the licenses granted hereunder may be assigned by the Company but may
              not be assigned by you without the prior express written consent of the Company. No
              waiver by either party of any breach or default hereunder shall be deemed to be a
              waiver of any preceding or subsequent breach or default. The section headings used
              herein are for reference only and shall not be read to have any legal effect. The
              Services are operated by us in the United States. Those who choose to access the
              Services from locations outside the United States do so at their own initiative and
              are responsible for compliance with applicable local laws. These Terms are governed by
              the laws of the British Virgin Islands, without regard to conflict of laws rules, and
              the proper venue for any disputes arising out of or relating to any of the same will
              be the courts located in the British Virgin Islands.
            </Paragraph>
            <Paragraph>
              9.6 How to Contact Us. You may contact us regarding the Services or these Terms by
              e-mail at.
            </Paragraph>
          </>
        ) : (
          <>
            <SectionHeader>Risks And Disclaimers</SectionHeader>
            <Paragraph>
              The <a href="https://increment.finance/">https://increment.finance/</a> website (“
              <b>Website</b>”) is operated by or on behalf Layken Limited (“<b>Company</b>”, “
              <b>we</b>” or “<b>us</b>”) and provides a user interface for the Increment protocol
              (whether the mainnet or testnet version) (the “<b>Protocol</b>”). The Protocol is an
              open source protocol that enables on-chain perpetual swaps, as further described in
              the documentation available at{' '}
              <a href="https://docs.increment.finance/increment/">
                https://docs.increment.finance/increment/
              </a>{' '}
              (“<b>Documentation</b>”). Before accessing the Protocol, you should review the
              Documentation and our Terms of Service available at{' '}
              <a href="https://docs.increment.finance/increment/legal/terms-of-service.">
                https://docs.increment.finance/increment/legal/terms-of-service
              </a>
              . In the event of any conflict between these Risks and Disclaimers and our Terms of
              Service, our Terms of Service will supersede and control.
            </Paragraph>
            <Paragraph>
              Use of the Protocol, as with any decentralized finance application, entails certain
              risks. We have provided a list of some of the risks associated with the Protocol;
              however, the following discussion is not an exhaustive list of the risks associated
              with the Protocol and does not necessarily reflect the relative importance of the
              various risks. You should review the Documentation and the Terms of Service carefully
              and consult with your advisers before using the Protocol.
            </Paragraph>
            <Paragraph>
              You should never trade or invest with funds that you can&apos;t afford to lose.
            </Paragraph>
            <SectionHeader>Risks associated with Cryptocurrencies</SectionHeader>
            <Paragraph>
              AS WITH ANY ASSET, THE VALUES OF CRYPTOCURRENCIES, DIGITAL ASSETS, TOKENS OR
              SECURITIES (“<b>CRYPTOCURRENCIES</b>”) ARE VOLATILE AND MAY FLUCTUATE SIGNIFICANTLY
              AND THERE IS A SUBSTANTIAL RISK OF ECONOMIC LOSS WHEN PURCHASING, HOLDING OR INVESTING
              IN CRYPTOCURRENCIES. BY ACCESSING THE WEBSITE AND USING OUR SERVICES, YOU ACKNOWLEDGE
              AND AGREE THAT: (A) YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTIONS IN
              CRYPTOCURRENCIES THAT ARE BASED ON BLOCKCHAIN AND CRYPTOGRAPHY TECHNOLOGIES AND ARE
              ISSUED AND MANAGED IN A DECENTRALIZED FORM; (B) YOU ASSUME ALL RISKS RELATED TO THE
              USE OF THIS WEBSITE AND TRANSACTIONS IN CRYPTOCURRENCIES USING THE PROTOCOL; AND (C)
              THE COMPANY SHALL NOT BE LIABLE FOR ANY LOSS OR ADVERSE OUTCOMES IN CONNECTION WITH
              YOUR USE OF THE WEBSITE OR CRYPTOCURRENCY TRANSACTIONS ON THE PROTOCOL.
            </Paragraph>
            <Paragraph>
              Cryptocurrencies are neither (i) deposits of or guaranteed by a bank nor (ii) insured
              by the FDIC or by any other governmental agency.
            </Paragraph>
            <Paragraph>
              The regulatory regime governing blockchain technologies and cryptocurrencies is
              uncertain, and new regulations or policies may materially adversely affect the
              potential utility or value of such cryptocurrencies and digital assets. There also
              exists the risks of new taxation of the purchase or sale of cryptocurrencies. All of
              the foregoing may result in volatility of cryptocurrency prices.
            </Paragraph>
            <Paragraph>
              You should conduct your own due diligence of any cryptocurrencies, and the developers
              or issuers behind those cryptocurrencies, and consult your advisors prior to making
              any investment decision. You are solely responsible for determining whether any
              investment, investment strategy or related transaction is appropriate for you
              according to your personal investment objectives, financial circumstances and risk
              tolerance, and you shall be solely responsible for any loss or liability therefrom.
              You should consult legal or tax professionals regarding your specific situation.
            </Paragraph>
            <Paragraph>
              We cannot control how third-party exchange platforms quote or value cryptocurrencies
              and other digital assets and we expressly deny and disclaim any liability to you and
              deny any obligations to indemnify or hold you harmless for any losses you may incur as
              a result of fluctuations in the value of cryptocurrencies.
            </Paragraph>
            <Paragraph>
              The Protocol is a purely non-custodial application, meaning we do not ever have
              custody, possession, or control of your cryptocurrencies at any time. Thus, you are
              solely responsible for the custody of the cryptographic private keys to the wallets
              you hold and you should never share your wallet credentials or seed phrase with
              anyone. We accept no responsibility for, or liability to you, in connection with your
              use of any wallet and make no representations or warranties regarding how the
              Interface or the Protocol will operate with any specific wallet. Likewise, you are
              solely responsible for any associated wallet and we are not liable for any acts or
              omissions by you in connection with or as a result of your wallet being compromised.
            </Paragraph>
            <Paragraph>
              We do not recommend whether any particular cryptocurrencies should be bought, earned,
              sold, swapped or held by you and we will not be held responsible for the decisions you
              make based on the information provided by us on this Website.
            </Paragraph>
            <SectionHeader>
              Risks associated with Blockchain Protocols and Smart Contracts
            </SectionHeader>
            <Paragraph>
              By using the Website, you represent that you have sufficient knowledge and experience
              in business and financial matters, including a sufficient understanding of blockchain
              technologies, cryptocurrencies, storage mechanisms (such as wallets), distributed
              computing, and blockchain-based software systems to be able to assess and evaluate the
              risks and benefits of use of the Protocol, and that you are able to bear the risks of
              using the Protocol including a complete loss of any funds transacted by you using the
              Protocol and the risk that any cryptocurrencies in which you transaction may lose all
              value. You acknowledge and agree that there are risks associated with using
              distributed protocols. We do not operate, run or deploy the Protocol. Instead, the
              Protocol is operated on a distributed basis by other users of the Protocol. The
              Protocol is subject to change, as determined by the Protocol’s governance mechanism,
              and we are not responsible for any changes in the Protocol or any resulting
              incompatibility between the Interface and the Protocol.
            </Paragraph>
            <Paragraph>
              We do not have control over users of the Protocol, which may be accessed through means
              other than the Interface, and you hereby assume the risks of interacting with such
              users.
            </Paragraph>
            <Paragraph>
              Smart contracts execute automatically when certain conditions are met. We do not have
              the ability to reverse a transaction that is recorded on a public blockchain. You are
              responsible for ensuring that any details entered you enter in connection with a
              transaction using any smart contracts are accurate and complete. We are not
              responsible for any losses due to your errors, including an incorrectly constructed
              transaction.
            </Paragraph>
            <Paragraph>
              All smart contracts are subject to risks. The Increment contracts have undergone
              testing, code reviews, internal audits and several external audits, but it is possible
              that they may still contain unknown bugs and produce economic errors that are
              inherently unforeseeable. Further, since smart contracts cannot be stopped or
              reversed, vulnerabilities in their programming and design or other vulnerabilities
              that may arise due to hacking or other security incidents may lead to irreversible
              losses.
            </Paragraph>
            <Paragraph>
              You acknowledge that there are inherent risks associated with using or interacting
              with public blockchains and distributed technology. There is no guarantee that the
              Protocol will continue to be available or that the Protocol will not be subject to
              errors, hacking or other security risks. The Protocol may also be subject to changes
              in operating rules, including forks, and it is your responsibility to make yourself
              aware of upcoming operating changes. We do not control the Protocol and are not
              responsible for changes in its functionality.
            </Paragraph>
            <Paragraph>
              In addition, we do not warrant that this Website will meet your needs, or that it will
              be uninterrupted, timely, secure or error-free, that defects will be corrected, or
              that this Website or the server that makes it available are free of viruses or other
              harmful components. However, the Protocol will continue to operate even if the
              Interface is not available. Any funds deposited by you in the smart contracts
              associated with the Protocol will remain in those smart contracts, even if you are
              unable to access them through the Interface.
            </Paragraph>
            <Paragraph>
              In certain cases, your transactions through the Services may not be successful due to
              an error with the Protocol, the Interface or your wallet. We accept no responsibility
              or liability to you for any such failed transactions, or any transaction or gas fees
              that may be incurred by you in connection with such failed transactions.
            </Paragraph>
            <SectionHeader>Additional Risk Factors </SectionHeader>
            <Paragraph>
              <i>Risks Associated with Smart Contracts</i>
            </Paragraph>
            <Paragraph>
              All smart contracts are subject to risks. The Increment contracts have undergone
              testing, code reviews, internal audits and several external{' '}
              <a href="https://docs.increment.finance/increment/protocol/audits">audits</a>, but it
              is possible that they may still contain unknown bugs and produce economic errors that
              are inherently unforeseeable. In addition, the Increment contracts rely on certain
              third-party smart contracts. Although we believe these contracts to be reliable, they
              were not part of the audit and could contain errors.
            </Paragraph>
            <Paragraph>
              <i>Economic Risks</i>
            </Paragraph>
            <Paragraph>Liquidity</Paragraph>
            <Paragraph>
              The protocol relies on liquidity providers to bring real collateral in order to mint
              virtual tokens to the Curve pool. Without enough liquidity remaining, traders may
              encounter issues when opening/closing positions, liquidations may become infeasible,
              and liquidity providers might be unable to close uneven positions.
            </Paragraph>
            <Paragraph>Slippage</Paragraph>
            <Paragraph>
              Unrealized PnL currently does not take slippage into account, so large positions can
              generate bad debt during liquidation. The Increment protocol limits the maximum
              position size that a single address can open, however, traders should be aware of this
              when taking positions with multiple addresses.
            </Paragraph>
            <Paragraph>TWAP</Paragraph>
            <Paragraph>
              The Increment protocol uses TWAP oracles for input validation of closing positions and
              liquidations. Manipulating TWAPs is more difficult than manipulating a spot price, but
              they can be tampered with if given sufficient financial resources. In addition,
              because TWAP is time-based, it could become out of sync with the markets during times
              of high volatility. Finally, because TWAP is generated from the protocol itself, it
              does not take into account prices on other exchanges and thus could become out of sync
              with the broader market.
            </Paragraph>
            <Paragraph>
              <i>Governance</i>
            </Paragraph>
            <Paragraph>
              Tokenholders will have the ability to vote on many aspects of the operation of the
              Increment protocol, from selecting the price oracle to allowing new trading pairs or
              tokens to the list of permitted collateral. There can be no assurance that that
              tokenholders will make decisions that provide the best outcome for traders.
            </Paragraph>
            <Paragraph>
              In addition, there will be an “Emergency Admin” address controlled by a multisig made
              up of several core contributors and stakeholders of the Increment protocol. The
              Emergency Admin will be able to pause or unpause the entire Increment protocol.
              Implementation of the pausing function could be detrimental to user positions in case
              of price fluctuations. Pausing the protocol should only occur under extremely critical
              circumstances, such as if a hack were to occur.
            </Paragraph>
            <Paragraph>
              <i>Dependencies</i>
            </Paragraph>
            <Paragraph>Curve factory</Paragraph>
            <Paragraph>
              As noted above, the Increment protocol relies on certain third-party smart contracts,
              which can be changed by the governance mechanisms of those contracts. In particular,
              Curve’s CryptoSwap powers our AMM. The admin
              (0x82561F43aEC744C076E2901d3fE23bfB8B03aD4d) of the curve factory
              (0x890b12affd59525e4f0273aF00Dcd9c4Ac7698C1) can change all parameters of the
              CryptoSwap contract. This changes the operation of the underlying trading engine
              impacting traders, liquidity providers and liquidators. Users of the Increment
              protocol may not have advance notice of changes in the CryptoSwap contract and this
              website may not be updated when such changes become effective.
            </Paragraph>
            <Paragraph>Oracles</Paragraph>
            <Paragraph>
              The Increment protocol currently uses Chainlink oracles to determine the funding rate
              of the protocol. When these oracles provide incorrect or delayed price information,
              the funding rates could be incorrectly estimated. In addition, liquidations may not be
              executed on time if oracle price updates too slowly or transactions are not processed.
              There can be no assurance that the current oracle or any oracle selected in the future
              will provide accurate information.
            </Paragraph>
            <Paragraph>Reserve Assets</Paragraph>
            <Paragraph>
              “UA” refers to “unit of account” by which transactions in the Increment protocol are
              denominated. Currently, one has to lock USDC to mint UA, in the same proportion. The
              protocol assumes a fixed price of 1 USD for each unit of UA. However, USDC is an
              upgradable contract where a native fee-on-transfer can be introduced. If such a
              mechanism is introduced, the Increment protocol would not longer be able to support
              USDC as the reserve asset due to accounting issues. For instance, if a 2 USDC fee is
              charged on every 10 USDC deposit onto Increment, the user should end up with 8 USDC
              but the protocol will see that as 10 USDC. In such a case the reserve asset will then
              need to be replaced with another asset.
            </Paragraph>
            <Paragraph>
              The protocol might be changed through the governance process to allow other ERC20
              tokens to be used as collaterals in the protocol – but while USDC’s value is almost
              completely static, the values of other tokens might change over time. To mitigate this
              risk, the Protocol includes a weight attached to collateral. The less stable the
              collateral, the lower its weight. This parameter may be changed by governance vote.
            </Paragraph>
            <Paragraph>
              The Increment protocol is built on <a href="https://docs.zksync.io/">zkSync Era</a>,
              an Ethereum layer-2 protocol. A severe degradation in any part of this critical
              infrastructure will adversely affect the functionality of the Increment protocol.
            </Paragraph>
            <Paragraph>
              For instance, if the sequencer on zkSync Era, which executes and batches L2
              transactions, goes offline then Increment will become unusable and transactions will
              not go through. This can cause significant problems with oracle price updates and
              potential liquidations once the sequencer is back online. A sequencer oracle would
              detect potential sequencer downtimes and allow a grace period for users to react
              before updating oracle prices - however, so far there isn&apos;t a onchain sequencer
              oracle available for zkSync Era.
            </Paragraph>
            <Paragraph>
              zkSync Era also offers a dedicated compiler responsible for transforming conventional
              Solidity and Vyper code into zkEVM bytecode. While there is extensive test coverage to
              ensure EVM compatibility, issues may still appear which may adversely affect the
              functionality of the Increment protocol.
            </Paragraph>
            <SectionHeader>No Investment or Other Professional Advice</SectionHeader>
            <Paragraph>
              This Website and any information or materials contained in it do not constitute the
              distribution, an offer or solicitation of any kind to purchase or sell any product,
              security or instrument whatsoever nor should they be construed as providing any type
              of investment or other advice or recommendations by us, any of our affiliates or third
              parties to any person in any jurisdiction where such distribution, offer,
              solicitation, purchase or sale would be unlawful under the laws of such jurisdiction.
              Moreover, the Website does not include, and the Company does not give, investment
              advice, endorsement, analysis or recommendations with respect to any cryptocurrencies
              or provide any financial, tax, legal advice or other professional advice or services
              of any kind. We do not make any investment recommendations, and no communication,
              through this website or in any other medium, is intended as or should be construed as
              advice or recommendation for any cryptocurrencies offered on or off this website or
              any other sort of advice. You should not rely on the Website for any such advice. We
              are not your broker, intermediary, agent, or advisor and have no fiduciary
              relationship or obligation to you in connection with any trades or other decisions or
              activities effected by you using this website. No advice or information contained on
              the Website will create any warranty or representation.
            </Paragraph>
          </>
        )}
      </ScrollContainer>
      <ButtonXL
        disabled={latestToSQuery.isFetching || waitingForSignature || addSignatureQuery.isFetching}
        onClick={
          state === TermsModalState.Terms
            ? () => {
                setState(TermsModalState.Risks);
              }
            : signToS
        }
      >
        {(() => {
          if (latestToSQuery.isFetching || waitingForSignature || addSignatureQuery.isFetching) {
            return <Loader />;
          }
          if (state === TermsModalState.Terms) {
            return 'Accept terms of service';
          }
          return 'Accept risks and disclaimers';
        })()}
      </ButtonXL>
    </ContentContainer>
  );
}
