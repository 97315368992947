import { useState } from 'react';
import styled from 'styled-components/macro';

import { H5 } from '../Text';

const HeaderWrapper = styled(H5)`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
  padding: 0.8125rem 1.25rem;
  box-sizing: border-box;
  margin: 0;
  background: ${({ theme }) => theme.bg3};
  border-radius: 0.75rem 0.75rem 0 0;
`;

const OptionWrapper = styled(H5)<{ selected: boolean }>`
  position: relative;
  background: none;
  border: none;
  color: ${({ selected, theme }) => (selected ? theme.white : theme.gray4)};
  padding: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: -0.8125rem;
    right: 0;
    left: 0;
    width: 100%;
    height: 0.2rem;
    border-radius: 0.066rem;
    background: ${({ theme, selected }) => (selected ? theme.gray1 : 'rbga(0, 0, 0, 0)')};
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme, selected }) => (selected ? theme.white : theme.gray3)};

    &:after {
      background: ${({ theme }) => theme.gray1};
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    height: 1.25rem;
    &:after {
      display: none;
    }
  }
`;

export const BaseContainer = styled.div<{ scrollable?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.bg2};
  max-height: 100vh;

  ${({ scrollable }) => (scrollable ? 'overflow: hidden;' : '')}
`;

export const NestedHeaderWrapper = styled(HeaderWrapper)`
  border-radius: 0;
  border-top: 1px solid ${({ theme }) => theme.bg5};
`;

export interface HeaderContainerProps {
  title: string;
  children?: React.ReactNode;
  scrollable?: boolean;
}

export function HeaderContainer({
  title,
  children = null,
  scrollable = false,
}: HeaderContainerProps) {
  return (
    <BaseContainer scrollable={scrollable}>
      <HeaderWrapper>{title}</HeaderWrapper>
      {children}
    </BaseContainer>
  );
}

HeaderContainer.defaultProps = {
  children: null,
  scrollable: false,
};

interface MultiHeaderContainerProps {
  className?: string;
  options: {
    title: string;
    component: React.ReactNode;
    scrollable?: boolean;
  }[];
}

export function MultiHeaderContainer({ options, className }: MultiHeaderContainerProps) {
  const [selected, setSelected] = useState(0);

  return (
    <BaseContainer scrollable={!!options[selected].scrollable} className={className}>
      <HeaderWrapper>
        {options.map(({ title }, idx) => (
          <OptionWrapper
            as="button"
            key={title}
            selected={selected === idx}
            onClick={() => setSelected(idx)}
          >
            {title}
          </OptionWrapper>
        ))}
      </HeaderWrapper>
      {options[selected].component}
    </BaseContainer>
  );
}

MultiHeaderContainer.defaultProps = {
  className: '',
};

interface NestedHeaderContainerProps {
  className?: string;
  options: {
    title: string;
    component: React.ReactNode;
    scrollable?: boolean;
  }[];
}

export function NestedHeaderContainer({ options, className }: NestedHeaderContainerProps) {
  const [selected, setSelected] = useState(0);

  return (
    <>
      <NestedHeaderWrapper className={className}>
        {options.map(({ title }, idx) => (
          <OptionWrapper
            as="button"
            key={title}
            selected={selected === idx}
            onClick={() => setSelected(idx)}
          >
            {title}
          </OptionWrapper>
        ))}
      </NestedHeaderWrapper>
      {options[selected].component}
    </>
  );
}

NestedHeaderContainer.defaultProps = {
  className: '',
};
