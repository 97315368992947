import { BigNumber, BigNumberish } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { Signer, Provider } from 'zksync-ethers';
import { GlobalPosition } from '../contexts/MarketsContext';
import { Network } from '../data/chain';

import { CurveCryptoSwap2ETH__factory, CurveCryptoViews__factory } from '../typechain';
import { SignerOrProvider } from './clearingHouse';
import { LpPosition } from './data';
import { calcPriceImpact } from './helpers';

interface GetDyParams {
  cryptoSwapAddress: string;
  signerOrProvider: Signer | Provider;
  i: BigNumberish;
  j: BigNumberish;
  dx: BigNumberish;
}

interface GetPriceImpactParams {
  cryptoSwapAddress: string;
  signerOrProvider: Signer | Provider;
  isLong: boolean;
  currentPrice: BigNumberish;
  proposedAmount: BigNumber;
}

interface EstimateLpTokensParams {
  cryptoSwapAddress: string;
  signerOrProvider: Signer | Provider;
  liquidityAmounts: [BigNumber, BigNumber];
}

interface GetDyFeesPercParams {
  chainConfig: Network;
  cryptoSwapAddress: string;
  signerOrProvider: SignerOrProvider;
  isLong: boolean;
  proposedAmount: BigNumberish;
}

interface EstimateLpWithdrawnTokens {
  cryptoSwapAddress: string;
  signerOrProvider: Signer | Provider;
  currentLpPosition: LpPosition;
  balanceToWithdraw: BigNumberish;
  latestGlobalPosition: GlobalPosition;
}

export const getDy = async ({
  cryptoSwapAddress,
  signerOrProvider,
  i,
  j,
  dx,
}: GetDyParams): Promise<BigNumber> => {
  const cryptoSwap = CurveCryptoSwap2ETH__factory.connect(cryptoSwapAddress, signerOrProvider);
  return cryptoSwap.get_dy(i, j, dx);
};

export const getPriceImpact = async ({
  cryptoSwapAddress,
  signerOrProvider,
  isLong,
  currentPrice,
  proposedAmount,
}: GetPriceImpactParams): Promise<{
  priceImpact: BigNumber;
  dy: BigNumber;
}> => {
  const dx = proposedAmount;

  const dy = await getDy({
    cryptoSwapAddress,
    signerOrProvider,
    i: isLong ? '0' : '1',
    j: isLong ? '1' : '0',
    dx,
  });

  return {
    priceImpact: calcPriceImpact(
      isLong ? dx : dy, // QUOTE
      isLong ? dy : dx, // BASE
      currentPrice,
    ),
    dy,
  };
};

export const estimateLpTokens = async ({
  cryptoSwapAddress,
  signerOrProvider,
  liquidityAmounts,
}: EstimateLpTokensParams): Promise<BigNumber> => {
  const cryptoSwap = CurveCryptoSwap2ETH__factory.connect(cryptoSwapAddress, signerOrProvider);
  return cryptoSwap.calc_token_amount(liquidityAmounts);
};

export const estimateLpWithdrawalInputs = async ({
  cryptoSwapAddress,
  signerOrProvider,
  currentLpPosition,
  balanceToWithdraw,
  latestGlobalPosition,
}: EstimateLpWithdrawnTokens): Promise<[BigNumber, BigNumber]> => {
  const cryptoSwap = CurveCryptoSwap2ETH__factory.connect(cryptoSwapAddress, signerOrProvider);
  const eWithdrawnQuoteTokens = (await cryptoSwap.balances(0))
    .mul(BigNumber.from(balanceToWithdraw).sub(1))
    .div(latestGlobalPosition.totalLiquidityProvided)
    .divWei(
      parseEther('1')
        .add(latestGlobalPosition.totalQuoteFeesGrowth)
        .sub(currentLpPosition.totalQuoteFeesGrowth),
    );
  const eWithdrawnBaseTokens = (await cryptoSwap.balances(1))
    .mul(BigNumber.from(balanceToWithdraw).sub(1))
    .div(latestGlobalPosition.totalLiquidityProvided)
    .divWei(
      parseEther('1')
        .add(latestGlobalPosition.totalBaseFeesGrowth)

        .sub(currentLpPosition.totalBaseFeesGrowth),
    );
  return [eWithdrawnQuoteTokens, eWithdrawnBaseTokens];
};

export const getDyFeesPerc = async ({
  chainConfig,
  cryptoSwapAddress,
  signerOrProvider,
  isLong,
  proposedAmount,
}: GetDyFeesPercParams): Promise<BigNumber> => {
  const cryptoViews = CurveCryptoViews__factory.connect(
    chainConfig.contracts.curveCryptoViews,
    signerOrProvider,
  );
  return cryptoViews.get_dy_fees_perc(
    cryptoSwapAddress,
    isLong ? '0' : '1',
    isLong ? '1' : '0',
    proposedAmount,
  );
};
