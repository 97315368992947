import { useEffect, useState, useMemo, createContext, useContext } from 'react';

import { breakpoints } from '../theme';

interface LayoutContextType {
  isMobile: boolean;
  isTablet: boolean;
  screenWidth: number;
}

const LayoutContext = createContext<LayoutContextType>({
  isMobile: false,
  isTablet: false,
  screenWidth: 0,
});

export function LayoutProvider({ children }: { children: React.ReactNode }) {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const layoutValue = useMemo<LayoutContextType>(
    () => ({
      isMobile: screenWidth ? screenWidth <= breakpoints[0] : false,
      isTablet: screenWidth ? screenWidth <= breakpoints[1] && screenWidth > breakpoints[0] : false,
      screenWidth,
    }),
    [screenWidth],
  );

  return <LayoutContext.Provider value={layoutValue}>{children}</LayoutContext.Provider>;
}

const useLayout = () => useContext(LayoutContext) as LayoutContextType;

export default useLayout;
