import { BigNumber, BigNumberish } from 'ethers';
import { Signer, Provider } from 'zksync-ethers';
import { parseEther } from 'ethers/lib/utils';
import { toast } from 'react-toastify';
import axios from 'axios';
import { TransactionReceipt, TransactionRequest } from 'zksync-ethers/build/types';

import {
  ClearingHouse__factory,
  ClearingHouseViewer__factory,
  UAHelper__factory,
} from '../typechain';
import { LibPerpetual } from '../typechain/ClearingHouseViewer';
import { Side } from './types';
import { Network } from '../data/chain';
import { decodeTxError } from './errors';
import { getFeeToken } from './localStorage';

export type SignerOrProvider = Signer | Provider;

interface ChangePositionParams {
  chainConfig: Network;
  signer: Signer;
  marketId: BigNumberish;
  proposedAmount: BigNumber;
  direction: Side;
  minAmount: BigNumber;
}

interface OpenReversePositionParams {
  chainConfig: Network;
  signer: Signer;
  marketId: BigNumberish;
  closeMinAmount: BigNumberish;
  closeProposedAmount: BigNumberish;
  openProposedAmount: BigNumberish;
  openMinAmount: BigNumberish;
  direction: Side;
}

interface GetTraderProposedAmountParams {
  chainConfig: Network;
  marketId: BigNumberish;
  user: string;
  reductionRatio?: string;
  iterations?: BigNumberish;
  minAmount?: BigNumberish;
  signerOrProvider: SignerOrProvider;
}

interface ClosePositionParams {
  chainConfig: Network;
  marketId: string;
  direction: Side;
  proposedAmount: BigNumberish;
  signer: Signer;
  minAmount: BigNumber;
}

interface TxParams {
  networkId: number;
  to: string;
  input: string;
  signer: Signer;
  value?: number;
}

interface DepositCollateralParams {
  chainConfig: Network;
  signer: Signer;
  amount: BigNumberish;
  tokenAddress: string;
}

interface WrapAndDepositCollateralParams {
  chainConfig: Network;
  signer: Signer;
  amount: BigNumberish;
  tokenAddress: string;
}

interface WithdrawParams {
  chainConfig: Network;
  signer: Signer;
  amount: BigNumberish;
  tokenAddress: string;
}

interface GetTraderUnrealizedPnLParams {
  marketId: string;
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
}

interface GetTraderFundingPaymentsParams {
  marketId: string;
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
}

interface GetLpUnrealizedPnLParams {
  marketId: string;
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
}

interface GetReserveValueParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
}

interface GetLpFundingPaymentsParams {
  marketId: string;
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
}

interface GetCollateralBalanceParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  tokenIdx: BigNumberish;
  userAddress: string;
}

interface GetTraderPositionParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  marketIdx: BigNumberish;
  userAddress: string;
}

interface GetLpPositionParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  marketIdx: BigNumberish;
  userAddress: string;
}

interface GetLpPositionAfterWithdrawalParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  marketIdx: BigNumberish;
  userAddress: string;
}

interface GetGlobalPositionParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  marketIdx: BigNumberish;
}

interface getMarginRatioParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
}

interface GetFreeCollateralByRatioParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
  ratio: BigNumberish;
}

interface GetMinMarginParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
}

interface GetMinMarginAtCreationParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
}

interface GetAccountLeverageParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
}

interface GetMarketLeverageParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  marketIdx: string;
  userAddress: string;
}

interface GetMinPositiveOpenNotionalParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
}

interface GetPnlAcrossMarketsParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
}

interface GetFundingPaymentsAcrossMarketsParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
}

interface GetUserDebtAcrossMarketsParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  userAddress: string;
}

interface getRemoveLiquidityProposedAmountParams {
  signerOrProvider: SignerOrProvider;
  chainConfig: Network;
  marketId: BigNumberish;
  userAddress: string;
  reductionRatio: BigNumberish;
  minVTokenAmounts: [BigNumber, BigNumber];
}

interface ProvideLiquidityParams {
  chainConfig: Network;
  marketId: BigNumberish;
  signer: Signer;
  amounts: [BigNumberish, BigNumberish];
  minLpAmount: BigNumberish;
}

interface RemoveLiquidityParams {
  chainConfig: Network;
  marketId: BigNumberish;
  signer: Signer;
  amountToRemove: BigNumberish;
  minWithdrawTokens: [BigNumberish, BigNumberish];
  proposedAmount: BigNumberish;
  minAmount: BigNumberish;
}

interface IncreaseAllowanceParams {
  chainConfig: Network;
  signer: Signer;
  amount: BigNumberish;
  spender: string;
  tokenAddress: string;
}

export const executeTx = async ({
  networkId,
  to,
  input,
  signer,
  value,
}: TxParams): Promise<TransactionReceipt> => {
  const from = await signer.getAddress();

  let txInput = {
    to,
    from,
    data: input,
    value: value ?? 0,
    chainId: +networkId,
    type: 0,
  };

  // attempt to sponsor the tx
  try {
    const feeToken = getFeeToken();
    if (feeToken && feeToken !== '0x0000000000000000000000000000000000000000') {
      const gasLimit = await signer.estimateGas(txInput);
      const res = await axios.post(process.env.REACT_APP_PAYMASTER_ENDPOINT, {
        from,
        to,
        value,
        input,
        gasLimit: gasLimit.toString(),
      });
      if (res.status === 200) {
        if (+res.data.sponsorshipRatio === 100) {
          txInput = res.data.txData;
        } else {
          console.warn('Transaction not fully sponsored. Defaulting to ETH for gas.');
        }
      } else {
        console.error('Failed to get sponsored tx');
      }
    }
  } catch (err) {
    console.error(err);
  }

  const tx = await signer.sendTransaction(txInput as TransactionRequest).catch(async (err) => {
    const errorMessage = await decodeTxError(err);
    toast.error(errorMessage);
    throw err;
  });

  const pendingTx = tx.wait();
  toast.promise(pendingTx, {
    pending: 'Awaiting transaction confirmation',
    success: {
      render: 'Transaction confirmed!',
      autoClose: 5000,
    },
    error: 'Transaction failed!',
  });

  return pendingTx;
};

export const provideLiquidity = async ({
  signer,
  chainConfig,
  marketId,
  amounts,
  minLpAmount,
}: ProvideLiquidityParams) => {
  const input = ClearingHouse__factory.createInterface().encodeFunctionData('provideLiquidity', [
    marketId,
    amounts,
    minLpAmount,
  ]);

  return executeTx({
    networkId: +chainConfig.chainId,
    input,
    to: chainConfig.contracts.clearingHouse,
    signer,
  });
};

export const removeLiquidity = async ({
  signer,
  chainConfig,
  marketId,
  amountToRemove,
  minWithdrawTokens,
  proposedAmount,
  minAmount,
}: RemoveLiquidityParams) => {
  const input = ClearingHouse__factory.createInterface().encodeFunctionData('removeLiquidity', [
    marketId,
    amountToRemove,
    minWithdrawTokens,
    proposedAmount,
    minAmount,
  ]);

  return executeTx({
    networkId: +chainConfig.chainId,
    input,
    to: chainConfig.contracts.clearingHouse,
    signer,
  });
};

export const getReserveValue = async ({
  signerOrProvider,
  chainConfig,
  userAddress,
}: GetReserveValueParams) => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );

  return clearingHouseViewer.getReserveValue(userAddress, true);
};

export const getLpFundingPayments = async ({
  signerOrProvider,
  chainConfig,
  marketId,
  userAddress,
}: GetLpFundingPaymentsParams) => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );

  return clearingHouseViewer.getLpFundingPayments(marketId, userAddress);
};

export const getLpUnrealizedPnL = async ({
  signerOrProvider,
  chainConfig,
  marketId,
  userAddress,
}: GetLpUnrealizedPnLParams) => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );

  return clearingHouseViewer.getLpUnrealizedPnL(marketId, userAddress);
};

export const getTraderFundingPayments = async ({
  signerOrProvider,
  chainConfig,
  marketId,
  userAddress,
}: GetTraderFundingPaymentsParams) => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );

  return clearingHouseViewer.getTraderFundingPayments(marketId, userAddress);
};

export const getTraderUnrealizedPnL = async ({
  signerOrProvider,
  chainConfig,
  marketId,
  userAddress,
}: GetTraderUnrealizedPnLParams) => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );

  return clearingHouseViewer.getTraderUnrealizedPnL(marketId, userAddress);
};

export const changePosition = async ({
  chainConfig,
  signer,
  marketId,
  proposedAmount,
  direction,
  minAmount,
}: ChangePositionParams) => {
  const input = ClearingHouse__factory.createInterface().encodeFunctionData('changePosition', [
    marketId,
    proposedAmount,
    minAmount,
    direction,
  ]);

  return executeTx({
    networkId: +chainConfig.chainId,
    input,
    to: chainConfig.contracts.clearingHouse,
    signer,
  });
};

export const openReversePosition = async ({
  chainConfig,
  signer,
  marketId,
  openProposedAmount,
  openMinAmount,
  direction,
  closeMinAmount,
  closeProposedAmount,
}: OpenReversePositionParams) => {
  const input = ClearingHouse__factory.createInterface().encodeFunctionData('openReversePosition', [
    marketId,
    closeProposedAmount,
    closeMinAmount,
    openProposedAmount,
    openMinAmount,
    direction,
  ]);

  return executeTx({
    networkId: +chainConfig.chainId,
    input,
    to: chainConfig.contracts.clearingHouse,
    signer,
  });
};

export const getTraderProposedAmount = async ({
  chainConfig,
  marketId,
  user,
  reductionRatio = '1',
  iterations = 20,
  minAmount = 0,
  signerOrProvider,
}: GetTraderProposedAmountParams): Promise<BigNumber> => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );
  return clearingHouseViewer.getTraderProposedAmount(
    marketId,
    user,
    parseEther(reductionRatio),
    iterations,
    minAmount,
  );
};

export const closePosition = async ({
  chainConfig,
  signer,
  proposedAmount,
  minAmount,
  marketId,
  direction,
}: ClosePositionParams) => {
  const input = ClearingHouse__factory.createInterface().encodeFunctionData('changePosition', [
    marketId,
    proposedAmount,
    minAmount,
    direction === Side.Long ? '1' : '0', // direction
  ]);

  return executeTx({
    networkId: +chainConfig.chainId,
    input,
    to: chainConfig.contracts.clearingHouse,
    signer,
  });
};

export const depositCollateral = async ({
  chainConfig,
  signer,
  amount,
  tokenAddress,
}: DepositCollateralParams) => {
  const input = ClearingHouse__factory.createInterface().encodeFunctionData('deposit', [
    amount,
    tokenAddress,
  ]);
  return executeTx({
    networkId: +chainConfig.chainId,
    input,
    to: chainConfig.contracts.clearingHouse,
    signer,
  });
};

export const depositReserveToken = async ({
  chainConfig,
  signer,
  amount,
  tokenAddress,
}: WrapAndDepositCollateralParams) => {
  const input = UAHelper__factory.createInterface().encodeFunctionData('depositReserveToken', [
    tokenAddress,
    amount,
  ]);

  return executeTx({
    networkId: +chainConfig.chainId,
    input,
    to: chainConfig.contracts.uaHelper,
    signer,
  });
};

export const withdrawCollateral = async ({
  chainConfig,
  signer,
  amount,
  tokenAddress,
}: WithdrawParams) => {
  const input = ClearingHouse__factory.createInterface().encodeFunctionData('withdraw', [
    amount,
    tokenAddress,
  ]);
  return executeTx({
    networkId: +chainConfig.chainId,
    input,
    to: chainConfig.contracts.clearingHouse,
    signer,
  });
};

export const withdrawReserveToken = async ({
  chainConfig,
  signer,
  amount,
  tokenAddress,
}: WithdrawParams) => {
  const input = UAHelper__factory.createInterface().encodeFunctionData('withdrawReserveToken', [
    tokenAddress,
    amount,
  ]);

  return executeTx({
    networkId: +chainConfig.chainId,
    input,
    to: chainConfig.contracts.uaHelper,
    signer,
  });
};

export const getCollateralBalance = async ({
  signerOrProvider,
  chainConfig,
  tokenIdx,
  userAddress,
}: GetCollateralBalanceParams): Promise<BigNumber> => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );
  return clearingHouseViewer.getBalance(userAddress, tokenIdx);
};

export const getTraderPosition = async ({
  signerOrProvider,
  chainConfig,
  marketIdx,
  userAddress,
}: GetTraderPositionParams): Promise<LibPerpetual.TraderPositionStruct> => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );
  return clearingHouseViewer.getTraderPosition(marketIdx, userAddress);
};

export const getLpPosition = async ({
  signerOrProvider,
  chainConfig,
  marketIdx,
  userAddress,
}: GetLpPositionParams): Promise<LibPerpetual.LiquidityProviderPositionStruct> => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );
  return clearingHouseViewer.getLpPosition(marketIdx, userAddress);
};

export const getLpPositionAfterWithdrawal = async ({
  signerOrProvider,
  chainConfig,
  marketIdx,
  userAddress,
}: GetLpPositionAfterWithdrawalParams) => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );
  return clearingHouseViewer.getLpPositionAfterWithdrawal(marketIdx, userAddress);
};

export const getGlobalPosition = async ({
  signerOrProvider,
  chainConfig,
  marketIdx,
}: GetGlobalPositionParams) => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );
  return clearingHouseViewer.getGlobalPosition(marketIdx);
};

export const getMarginRatio = async ({
  signerOrProvider,
  chainConfig,
  userAddress,
}: getMarginRatioParams): Promise<BigNumber> => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );
  return clearingHouseViewer.marginRatio(userAddress);
};

export const getFreeCollateralByRatio = async ({
  signerOrProvider,
  chainConfig,
  userAddress,
  ratio,
}: GetFreeCollateralByRatioParams): Promise<BigNumber> => {
  const clearingHouse = ClearingHouse__factory.connect(
    chainConfig.contracts.clearingHouse,
    signerOrProvider,
  );
  return clearingHouse.getFreeCollateralByRatio(userAddress, ratio);
};

export const getMinMargin = async ({
  signerOrProvider,
  chainConfig,
}: GetMinMarginParams): Promise<BigNumber> => {
  const clearingHouse = ClearingHouse__factory.connect(
    chainConfig.contracts.clearingHouse,
    signerOrProvider,
  );
  return clearingHouse.minMargin();
};

export const getMinMarginAtCreation = async ({
  signerOrProvider,
  chainConfig,
}: GetMinMarginAtCreationParams): Promise<BigNumber> => {
  const clearingHouse = ClearingHouse__factory.connect(
    chainConfig.contracts.clearingHouse,
    signerOrProvider,
  );
  return clearingHouse.minMarginAtCreation();
};

export const getMinPositiveOpenNotional = async ({
  signerOrProvider,
  chainConfig,
}: GetMinPositiveOpenNotionalParams): Promise<BigNumber> => {
  const clearingHouse = ClearingHouse__factory.connect(
    chainConfig.contracts.clearingHouse,
    signerOrProvider,
  );
  return clearingHouse.minPositiveOpenNotional();
};

export const getPnlAcrossMarkets = async ({
  signerOrProvider,
  chainConfig,
  userAddress,
}: GetPnlAcrossMarketsParams): Promise<BigNumber> => {
  const clearingHouse = ClearingHouse__factory.connect(
    chainConfig.contracts.clearingHouse,
    signerOrProvider,
  );
  return clearingHouse.getPnLAcrossMarkets(userAddress);
};

export const getFundingPaymentsAcrossMarkets = async ({
  signerOrProvider,
  chainConfig,
  userAddress,
}: GetFundingPaymentsAcrossMarketsParams): Promise<BigNumber> => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );
  return clearingHouseViewer.getFundingPaymentsAcrossMarkets(userAddress);
};

export const getUserDebtAcrossMarkets = async ({
  signerOrProvider,
  chainConfig,
  userAddress,
}: GetUserDebtAcrossMarketsParams): Promise<BigNumber> => {
  const clearingHouse = ClearingHouse__factory.connect(
    chainConfig.contracts.clearingHouse,
    signerOrProvider,
  );
  return clearingHouse.getDebtAcrossMarkets(userAddress);
};

export const getAccountLeverage = async ({
  signerOrProvider,
  chainConfig,
  userAddress,
}: GetAccountLeverageParams): Promise<BigNumber> => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouse,
    signerOrProvider,
  );
  return clearingHouseViewer.accountLeverage(userAddress);
};

export const getMarketLeverage = async ({
  signerOrProvider,
  chainConfig,
  marketIdx,
  userAddress,
}: GetMarketLeverageParams): Promise<BigNumber> => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouse,
    signerOrProvider,
  );
  return clearingHouseViewer.marketLeverage(marketIdx, userAddress);
};

export const getRemoveLiquidityProposedAmount = async ({
  signerOrProvider,
  chainConfig,
  marketId,
  userAddress,
  reductionRatio,
  minVTokenAmounts,
}: getRemoveLiquidityProposedAmountParams): Promise<BigNumber> => {
  const clearingHouseViewer = ClearingHouseViewer__factory.connect(
    chainConfig.contracts.clearingHouseViewer,
    signerOrProvider,
  );
  return clearingHouseViewer.callStatic.getLpProposedAmount(
    marketId,
    userAddress,
    reductionRatio,
    '100',
    minVTokenAmounts,
    0,
  );
};

export const increaseAllowance = async ({
  chainConfig,
  signer,
  amount,
  spender,
  tokenAddress,
}: IncreaseAllowanceParams) => {
  const input = ClearingHouse__factory.createInterface().encodeFunctionData('increaseAllowance', [
    spender,
    amount,
    tokenAddress,
  ]);

  return executeTx({
    networkId: +chainConfig.chainId,
    input,
    to: chainConfig.contracts.clearingHouse,
    signer,
  });
};
