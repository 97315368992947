import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import useLayout from '../../contexts/LayoutContext';
import { ButtonM } from '../Button';
import { supportedLanguages } from '../../locales/i18n';
import { Label2, Nav2 } from '../Text';
import { ReactComponent as ArrowBase } from '../../assets/vectors/arrow-s.svg';
import ellipsis from '../../assets/vectors/ellipsis.svg';
import hamburger from '../../assets/vectors/hamburger.svg';
import check from '../../assets/vectors/check.svg';
import { paymasters } from '../../data/paymasters';
import { getFeeToken, setFeeToken } from '../../utils/localStorage';

const Container = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.navZ};
`;

const ButtonWrapper = styled(ButtonM)<{
  expanded: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ expanded, theme }) => (expanded ? theme.bg4 : 'none')};
  border: 2px solid ${({ expanded, theme }) => (expanded ? theme.bg4 : theme.gray1)};
  padding: 0 0.625rem;
  height: 2.625rem;

  &:hover {
    filter: drop-shadow(0rem 0rem 0.25rem ${({ theme }) => theme.gray1});
  }

  &:enabled:active {
    filter: none;
    background: ${({ theme }) => theme.gray1};
  }
`;

const DropdownOption = styled(Nav2)<{ selected?: boolean }>`
  white-space: nowrap;
  color: ${({ selected, theme }) => (selected ? theme.white : theme.gray4)};
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: 0.5rem;
    font-weight: 400;
  }

  &:hover {
    color: white;

    path {
      stroke: ${({ theme }) => theme.white};
    }
  }

  path {
    stroke: ${({ theme }) => theme.gray4};
  }
`;

const LanguageOption = styled(DropdownOption)`
  margin-left: 0.25rem;
`;

const PaymasterOption = styled(DropdownOption)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const PaymasterIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const PaymasterTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PaymasterTitle = styled(Nav2)<{ promo?: string }>`
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  ${({ promo, theme }) =>
    promo &&
    `
    &:after {
      content: '${promo}';
      color: ${theme.blueLink};
      font-size: 0.75rem;
    }
  `}
`;

const Ellipsis = styled.img`
  width: 1rem;
  height: 1rem;
`;

const Arrow = styled(ArrowBase)`
  transform: rotate(270deg);
  width: 0.75rem;
  height: 0.75rem;
`;

const Check = styled.img<{ visible: boolean }>`
  width: 1rem;
  height: 1rem;
  margin-left: auto;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

const DropdownContainer = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.navZ};
  top: 100%;
  margin-top: 0.45rem;
  right: 0;
  background: ${({ theme }) => theme.bg4};
  box-sizing: border-box;
  width: auto;
  min-width: 10rem;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  gap: 0.625rem;
`;

const Back = styled(Label2)`
  color: ${({ theme }) => theme.gray4};
  margin-bottom: 0;
  cursor: pointer;
  font-size: 0.75rem;

  &:hover {
    color: ${({ theme }) => theme.white};
  }
`;

export function SettingsButton() {
  const { t, i18n } = useTranslation();
  const { isMobile } = useLayout();

  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);
  const [languageSelect, setLanguageSelect] = useState(false);
  const [paymasterSelect, setPaymasterSelect] = useState(false);
  const [selectedFeeToken, setSelectedFeeToken] = useState<string>();

  const close = () => {
    setFocused(false);
    setExpanded(false);
    setLanguageSelect(false);
    setPaymasterSelect(false);
  };

  useEffect(() => {
    if (focused) {
      setExpanded(true);
    }
  }, [focused]);

  useEffect(() => {
    const feeToken = getFeeToken();
    if (feeToken) {
      setSelectedFeeToken(feeToken);
    }
  }, []);

  const updateFeeToken = (newFeeToken: string) => {
    setSelectedFeeToken(newFeeToken);
    setFeeToken(newFeeToken);
  };

  const handleToggleClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Container>
      <ButtonWrapper onClick={handleToggleClick} expanded={expanded}>
        <Ellipsis src={isMobile ? hamburger : ellipsis} />
      </ButtonWrapper>
      {expanded && (
        <DropdownContainer onMouseLeave={close}>
          {(() => {
            if (languageSelect)
              return (
                <>
                  <Back as="div" onClick={() => setLanguageSelect(false)}>
                    &lt;- {t('Back')}
                  </Back>
                  {Object.keys(supportedLanguages).map((lang) => (
                    <LanguageOption
                      selected={i18n.language === lang}
                      onClick={() => {
                        i18n.changeLanguage(lang);
                      }}
                      key={lang}
                    >
                      {supportedLanguages[lang]}
                    </LanguageOption>
                  ))}
                </>
              );

            if (paymasterSelect)
              return (
                <>
                  <Back as="div" onClick={() => setPaymasterSelect(false)}>
                    &lt;- {t('Back')}
                  </Back>
                  {paymasters.map(
                    ({ image, feeTokenSymbol, feeTokenAddress, paymasterName, promo }) => (
                      <PaymasterOption
                        onClick={() => updateFeeToken(feeTokenAddress)}
                        key={feeTokenAddress}
                      >
                        <PaymasterIcon src={image} alt={feeTokenSymbol} />
                        <PaymasterTextWrapper>
                          <PaymasterTitle promo={promo}>{feeTokenSymbol}</PaymasterTitle>
                          <Nav2>{paymasterName}</Nav2>
                        </PaymasterTextWrapper>
                        <Check
                          src={check}
                          alt="check"
                          visible={
                            selectedFeeToken === feeTokenAddress ||
                            (!selectedFeeToken &&
                              feeTokenAddress === '0x0000000000000000000000000000000000000000')
                          }
                        />
                      </PaymasterOption>
                    ),
                  )}
                </>
              );

            return (
              <>
                <DropdownOption
                  as="a"
                  href="https://docs.increment.finance/increment/protocol/user-guide"
                  target="_blank"
                >
                  {t('How-To Guides')}
                </DropdownOption>
                <DropdownOption as="a" href="https://discord.increment.finance/" target="_blank">
                  {t('Ask Questions')}
                </DropdownOption>
                <DropdownOption
                  as="a"
                  href="https://docs.increment.finance/developer-docs/"
                  target="_blank"
                >
                  {t('Developer Docs')}
                </DropdownOption>
                <DropdownOption
                  as="a"
                  href="https://app.fuul.xyz/referrals/increment-finance"
                  target="_blank"
                >
                  {t('Referrals')}
                </DropdownOption>
                <DropdownOption
                  onClick={() => {
                    setLanguageSelect(true);
                  }}
                >
                  {t('Language')}: <span> {supportedLanguages[i18n.language]}</span>
                  <Arrow />
                </DropdownOption>
                <DropdownOption
                  onClick={() => {
                    setPaymasterSelect(true);
                  }}
                >
                  {t('Paymaster')}&nbsp; <Arrow />
                </DropdownOption>
              </>
            );
          })()}
        </DropdownContainer>
      )}
    </Container>
  );
}
